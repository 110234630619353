import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";


function Article() {
    return (
        <>
            <Header article={true} />
            <div className="container mt-5">
                <div className="row  mt-5">
                    <div className="col-md-4">
                        <button className="article-btn">General Question</button>
                    </div>
                    <div className="col-md-4">
                        <button className="article-btn">Booking</button>
                    </div>
                    <div className="col-md-4">
                        <button className="article-btn">What to Expect</button>
                    </div>
                </div>
            </div>
            <div className="container mt-md-4">
                <div className="row">
                    <div className="col-md-4">
                        <button className="article-btn">Pricing & Billing</button>
                    </div>
                    <div className="col-md-4">
                        <button className="article-btn">Privacy</button>
                    </div>
                    <div className="col-md-4">
                        <button className="article-btn">Contact Support</button>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <p className="service-head">Recent Activity</p>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <p className="article-ques">If you would like to contact Doctor Allways Customer Support, please reach out us to by:</p>
                        <p className="article-ans">Our support hours of operations are Monday-Friday 7am PT - 6pm PT & closed on Saturday & Sunday</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                        <p className="article-post">Article created 1 day ago&nbsp;&nbsp;<img src={images.chat} alt="question"
                            className="question" />&nbsp;&nbsp;0</p>
                        <div><span></span></div>
                    </div>
                </div>
                <hr></hr>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <p className="article-ques">If you would like to contact Doctor Allways Customer Support, please reach out us to by:</p>
                        <p className="article-ans">Our support hours of operations are Monday-Friday 7am PT - 6pm PT & closed on Saturday & Sunday</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                        <p className="article-post">Article created 1 day ago&nbsp;&nbsp;<img src={images.chat} alt="question"
                            className="question" />&nbsp;&nbsp;0</p>
                        <div><span></span></div>
                    </div>
                </div>
                <hr></hr>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <p className="article-ques">If you would like to contact Doctor Allways Customer Support, please reach out us to by:</p>
                        <p className="article-ans">Our support hours of operations are Monday-Friday 7am PT - 6pm PT & closed on Saturday & Sunday</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                        <p className="article-post">Article created 1 day ago&nbsp;&nbsp;<img src={images.chat} alt="question"
                            className="question" />&nbsp;&nbsp;0</p>
                        <div><span></span></div>
                    </div>
                </div>
                <hr></hr>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <p className="article-ques">If you would like to contact Doctor Allways Customer Support, please reach out us to by:</p>
                        <p className="article-ans">Our support hours of operations are Monday-Friday 7am PT - 6pm PT & closed on Saturday & Sunday</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                        <p className="article-post">Article created 1 day ago&nbsp;&nbsp;<img src={images.chat} alt="question"
                            className="question" />&nbsp;&nbsp;0</p>
                        <div><span></span></div>
                    </div>
                </div>
                <hr></hr>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <p className="article-ques">If you would like to contact Doctor Allways Customer Support, please reach out us to by:</p>
                        <p className="article-ans">Our support hours of operations are Monday-Friday 7am PT - 6pm PT & closed on Saturday & Sunday</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                        <p className="article-post">Article created 1 day ago&nbsp;&nbsp;<img src={images.chat} alt="question"
                            className="question" />&nbsp;&nbsp;0</p>
                        <div><span></span></div>
                    </div>
                </div>
                <hr></hr>
                <div className="help-butn">
                    <button className="help-btn"><img src={images.question_img} alt="question"
                        className="question" />&nbsp;&nbsp;&nbsp;Help</button>
                </div>
            </div>

            <Footer first={true}/>

        </>

    );

}

export default Article;