import React, { useState } from "react";
import BookSignup from "../booking/BookSignup";
import { ApiCall } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';




function PasswordReset() {
    const [PasswordReset, setPasswordReset] = useState(1);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);

    const sendMail = (e) => {

        e.preventDefault();
        setBtnLoad(true);

        if (mailValidation()) {
            const mailData = {
                email: email,
            };
            ApiCall("auth/patient_forgotpassword", mailData).then((data) => {
                if (data.message == "Mail sent successfully.") {
                    setEmail('');
                    toast.success("Reset Link sent to your Email Id.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setBtnLoad(false);
                        setPasswordReset(2);
                    }, 2000)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => setBtnLoad(false), 2000);
                }
            })
        }
        else {
            setBtnLoad(false);
        }
    }
    const mailValidation = () => {

        let isValid = true;

        if (email == "") {
            setEmailErr("Enter your email");
            isValid = false;
        } else {
            setEmailErr("");
        }
        if (email != "") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isValid = false;
                setEmailErr("Enter valid Email");
            }
        }
        return isValid;
    }

    return (
        <>
            {
                PasswordReset == 1 ?
                    <div className="mt-5 row">
                        <div className="col-md-10">

                            <p className="book-log-head">Password Reset</p>
                            <div className="book-email-input mb-3 mt-2">
                                <input value={email} type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)}></input>
                                <span
                                    className="err-txt"
                                >
                                    {emailErr ? emailErr : ""}
                                </span>
                            </div>
                            <div className="book-sign-last mt-3">
                                <button className={btnLoad ? "book-spinner spinner-border spinner-border-sm" : "book-log-btn"} onClick={sendMail} disabled={btnLoad} >{btnLoad ? "" : "Reset"}</button>
                                <div>
                                    <span>Don't have an Account?</span>&nbsp;&nbsp;&nbsp;
                                    <a className="" onClick={() => setPasswordReset(0)} role="button">Sign Up</a>
                                </div>
                            </div>
                        </div>

                    </div> : PasswordReset == 2 ? <div className="mt-5 row">
                        <div className="col-md-10 col-12">
                            <p className="book-log-head">Password Reset</p>
                            <p className="start-head mt-3">
                                We have sent you an email. Click the link in the message to reset your Password.
                            </p>
                            <div className="book-sign-last mt-3">
                                <div>
                                    <span>Don't have an Account?</span>&nbsp;&nbsp;&nbsp;
                                    <a className="" onClick={() => setPasswordReset(0)} role="button">Sign Up</a>
                                </div>
                            </div>
                        </div>

                    </div > : <BookSignup />
            }


        </>
    );

}

export default PasswordReset;
