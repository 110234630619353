import React, { useEffect, useState } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../css/home.css";
import ClinicList from "./ClinicList";
import { ApiCall } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';
import BookYourDoc from "./BookYourDoc";
import Step1 from "./Step1";
import Step3 from "./Step3";
import { useSelector } from "react-redux";
import Store from "../../Redux/Store";
import moment from "moment";


function Step2(props) {

    const [services, setServices] = useState([]);
    const [bookHome, setBookHome] = useState(0);
    const [step, setStep] = useState(2);

    const bookingData = useSelector((state) => state.bookingData);

    const serviceData = bookingData.serviceData;
    const common_symptoms = bookingData.commonSymptoms;


    const severeData = [
        {
            id: 1,
            name: "No pain"
        },
        {
            id: 2,
            name: "Mild"
        },
        {
            id: 3,
            name: "Moderate"
        },
        {
            id: 4,
            name: "Severe"
        },
        {
            id: 5,
            name: "Very Severe"
        },
        {
            id: 6,
            name: "Worst Pain"
        },
    ];
    let userData = props.userData;

    let cartData = props.cartData;

    const getServices = () => {
        let selectedService = {};
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
        const userDel = {
            card_id: null,
            payment_type: null
        }
        Store.dispatch({ type: "updateUserData", payload: userDel });
        Store.dispatch({ type: "updateUserData", payload: selectedService });
        ApiCall("service_list").then((data) => {
            if (data.message == "Service listed successfull.") {
                setServices(data.result)
            }
        })
    }

    useEffect(() => {
        getServices();
    }, [])

    const checkService = () => {

        if (serviceData != null && serviceData.service_id && serviceData.service_name && serviceData.clinic) {
            setStep(3);
        }
        else {
            toast.error("choose service and doctors to continue", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <>
            {
                bookHome == 0 ?
                    <>
                        {
                            step == 2 ?
                                <div className="common-bg py-5">
                                    <div className="container book-content">
                                        <div className="row px-lg-5 px-2">
                                            <div className="col-md-8">
                                                <p className="step-head">Book your Service</p>
                                                <p className="step-desc">Get back to Better Health Your way. Whether at Home, at Work or Traveling, Doctor Allways has the right service for you.</p>
                                            </div>
                                            <div className="col-md-4 d-flex flex-column justify-content-end align-items-center">
                                                <div className="step-list">
                                                    <a onClick={() => (setStep(1))} className={step == 1 ? "step-left1 step-active" : "step-left1"} role="button">1</a>
                                                    <a onClick={() => (setStep(2))} className={step == 2 ? "step-left step-active" : "step-left"} role="button">2</a>
                                                    <a onClick={() => checkService()} className={step == 3 ? "step-active" : ""} role="button">3</a>
                                                </div>
                                                <p className="right-head">{step == 1 ? "Symptoms" : step == 2 ? "Service" : "Checkout"}</p>
                                            </div>
                                        </div>
                                        <div className="row px-4">
                                            <div className="col-md-12">
                                                <hr className="help-line mb-0 mt-4"></hr>
                                            </div>
                                        </div>
                                        <div className="row px-lg-5 px-2 mt-1">
                                            <div className="col-md-7 left-content-steps">
                                                <ClinicList services={services} />
                                            </div>
                                            <div className="col-md-5 px-lg-5 px-4 mt-md-1 mt-4 d-flex flex-column justify-content-between">
                                                <div>
                                                    <div className="right-name-sec">
                                                        <p className="name-con">{userData.name} / {userData.age}</p>
                                                        <a className="name-edit" role="button" onClick={() => setBookHome(1)}>Edit</a>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="right-sym-sec">
                                                            <p className="sym-head mb-1">Your Symptoms</p>
                                                            <a className="name-edit mt-2" role="button" onClick={() => setStep(1)}>Edit</a>
                                                        </div>
                                                        {cartData.map((symptom, k) => {
                                                            return (
                                                                <div className="right-name-sec mt-3" key={k}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="name-con">{symptom.symptoms_name}</p>
                                                                    </div>
                                                                    <div className="sym-status">

                                                                        {severeData.map((ele) => {

                                                                            if (ele.id == symptom.severe_id)
                                                                                return ele.name
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )

                                                        })}
                                                        {common_symptoms.map((symptom, k) => {

                                                            return (


                                                                <div className="right-name-sec mt-3" key={k}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="name-con">{symptom.symptoms_name}</p>
                                                                    </div>
                                                                    <div className="sym-status">

                                                                        {severeData.map((ele) => {

                                                                            if (ele.id == symptom.severe_id)
                                                                                return ele.name
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )

                                                        })}
                                                    </div>
                                                    {

                                                        serviceData.service_name ?
                                                            <div className="mt-4">
                                                                <div className="right-sym-sec">
                                                                    <p className="sym-head mb-1">Scheduling</p>
                                                                    {/* <a className="name-edit mt-3" role="button">Edit</a> */}
                                                                </div>
                                                                <div className="schedule-class row">
                                                                    <div className="col-lg-6">
                                                                        <div className="show-service">

                                                                            <BookmarkIcon style={{ color: "#1585FF" }} />
                                                                            <p>

                                                                                {serviceData.clinic_name},<br />{serviceData.service_name}</p>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-lg-6 p-lg-0">
                                                                        <p><EventIcon style={{ color: "#1585FF" }} />&nbsp;&nbsp;{moment(serviceData.date, 'YYYY-MM-DD').format('MM-DD-YYYY')},&nbsp;{serviceData.time}</p>
                                                                    </div>
                                                                    {/* <div className="col-md-12">
                                                                        <p><BookmarkIcon style={{ color: "#1585FF" }} />&nbsp;&nbsp;{userData.address}</p>
                                                                    </div> */}
                                                                </div>
                                                            </div> : null
                                                    }

                                                </div>


                                                <div className="mt-4">
                                                    {
                                                        serviceData.service_name ?
                                                            <div className="d-flex flex-column align-items-end">
                                                                <span className="total-text">Total</span>
                                                                <p className="total-amount">${serviceData.amount}</p>
                                                            </div>
                                                            : null
                                                    }
                                                    <button className="step-continue" onClick={() => checkService()}>CONTINUE</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <ToastContainer />
                                </div> : step == 1 ? <Step1 /> : step == 3 ? <Step3 /> : ""
                        }

                    </>
                    : <BookYourDoc />
            }


        </>

    );

}

export default Step2;