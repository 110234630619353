import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import LegalContent from "./LegalContent";

function Legal() {

    return (
        <>
            <Header legal={true} />
            <div className="help-con-bg py-5">
                <LegalContent />
            </div>
            <Footer first={false} />

        </>

    );

}

export default Legal;