import React, { useState } from "react";
import BookSignup from "../booking/BookSignup";
import PasswordReset from "./PasswordReset";
import { APPLE_CLIENT_ID, ApiCall, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, Web_url } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';
import Store from "../../Redux/Store";
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialApple
} from 'reactjs-social-login';
import { jwtDecode } from "jwt-decode";



function BookLogin() {
    const [signup, setSignup] = useState(0);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);
    const [googlebtn, setGooglebtn] = useState(false);



    const loginFun = (e) => {

        e.preventDefault();
        setBtnLoad(true);

        if (loginValidation()) {
            const login = {
                email: email,
                password: password,
            };
            ApiCall("auth/patient_login", login).then((data) => {
                if (data.message == "Login Successfully.") {
                    let loginData = {
                        id: data.result.id,
                        name: data.result.name,
                        email: data.result.email,
                        phone_number: data.result.phone_number,
                        address: data.result.address,
                        city: data.result.city,
                        state: data.result.state,
                        avatar: data.result.profile_picture,
                        dob: data.result.dob,
                        gender: data.result.gender,
                        wallet: data.result.wallet_balance,
                        lat: data.result.customer_lat,
                        lng: data.result.customer_lng,
                        createdAt: data.result.created_at.split(' ')[0],
                        billing_status: data.result.billing_status,
                        billing_address: data.result.billing_address,
                        billing_lat: data.result.billing_lat,
                        billing_lng: data.result.billing_lng
                    };
                    Store.dispatch({ type: "addData", payload: loginData });
                    localStorage.setItem("user", data.result.id);
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setEmail('');
                        setPassword('');
                        setBtnLoad(false);
                    }, 3000)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => setBtnLoad(false), 2000);
                }
            })
        }
        else {
            setBtnLoad(false);
        }
    }

    const loginValidation = () => {

        let isValid = true;

        if (email == "") {
            setEmailErr("Enter your email");
            isValid = false;
        } else {
            setEmailErr("");
        }
        if (email != "") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isValid = false;
                setEmailErr("Enter valid Email");
            }
        }

        if (password == "") {
            setPassErr("Enter password");
            isValid = false;
        }
        else {
            setPassErr("");
        }
        return isValid;
    }
    const LoginWithGoogle = async (data) => {
        setGooglebtn(true);
        let userData = {
            access_token: data.access_token ? data.access_token : data.accessToken ? data.accessToken : "",
            email: data.email ? data.email : "",
            name: data.name ? data.name : "",
        }
        await ApiCall("auth/sociallogin", userData).then((res) => {
            if (res.message == "Patient login successfull." || res.message == "Register Successfully.") {
                let loginData = {
                    id: res.result.id,
                    name: res.result.name,
                    email: res.result.email,
                    phone_number: res.result.phone_number,
                    address: res.result.address,
                    city: res.result.city,
                    state: res.result.state,
                    avatar: res.result.profile_picture,
                    dob: res.result.dob,
                    gender: res.result.gender,
                    wallet: res.result.wallet_balance,
                    lat: res.result.customer_lat,
                    lng: res.result.customer_lng,
                    createdAt: res.result.created_at.split(' ')[0],
                    billing_status: res.result.billing_status,
                    billing_address: res.result.billing_address,
                    billing_lat: res.result.billing_lat,
                    billing_lng: res.result.billing_lng
                };
                Store.dispatch({ type: "addData", payload: loginData });
                localStorage.setItem("user", res.result.id);
                toast.success("Login successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // setGooglebtn(false)
            }
            else {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => setGooglebtn(false), 2000);
            }
        })
    }

    const LoginWithApple = async (data) => {
        setGooglebtn(true);
        if (data.authorization) {
            // console.log(data, 'data')
            const decoded = jwtDecode(data.authorization.id_token);
            let userData = {
                email: decoded.email ? decoded.email : "",
            }
            // console.log(decoded.email);
            await ApiCall("apple_login", userData).then((res) => {
                if (res.message == "Patient login successfull." || res.message == "Register Successfully.") {
                    let loginData = {
                        id: res.result.id,
                        name: res.result.name,
                        email: res.result.email,
                        phone_number: res.result.phone_number,
                        address: res.result.address,
                        city: res.result.city,
                        state: res.result.state,
                        avatar: res.result.profile_picture,
                        dob: res.result.dob,
                        gender: res.result.gender,
                        wallet: res.result.wallet_balance,
                        lat: res.result.customer_lat,
                        lng: res.result.customer_lng,
                        createdAt: res.result.created_at.split(' ')[0],
                        billing_status: res.result.billing_status,
                        billing_address: res.result.billing_address,
                        billing_lat: res.result.billing_lat,
                        billing_lng: res.result.billing_lng
                    };
                    const bookingDetails = {
                        name: res.result.name,
                        address: res.result.address,
                        dob: res.result.dob != "" && res.result.dob != null ? moment(res.result.dob).format("YYYY-MM-DD") : "",
                        gender: res.result.gender,
                        age: "",
                        lat: res.result.customer_lat,
                        lng: res.result.customer_lng,
                        userType: 0,
                    }
                    Store.dispatch({ type: "addUserdata", payload: bookingDetails });
                    Store.dispatch({ type: "addData", payload: loginData });

                    localStorage.setItem("user", res.result.id);
                    toast.success("Login successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                 
                }
                else {
                    toast.error(res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => setGooglebtn(false), 2000);
                }
            })

        }
        else {
            setGooglebtn(false);
            toast.error("Login Failed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <>
            {
                signup == 0 ?
                    <div className="mt-4 row">
                        <div className="col-md-10">
                            <p className="book-log-head">Log In</p>
                            <div className="row mt-4">
                                <div className="col-4">
                                    <LoginSocialFacebook
                                        appId={FACEBOOK_APP_ID}
                                        fieldsProfile={
                                            'id,first_name,last_name,email,name'
                                        }
                                        onResolve={({ provider, data }) => {
                                            LoginWithGoogle(data)
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Facebook</button>
                                    </LoginSocialFacebook>
                                </div>
                                <div className="col-4">
                                    <LoginSocialGoogle
                                        client_id={GOOGLE_CLIENT_ID}
                                        // onLoginStart={onLoginStart}
                                        // redirect_uri="http://localhost:3000"
                                        //scope="openid profile email "
                                        scope="email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar  https://www.googleapis.com/auth/calendar.events"
                                        discoveryDocs="claims_supported"
                                        access_type="offline"
                                        onResolve={({ data }) => {
                                            LoginWithGoogle(data)
                                            // setProvider(provider);
                                            // setProfile(data);
                                        }
                                        }
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Google</button>
                                    </LoginSocialGoogle>
                                </div>
                                <div className="col-4">
                                    <LoginSocialApple
                                        client_id={APPLE_CLIENT_ID}
                                        scope='name email'
                                        redirect_uri={Web_url.Domain_url}
                                        // onLoginStart={onLoginStart}
                                        onResolve={({ provider, data }) => {
                                            LoginWithApple(data);
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Apple</button>
                                    </LoginSocialApple>
                                </div>
                            </div>
                            <div className="row  mt-4 book-line-class">
                                <div className="col-6">
                                    <hr className="left-hr"></hr>
                                </div>
                                <span>OR</span>
                                <div className="col-6">
                                    <hr className="right-hr"></hr>
                                </div>
                            </div>
                            <form>
                                <div className="book-email-input mb-3 mt-2">
                                    <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} ></input>
                                    <span
                                        className="err-txt"
                                    >
                                        {emailErr ? emailErr : ""}
                                    </span>
                                </div>
                                <div className="book-pass-input mb-3">
                                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} autoComplete="off"></input>
                                    <span
                                        className="err-txt"
                                    >
                                        {passErr ? passErr : ""}
                                    </span>
                                </div>
                                <div className="book-log-last mt-3">
                                    <a onClick={() => setSignup(1)} role="button"><span>Forgot your Password?</span></a>
                                    {/* <button className="book-log-btn">LOGIN</button> */}
                                    <button type="button" className={btnLoad ? "book-spinner spinner-border spinner-border-sm" : "book-log-btn"} onClick={loginFun} disabled={btnLoad} >{btnLoad ? "" : "LOGIN"}</button>
                                </div>
                            </form>
                            <div className="row justify-content-center">
                                <div className="col-10 book-login-end">
                                    <hr className="bottom-line"></hr>
                                    <p>Don't have an Account?</p>
                                    <a className="" onClick={() => setSignup(2)} role="button">SIGNUP</a>
                                </div>
                            </div>
                        </div>
                    </div> : signup == 1 ?
                        <PasswordReset />
                        : <BookSignup />
            }

        </>
    );
}

export default BookLogin;
