import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";
import { useNavigate } from "react-router-dom";

function Services() {
    useEffect(() => {
        var hash = location.hash.substr(1);
        var lastChildElement = document.getElementById(hash);
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }, [])
    const navigate = useNavigate();
    return (
        <>
            <Header common_img={false} service={true} />
            <div className="container service-content">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-10">
                        <p className="inner-cont">Get back to better health your way. Whether at home, at work or travelling, doctor Allways has the right service for you.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <p className="service-head">OUR SERVICES</p>
                <div className="row d-flex justify-content-center mt-5" id="telehealth">
                    <div className="col-md-6 col-10">
                        <div className="serv-img1">
                            <img src={images.icon1_serv} alt="icon" className="serv-icon1" />
                        </div>
                    </div>
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center">
                        <p className="how-head">Telehealth</p>
                        <p className="how-desc">Get safe, reliable care through your smart device, wherever you are,
                            in real time. Schedule a visit with a provider through our platform
                            and receive same-day care over our secure video connection, all for
                            as low as $55.</p>
                        <p className="how-desc">Need to talk to someone now? Providers are available within
                            minutes of booking a virtual visit.</p>
                        <p className="how-desc">You can discuss symptoms or medical conditions, receive a
                            diagnosis, learn about treatment options and get prescriptions, if
                            needed, with the same type of provider you would see in an Urgent
                            Care, doctor's office or an ER.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row d-flex justify-content-center ser2-sec" id="housecall">
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Housecall Visits</p>
                        <p className="how-desc">It's quick and simple to see a provider at your home, office or hotel,
                            all within two hours of booking. Use our platform to schedule a visit
                            For a time that works for you, and see a provider for as little as $149*,</p>
                        <p className="how-desc">*Pricing may vary depending on location.</p>
                        <p className="how-desc">You can avoid the crowded waiting room by having the provider come
                            to you. When you book a housecall visit with Doctor Allways a
                            Medical Doctor, a Physician Assistant or a Registered Nurse
                            Practitioner will visit you with everything they need to provide the
                            care you and your Family needs.</p>
                    </div>
                    <div className="col-md-6 col-10 d-flex align-items-md-end justify-content-md-end">
                        <div className="serv-img2">
                            <img src={images.icon2_serv} alt="icon" className="serv-icon2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center d-flex" id="urgentcare">
                    <div className="col-md-6 col-10">
                        <div className="serv-img3">
                            <img src={images.icon3_serv} alt="icon" className="serv-icon1" />
                        </div>
                    </div>
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Urgent Care / Walk in Clinics</p>
                        <p className="how-desc">Booking an urgent care or walk-in clinic visit using our system is
                            easy. Just provide your symptoms, location, and choose the option
                            that works best For you. You can even book a visit for the same day.
                            Pay a low all-inclusive rate* on our platform and Forget about
                            surprise bills on site.</p>
                        <p className="how-desc">All services must be rendered at the clinic at the time of service and are on
                            the sole discretion of the treating medical provider. No vaccines included.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row d-flex ser2-sec" id="specialist">
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Specialist Appointments</p>

                        <p className="how-desc">Find the specialist you need and book your appointment without the need of a referral, that easy! you can appoinment as fast as in the next 48 hours.</p>
                        <p className="how-desc">If your provider determines you need to see a specialist during your
                            appointment, vou'll be referred to one within the Doctor AllWays
                            network.</p>
                        <p className="how-desc">Does it sound impossibly easy? That's the way it should be.</p>
                    </div>
                    <div className="col-md-6 col-10 d-flex align-items-md-end justify-content-md-end">
                        <div className="serv-img4">
                            <img src={images.icon4_serv} alt="icon" className="serv-icon2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center d-flex" id="pharma">
                    <div className="col-md-6 col-10">
                        <div className="serv-img5">
                            <img src={images.pharmacy} alt="icon" className="serv-icon1" />
                        </div>
                    </div>
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Pharmacy</p>
                        <p className="how-desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus iusto aspernatur explicabo minima error eaque fugit consequatur labore iure, quaerat quos eum. Consequuntur, ad fugit.</p>
                        <p className="how-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil quae iure molestiae, explicabo vitae tempore sunt aliquid odio sint fuga.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row d-flex ser2-sec" id="lab">
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Lab</p>

                        <p className="how-desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus iusto aspernatur explicabo minima error eaque fugit consequatur labore iure, quaerat quos eum. Consequuntur, ad fugit.</p>
                        <p className="how-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil quae iure molestiae, explicabo vitae tempore sunt aliquid odio sint fuga.</p>
                    </div>
                    <div className="col-md-6 col-10 d-flex align-items-md-end justify-content-md-end">
                        <div className="serv-img6">
                            <img src={images.lab} alt="icon" className="serv-icon2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center d-flex" id="imaging">
                    <div className="col-md-6 col-10">
                        <div className="serv-img7">
                            <img src={images.imaging} alt="icon" className="serv-icon1" />
                        </div>
                    </div>
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Imaging</p>
                        <p className="how-desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus iusto aspernatur explicabo minima error eaque fugit consequatur labore iure, quaerat quos eum. Consequuntur, ad fugit.</p>
                        <p className="how-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil quae iure molestiae, explicabo vitae tempore sunt aliquid odio sint fuga.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid choose-bg mt-5">
                <div className="row mt-5 justify-content-end">
                    <div className="col-xl-6 col-10">
                        <p className="choose-head">Why Choose Doctor Allways?</p>
                        <p className="how-desc">Eliminate the hassles of healthcare.
                            Get access to care the same day you book.
                            Choose which service is right for you. Fixed, affordable pricing and upfront payments.
                        </p>
                        <button className="serv-book-btn" onClick={() => navigate("/bookus")}>BOOK NOW</button>

                    </div>
                </div>
            </div>

            <Footer first={true} />

        </>

    );

}

export default Services;