import React, { useEffect, useState } from 'react'
import {
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { ApiCall, STRIPE_SECRET_KEY } from '../../common/Const';
import { ToastContainer, toast } from 'react-toastify';
import Store from "../../Redux/Store";
import { useSelector } from 'react-redux';



function Gpay(props) {

    // const stripe = useStripe();
    // const elements = useElements();
    const stripe = useStripe();
    const elements = useElements();

    const LoginData = useSelector((state) => state.login);
    const bookingData = useSelector((state) => state.bookingData);
    const userData = bookingData.userData;
    const cartData = bookingData.bookingData;
    const serviceData = bookingData.serviceData;
    const common_symptoms = bookingData.commonSymptoms;
    let service = serviceData;
    let amount = service.amount * 100;

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {

                // Fetch the client secret from your server
                if (!stripe || !elements) {
                    return;
                }

                var paymentRequest = stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: 'Total amount',
                        amount: amount,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });


                var prButton = elements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,
                });



                paymentRequest.canMakePayment().then(function (result) {
                    // console.log(result, 'res')
                    if (result && result.applePay || result && result.googlePay) {
                        // if(result.applePay){
                        //   payment_type ="applePay";
                        // }else if(result.googlePay){
                        //   payment_type ="googlePay";
                        // }
                        document.getElementById('wallet_payment_error').style.display = 'none';
                        prButton.mount('#payment-request-button');
                        document.getElementById('pay-class').style.display = 'block';
                    } else {
                        document.getElementById('pay-class').style.display = 'none';
                        document.getElementById('payment-request-button').style.display = 'none';
                        document.getElementById('wallet_payment_error').style.display = 'block';
                    }
                })
                    .catch((error) => {
                        console.error('Error:', error);
                    });


            } catch (error) {
                console.error('Error:', error);
            }
            paymentRequest.on('paymentmethod', async function (res) {
                let payment_type = await res.walletName;
                let token;
                const response = await fetch(`https://api.stripe.com/v1/payment_intents?amount=${amount}&currency=usd`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + STRIPE_SECRET_KEY,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }).then((response) => response.json())
                    .then((data) => {

                        // console.log(data, 'tokennnn')
                        // token = data.client_secret;
                        if (data.client_secret) {
                            const user = {
                                payment_type: payment_type,
                                payment_status: "success",
                                payment_id: data.id,
                                client_secret: data.client_secret,
                                payment_method: res.paymentMethod.id
                            }
                            token = data.client_secret;
                            // console.log(res, payment_type, 'kkkkkkk')
                            Store.dispatch({ type: "updateUserData", payload: user });
                            setTimeout(() => {


                                let isAuth = localStorage.getItem("user");

                                let BookData = {
                                    patient_id: isAuth,
                                    name: userData.name,
                                    dob: userData.dob,
                                    gender_id: userData.gender,
                                    address: userData.address,
                                    appointment_date: serviceData.date,
                                    appointment_time: serviceData.time,
                                    service_id: serviceData.service_id,
                                    doctor_id: serviceData.clinic,
                                    total_amount: serviceData.amount,
                                    symptoms: cartData.length > 0 ? JSON.stringify(cartData) : "",
                                    payment_type: payment_type,
                                    card_id: "",
                                    payment_id: data.id,
                                    payment_method: res.paymentMethod.id,
                                    client_secret: data.client_secret,
                                    email: LoginData.email,
                                    common_symptoms: common_symptoms.length > 0 ? JSON.stringify(common_symptoms) : "",
                                    record_id: userData.record_id ? userData.record_id : ""
                                }
                                // alert(JSON.stringify(BookData))

                                // console.log(BookData, 'kk')

                                ApiCall("patient_appointmenet", BookData).then((data) => {
                                    if (data.message == "Success.") {

                                        props.handler(data.result);

                                    }
                                    else {
                                        toast.error(data.message, {
                                            position: "top-right",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                        // setBtnLoad(false);
                                    }
                                })
                            }, 5000)

                        }
                    });

                // Confirm the PaymentIntent without handling potential next actions (yet).
                // stripe.confirmCardPayment(
                //     token,
                //     { payment_method: ev.paymentMethod.id },
                //     { handleActions: false }
                // ).then(function (confirmResult) {
                //     console.log(confirmResult, 'ccccccccc')
                // if (confirmResult.error) {
                //     ev.complete('fail');
                // } else {
                //     ev.complete('success');
                //     if (confirmResult.paymentIntent.status === "requires_action") {
                //         stripe.confirmCardPayment(token).then(function (result) {
                //             if (result.error) {
                //                 alert(result.error)
                //             } else {
                //                 console.log(confirmResult, 'hhhhhhhhh')
                //                 console.log(payment_type, 'ssssss')
                //             }
                //         });
                //     } else {
                //         console.log(confirmResult)
                //     }
                // }
                //     });
            });

        };

        fetchClientSecret();

    }, [stripe, elements])


    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "black",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontSmoothing: "antialiased",
            },
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#e5424d"
                }
            }
        },
        hidePostalCode: true
    };

    const [btnLoad, setBtnLoad] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);




    return (
        <div>
            <form>
                {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}

                {/* <span className='card-err'>
                    {errorMessage}
                </span> */}
                <div id="payment-request-button">

                </div>
                <span className='pay-class mt-2' id="pay-class">Click Here to Pay</span>

                <div className='pay-con' id="wallet_payment_error" style={{ display: "none" }}>
                    Payment type does not supported on this device.
                </div>
                {/* <button type="button" className={btnLoad ? "book-spinner dot-spinner spinner-border spinner-border-sm mt-3" : "card-btn"} onClick={(e) => handleGooglePayClick(e)} disabled={!stripe || !elements || btnLoad} >{btnLoad ? "" : "Pay with Google Pay"}</button> */}

            </form>
            <ToastContainer />

        </div>
    )
}
export default React.memo(Gpay);
