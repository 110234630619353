import React from 'react'
import Header from '../pages/Header';
import "../../css/thankyou.css";


 function BookHomePage() {
  return (
    <>
      <Header display={true} />
      <div className="thankyou_bg">
        <div className="container centerclasss">
          <div className="row classrows justify-content-center align-items-center">
            <div className="col-12 col-lg-10 text-center">
              <div className="box-1">
                <div className="row justify-content-center">
                  <div className="col-lg-9 col-md-9 col-9 pt-5 ">
                    <h2 className="text-center bookyourdoc">
                      Book Your doctor
                    </h2>
                    <hr className="hrclass"></hr>
                    <p className="christian_para">
                      christian we have detected that based on the provided
                      Symptoms <br></br> you may need urgent care.
                    </p>
                    <p className="christian_para">
                      Please seek care at the nearest emergency clinic or
                    </p>
                    <p className='call_911'>call 911 immidiately</p>
                    <div className='button-div'>
                    <button type='button' className='btn btn-primary homebutton px-4'>HOME PAGE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BookHomePage;