import React, { useEffect, useState } from "react";
import "../../css/header.css"
import { images } from "../../common/images";
import { useSelector } from "react-redux";
import { Web_url } from "../../common/Const";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import store from "../../Redux/Store";
import { ToastContainer, toast } from 'react-toastify';




function Header(props) {

    const [user, setUser] = useState(null);
    const LoginData = useSelector((state) => state.login);
    const navigate = useNavigate();



    useEffect(() => {
       
        let isAuth = localStorage.getItem("user");
        setUser(isAuth);
    }, [LoginData])


    const [expanded, setExpanded] = useState(false);
    const {
        display,
        common_bg,
        home,
        about,
        service,
        choose,
        article,
        blog,
        blog_bg,
        help,
        legal,
        news,
        profile,
    } = props;


    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    const handleLogout = () => {
        store.dispatch({ type: "clearBookingData", payload: {} });
        store.dispatch({ type: "removeData" });
        localStorage.clear();
        toast.success("Logged out Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
            navigate('/');
            window.scrollTo({ top: 0, behavior: "smooth" })
        }, 3000)
    }

    const items = [
        {
            key: "1",
            label: (
                <div>
                    <a
                        className="list-item mx-2"
                        style={{ color: "#1585FF", fontWeight: "500" }}
                        href="/profile"
                    >
                        Profile
                    </a>
                </div>
            ),
        },
        {
            key: "2",
            label: (
                <div >
                    <a
                        className="list-item mx-2"
                        style={{ color: "#1585FF", fontWeight: "500" }}
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#logout"
                    >
                        Logout
                    </a>
                </div>
            ),
        },
    ];

    return (
        <>
            <div
                className={
                    service
                        ? "service-bg"
                        : common_bg
                            ? "home-img"
                            : article
                                ? "article-bg"
                                : blog_bg
                                    ? "blog_bg"
                                    : help
                                        ? "help-bg"
                                        : legal
                                            ? "legal-bg"
                                            : news
                                                ? "news-bg"
                                                : profile
                                                    ? "profile_bg"
                                                    : ""
                }
            >
                <nav className="navbar navbar-expand-md">
                    <div className={display ? "container" : "container-fluid nav-cont"}>
                        <a onClick={() => navigate("/")} role="button" className="navbar-brand">
                            <img
                                role={"button"}
                                className="logo-img"
                                src={images.Hlogo}
                                alt="user"
                            />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleNavbar}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className={`collapse navbar-collapse${expanded ? " show" : ""}`}
                        >
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link list-item" onClick={() => navigate("/aboutus")} role="button">
                                        About
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link list-item" onClick={() => navigate("/services")} role="button">
                                        Services
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link list-item" onClick={() => navigate("/chooseus")} role="button">
                                        Our Doctors
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link list-item" onClick={() => navigate("/help")} role="button">
                                        Help
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link book-btn" onClick={() => navigate("/bookus")} role="button">
                                        Book Now
                                    </a>
                                </li>
                                <li className="">
                                    {
                                        user && user != null ?
                                            <>
                                                <Dropdown
                                                    menu={{
                                                        items,
                                                    }}
                                                >
                                                    <a role="button" className="avatar">
                                                        <Avatar
                                                            alt={LoginData.id ? LoginData.name : ""}
                                                            src={
                                                                LoginData && LoginData.avatar && LoginData.avatar != ""
                                                                    ? Web_url.Domain_url + LoginData.avatar
                                                                    : images.user
                                                            }

                                                        />
                                                    </a>
                                                </Dropdown>
                                                <a className="header-pro nav-link list-item" onClick={() => navigate("/profile")} role="button">
                                                    Profile
                                                </a>
                                                <a className="header-pro nav-link list-item" role="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#logout">
                                                    Logout
                                                </a>
                                            </>
                                            :
                                            <a className="nav-link list-item" onClick={() => navigate("/login")} role="button">
                                                Login / Register
                                            </a>
                                    }

                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {home ? (
                    <>
                        <div className="side-img">
                            <img
                                src={images.doctor}
                                alt="doctor-img"
                                className="doctor-img"
                            />
                        </div>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-9 col-lg-10">
                                    <p className="head-con">
                                        Access healthcare from the <br />
                                        comfort of your home or office
                                    </p>
                                    <p className="head-ques">
                                        Have the doctor came to you for as low as $149
                                    </p>
                                    <button className="home-book-btn" onClick={() => navigate("/bookus")}>BOOK NOW</button>
                                </div>
                                <div className="col-12">
                                    <img
                                        src={images.doctor}
                                        alt="doctor-img"
                                        className="side-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {about ? (
                    <>
                        <div className="side-img1">
                            <img
                                src={images.doctor2}
                                alt="doctor-img"
                                className="doctor-img"
                            />
                        </div>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-4 col-lg-5 col-10 mt-md-5">
                                    <p className="head-con">
                                        Convenient and Reliable Care, Made Easy
                                    </p>
                                </div>
                                <div className="col-12">
                                    <img
                                        src={images.doctor2}
                                        alt="doctor-img"
                                        className="side-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {choose ? (
                    <>
                        <div className="side-img2">
                            <img
                                src={images.Choose_bg1}
                                alt="doctor-img"
                                className="doctor-img"
                            />
                        </div>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-4 col-lg-5 col-10 mt-md-5">
                                    <p className="head-con">Why Choose Us?</p>
                                </div>
                                <div className="col-12">
                                    <img
                                        src={images.Choose_bg1}
                                        alt="doctor-img"
                                        className="side-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {blog ? (
                    <>
                        <div className="blog_img"></div>
                    </>
                ) : (
                    ""
                )}
                {help ? (
                    <>
                        <div className="help-section">
                            <div className="container px-5">
                                <div className="row">
                                    <div className="col-md-6 col-lg-5 col-12 mt-md-5">
                                        <p className="help-head">Help</p>
                                        <p className="help-desc">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.
                                        </p>
                                        <button className="book-doc-btn mt-3" onClick={() => navigate("/bookus")}>
                                            BOOK A DOCTOR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {legal ? (
                    <>
                        <div className="help-section">
                            <div className="container px-5">
                                <div className="row">
                                    <div className="col-md-7 col-lg-5 col-12 mt-md-5">
                                        <p className="help-head">Legals</p>
                                        <p className="help-desc">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.
                                        </p>
                                        {/* <button className="book-doc-btn mt-3" onClick={() => navigate("/bookus")}>
                                            BOOK A DOCTOR
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {news ? (
                    <>
                        <div className="help-section">
                            <div className="container px-5">
                                <div className="row">
                                    <div className="col-md-8 col-lg-5 col-12 mt-md-5">
                                        <p className="help-head">Newsroom</p>
                                        <p className="help-desc">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.
                                        </p>
                                        <button className="book-doc-btn mt-3" onClick={() => navigate("/bookus")}>
                                            BOOK A DOCTOR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {profile ? (
                    <>
                        <div className="help-section">
                            <div className="container px-5 py-5">
                                <div className="row profilesection">
                                    <div className="col-md-8 col-lg-6 col-12 mt-md-5">
                                        <p className="profile">Profile</p>
                                        <p className="help-desc">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galle
                                        </p>
                                        <button className="book-doc-btn mt-3" onClick={() => navigate("/bookus")}>
                                            BOOK A DOCTOR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
            {display ? <hr className="header-hr"></hr> : ""}

            <div className="modal fade" id="logout" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header logout-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Logout</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center logout-desc">Are you sure want to logout?</p>
                        </div>
                        <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={handleLogout}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default React.memo(Header);
