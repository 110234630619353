import React from "react";
import { images } from "../../common/images";


function Faq() {
    return (
        <>
            <div className="accordion mt-2" id="accordionExample">
                <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <img src={images.help_quest} alt="quest-img" className="quest-help-img" />
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </button>
                    </p>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <hr className="m-0 mx-4 my-2"></hr>
                        <div className="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mt-3" id="accordionExample1">
                <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                            <img src={images.help_quest} alt="quest-img" className="quest-help-img" />
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </button>
                    </p>
                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <hr className="m-0 mx-4 my-2"></hr>
                        <div className="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mt-3" id="accordionExample2">
                <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
                            <img src={images.help_quest} alt="quest-img" className="quest-help-img" />
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </button>
                    </p>
                    <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                        <hr className="m-0 mx-4 my-2"></hr>
                        <div className="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mt-3" id="accordionExample3">
                <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">
                            <img src={images.help_quest} alt="quest-img" className="quest-help-img" />
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </button>
                    </p>
                    <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample3">
                        <hr className="m-0 mx-4 my-2"></hr>
                        <div className="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </div>
                    </div>
                </div>
            </div>

        </>

    );

}

export default Faq;