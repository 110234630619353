import { combineReducers } from "redux";

const LoginData = (state = {}, action) => {
    switch (action.type) {
        case "addData":
            return { name: action.payload.name, email: action.payload.email, phone_number: action.payload.phone_number, address: action.payload.address, state: action.payload.state, city: action.payload.city, avatar: action.payload.avatar, dob: action.payload.dob, gender: action.payload.gender, lat: action.payload.lat, lng: action.payload.lng, wallet: action.payload.wallet, createdAt: action.payload.createdAt, billing_address: action.payload.billing_address, billing_lat: action.payload.billing_lat, billing_lng: action.payload.billing_lng, billing_status: action.payload.billing_status };
        case "removeData":
            return {};
        case "updateData":
            return { ...state, avatar: action.payload.avatar };
        case "updateWalletData":
            return { ...state, wallet: action.payload.wallet };
        default:
            return state;
    }
}

const bookingData = (state = { commonSymptoms: [], bookingData: [], userData: { userType : 0}, serviceData: {} }, action) => {
    switch (action.type) {
        case "addUserdata":
            var userDetails = { name: action.payload.name, address: action.payload.address, dob: action.payload.dob, gender: action.payload.gender, age: action.payload.age, lat: action.payload.lat, lng: action.payload.lng, userType: action.payload.userType }
            return { ...state, userData: userDetails };
        case "updateSymptoms":
            return { ...state, bookingData: [...state.bookingData, action.payload] }
        case "addCommonSymptoms":
            return { ...state, commonSymptoms: [...state.commonSymptoms, action.payload] }
        case "removeCommonSymptoms":
            const removeSymptoms = state.commonSymptoms.filter(e => e.symptom_id != action.payload);
            return { ...state, commonSymptoms: removeSymptoms };
        case "removeBookingData":
            const remove = state.bookingData.filter(e => e.symptom_id != action.payload.symptom_id);
            return { ...state, bookingData: remove };
        case "ModifyBookingData":
            const modifyData = state.bookingData.map(obj => {
                if (obj.symptom_id === action.payload.symptom_id) {
                    return action.payload;
                }
                return obj;
            });
            return { ...state, bookingData: modifyData };
        case "ModifyCommonSymptom":
            const modifyCommon = state.commonSymptoms.map(obj => {
                if (obj.symptom_id === action.payload.symptom_id) {
                    return action.payload;
                }
                return obj;
            });
            return { ...state, commonSymptoms: modifyCommon };
        case "updateServiceData":
            const service = action.payload;
            return { ...state, serviceData: service }
        case "updateUserData":
            if (action.payload.card_id) {
                const updateUser = { ...state.userData, payment_type: action.payload.payment_type, card_id: action.payload.card_id, payment_status: "failed", payment_id: null, client_secret: null, payment_method: null };
                return { ...state, userData: updateUser }
            }
            else {
                const updateUser = { ...state.userData, payment_type: action.payload.payment_type, card_id: null, payment_status: action.payload.payment_status, payment_id: action.payload.payment_id, client_secret: action.payload.client_secret, payment_method: action.payload.payment_method };
                return { ...state, userData: updateUser }
            }
        case "updateMedicalRecord":
            const updateMedical = { ...state.userData, record_id: action.payload };
            return { ...state, userData: updateMedical }
        case "clearBookingData":
            return { commonSymptoms: [], bookingData: [], userData: {}, serviceData: {} }
        default:
            return state;
    }
}

const Reducer = combineReducers({

    login: LoginData,
    bookingData: bookingData


});

export default Reducer;
