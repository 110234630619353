import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";
import { useNavigate } from "react-router-dom";




function Home() {

    const navigate = useNavigate();

    const handleService = (hash) => {
        navigate('/services#' + hash);
    }

    return (
        <>
            <Header display={false} common_bg={true} home={true} />
            <div className="container">
                <div className="row my-5">
                    <div className="col-md-6">
                        <img src={images.group} alt="group-img" className="group-img" />

                    </div>
                    <div className="col-md-6 ques-cont">
                        <p className="ques-tag">WHO WE ARE</p>
                        <p className="ans-head">On - demand Care<br /> Your Way</p>
                        <p className="ans-desc">Doctor Allways removes the hassle and uncertainty from
                            healthcare, offering affordable access to a network of
                            licensed providers. With us you can get the care you need
                            -when, where and how you want it. Our on-demand
                            healthcare platform puts you back in control of your health,
                            providing up-front pricing before booking, making
                            unpleasant billing surprises a thing from the past.</p>
                        {/* <button className="read-btn">READ MORE</button> */}

                    </div>

                </div>

            </div>
            <div className="container">
                <p className="service-head">OUR SERVICES</p>
                <div className="row my-4">
                    <div className="col-md-3 col-6 text-center" onClick={() => handleService('telehealth')} role="button">
                        <img src={images.icon1_serv} alt="service-img" className="" />
                        <p className="service-title">Telehealth</p>

                    </div>

                    <div className="col-md-3 col-6 text-center" onClick={() => handleService('housecall')} role="button">
                        <img src={images.icon2_serv} alt="service-img" className="" />
                        <p className="service-title">Housecall Visits</p>

                    </div>
                    <div className="col-md-3 col-6 text-center" onClick={() => handleService('urgentcare')} role="button">
                        <img src={images.icon3_serv} alt="service-img" className="" />
                        <p className="service-title">Urgent Care / Walk in Clinic</p>

                    </div>
                    <div className="col-md-3 col-6 text-center" onClick={() => handleService('specialist')} role="button">
                        <img src={images.icon4_serv} alt="service-img" className="" />
                        <p className="service-title">Specialist Appointments</p>
                    </div>
                    {/* <div className="col-md-4 col-6 text-center">
                        <img src={images.pharmacy} alt="service-img" className="" />
                        <p className="service-title">Pharmacy</p>
                    </div>

                    <div className="col-md-4 col-6 text-center">
                        <img src={images.lab} alt="service-img" className="" />
                        <p className="service-title">Lab & Imaging</p>
                    </div> */}
                </div>
                <div className="row my-4 justify-content-center">
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4 col-6 text-center" onClick={() => handleService('pharma')} role="button">
                                        <img src={images.pharmacy} alt="service-img" className="" />
                                        <p className="service-title">Pharmacy</p>
                                    </div>
                                    <div className="col-md-4 col-6 text-center" onClick={() => handleService('lab')} role="button">
                                        <img src={images.lab} alt="service-img" className="" />
                                        <p className="service-title">Lab</p>
                                    </div>
                                    <div className="col-md-4 col-6 text-center" onClick={() => handleService('imaging')} role="button">
                                        <img src={images.imaging} alt="service-img" className="" />
                                        <p className="service-title">Imaging</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <p className="service-head mt-5">GET ACCESS TO THE CARE YOU NEED <br className="break-no" />WHEN, WHERE AND HOW YOU WANT IT</p>
            </div>

            <div className="grid-container">
                <div className="grid-item double-img">
                    <img src={images.get_access1} alt="service-img" className="access-img" />
                    <span className="access-title">Provide Your Symptoms</span>
                    <span className="access-desc">Get started by telling us
                        your symptoms and their
                        intensity -from bumps
                        and bruises to fevers and
                        fractures. This first step
                        helps us understand your
                        needs, and begins your
                        journey back to better
                        health.</span>
                </div>
                <div className="grid-item arrow-img">
                    <img src={images.right1} alt="service-img" className="" />

                </div>
                <div className="grid-item double-img">
                    <img src={images.get_access2} alt="service-img" className="access-img" />
                    <span className="access-title">Choose Your Service
                    </span>
                    <span className="access-desc">Choose the type of
                        service that works best
                        for you. Doctor AllWays
                        offers a wide range of
                        medical services, allowing
                        you to tailor your
                        experience to your
                        requirements. Not sure
                        what you need? We've
                        got a team of trained
                        specialists who can help.</span>
                </div>
                <div className="grid-item arrow-img">
                    <img src={images.right1} alt="service-img" className="" />

                </div>
                <div className="grid-item double-img">
                    <img src={images.get_access3} alt="service-img" className="access-img" />
                    <span className="access-title">Confirm and Pay</span>
                    <span className="access-desc">Once you select your
                        date and time, Doctor
                        AllWays will do the magic
                        of making healthcare a
                        seamless and transparent
                        experience.</span>
                </div>
            </div>
            <div className="container mobile-con">
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6">
                        <img src={images.mobile} alt="mobile-bg" className="mobile-img" />
                    </div>
                    <div className="col-md-6 col-10 d-flex flex-column justify-content-center align-items-center">
                        <p className="quick-con">
                            Add Doctor Allways quick access to your smart phone
                        </p>
                        <p className="mail-con">
                            Give us your email<span> __________________________ </span>to add
                            a direct access to doctor allways on your phone
                        </p>
                    </div>
                </div>
            </div>

            <Footer first={true} />


        </>
    );

}

export default Home;
