import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { Provider } from "react-redux";
import Store, { persist } from './Redux/Store';
import { PersistGate } from "redux-persist/integration/react";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <Provider store={Store}>
            <PersistGate loading={null} persistor={persist}>
                  <App />
            </PersistGate>
      </Provider>

);
