import React, { useEffect, useState } from "react";
import Step1 from "./Step1";
import DatePicker from "react-datepicker";
import Store from "../../Redux/Store";
import moment from "moment";
import { useSelector } from "react-redux";
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { ApiCall, GOOGLE_API_KEY } from "../../common/Const";





function BookYourDoc() {
  const [from_places, setFromPlaces] = useState("");

  Geocode.setApiKey(GOOGLE_API_KEY);
  Geocode.setLanguage("en");
  const [libraries, setLibraries] = useState(["places"])
  const bookingData = useSelector((state) => state.bookingData.userData);
  console.log(bookingData, 'lll')
  const LoginData = useSelector((state) => state.login);

  const [bookHome, setBookHome] = useState(1);

  const [birthDate, setBirthDate] = useState(bookingData && bookingData.dob ? new Date(bookingData.dob) : LoginData && LoginData.dob ? new Date(LoginData.dob) : null);

  const [userName, setUserName] = useState(bookingData && bookingData.name ? bookingData.name : LoginData && LoginData.name ? LoginData.name : "");
  const [userAddress, setUserAddress] = useState(bookingData && bookingData.address ? bookingData.address : LoginData && LoginData.address ? LoginData.address : "");
  const [userGender, setUserGender] = useState(bookingData && bookingData.gender ? bookingData.gender : LoginData && LoginData.gender ? LoginData.gender : "");

  const [nameErr, setNameErr] = useState("");
  const [birthDateErr, setBirthDateErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [userLat, setUserLat] = useState(bookingData && bookingData.lat ? bookingData.lat : LoginData && LoginData.lat ? LoginData.lat : "");
  const [userLng, setUserLng] = useState(bookingData && bookingData.lng ? bookingData.lng : LoginData && LoginData.lng ? LoginData.lng : "");
  const [userList, setUserList] = useState([])
  const [userType, setUserType] = useState(bookingData.userType ? bookingData.userType : 0);
  const [userDis, setUserDis] = useState(bookingData.userType == 0 ? false :bookingData.userType == undefined ? false : true);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })

    if (localStorage.getItem('user') != null) {
      getUserlist(localStorage.getItem('user'));

    }

  }, [])

  const getUserlist = (id) => {

    ApiCall("billing_profile_view", { patient_id: id }).then((data) => {
      // console.log(data)
      if (data.message == "Success") {
        setUserList(data.result);
      }
    })
  }

  const handleUserDetails = () => {

    if (userValidation()) {

      let today_date = new Date();
      let distance = birthDate.getTime() - today_date.getTime();
      let daysOld = Math.floor(distance / (1000 * 60 * 60 * 24));
      let yearsOld = Math.abs(Number((daysOld / 365).toFixed(0)));

      const bookingData = {
        name: userName,
        address: userAddress,
        dob: moment(birthDate).format("YYYY-MM-DD"),
        gender: userGender,
        age: yearsOld,
        lat: userLat,
        lng: userLng,
        userType: userType,
      }
      Store.dispatch({ type: "addUserdata", payload: bookingData });
      setBookHome(0)
    }
  }
  const userValidation = () => {

    let isValid = true;

    if (userName == "") {
      setNameErr("Enter your Name");
      isValid = false;
    }
    else {
      setNameErr('');
    }
    if (birthDate == null) {
      setBirthDateErr("Choose birth date");
      isValid = false;
    }
    else {
      setBirthDateErr("");
    }
    if (userGender == "") {
      setGenderErr("Choose gender");
      isValid = false;
    }
    else {
      setGenderErr("");
    }
    if (userAddress == "") {
      setAddressErr("Enter your address");
      isValid = false;
    }
    else {
      setAddressErr("");
    }
    if (userLat == "" || userLng == "") {
      setAddressErr("Enter Valid address");
      isValid = false;
    }

    return isValid;


  }
  const fromLoad = (autocomplete) => {
    setFromPlaces(autocomplete)
  }

  const onFromPlaceChanged = async () => {
    if (from_places !== null && from_places) {
      var Lat = from_places.getPlace().geometry.location.lat();
      var Lon = from_places.getPlace().geometry.location.lng();
      var address = from_places.getPlace().formatted_address;

      setUserAddress(address);
      setUserLat(Lat);
      setUserLng(Lon);
    }
  }
  const userView = (data) => {
    if (data == 0) {
      setUserName(LoginData.name)
      setUserAddress(LoginData.address)
      setUserGender(LoginData.gender)
      setBirthDate(new Date(LoginData.dob))
      setUserLat(LoginData.lat)
      setUserLng(LoginData.lng)
      setUserType(data);
      setUserDis(false)
      return;
    }
    let value = userList.find((e) => e.id == data);

    // setsevereDataList([...filterdata]);

    // const loginData = {
    //   name: value.name,
    //   address: value.billing_address,
    //   dob: moment(value.dob).format("YYYY-MM-DD"),
    //   gender: value.gender,
    //   lat: value.lat,
    //   lng: value.lng,
    // }
    // console.log(loginData, 'll')
    setUserType(data);
    setUserName(value.name)
    setUserAddress(value.billing_address)
    setUserGender(value.gender)
    setBirthDate(new Date(value.dob))
    setUserLat(value.lat)
    setUserLng(value.lng)
    setUserDis(true)



    // Store.dispatch({ type: "updateUserData", payload: loginData });

    // Store.dispatch({ type: "addUserdata", payload: loginData });

  }
  return (

    <>

      {
        bookHome == 1 ?
          <div className="thankyou_blur_bg">
            <div className="container my-5">
              <div className="row classrows justify-content-center align-items-center">
                <div className="col-12 col-lg-10">
                  <div className="box-2">
                    <h3 className=" bookdoch3">Book Your Service</h3>
                    <form autoComplete="off">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-9 col-9 pt-4">
                          <p className="text-center bookpara">
                            Get back to Better Health Your way. Whether at Home, at Work or Traveling, Doctor Allways has the right service for you.
                          </p>
                          {
                            LoginData.name ?
                              <div className="form-group mb-3 user-select">
                                <label>Choose User</label>
                                <select className="form-select" value={userType} onChange={(e) => userView(e.target.value)}>
                                  <option value="0">Custom</option>
                                  {userList.length > 0 && userList.map((data, key) => {
                                    return (
                                      <option value={data.id} key={key} >{data.title}</option>

                                    );
                                  })}
                                </select>
                              </div>
                              : null
                          }

                          <div className="row justify-content-center pt-3">
                            <div className="col-12 col-md-4 col-lg-4 ">
                              {" "}
                              <div className="form-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bookinput"
                                  id="fullname"
                                  aria-describedby="emailHelp"
                                  placeholder="Full name"
                                  value={userName}
                                  onChange={(e) => setUserName(e.target.value)}
                                  disabled={userDis}
                                />
                                <span
                                  className="err-txt"
                                >
                                  {nameErr ? nameErr : ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              {" "}
                              <div className="form-group mb-3 user-date">
                                {/* <select className="form-select">
                                  <option>Birthdate</option>
                                </select> */}
                                <DatePicker id="dob" showDateSelect selected={birthDate ? birthDate : null} showYearDropdown placeholderText="Birth date" maxDate={moment().toDate()} onChange={(date) => setBirthDate(date)} dateFormat="MM-dd-yyyy" className="form-control" onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                  yearDropdownItemNumber={100} scrollableYearDropdown
                                  disabled={userDis}
                                  disabledKeyboardNavigation onFocus={e => e.target.blur()} />
                                <span
                                  className="err-txt"
                                >
                                  {birthDateErr ? birthDateErr : ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              {" "}
                              <div className="form-group mb-3 user-select">
                                <select className="form-select" value={userGender} onChange={(e) => setUserGender(e.target.value)} disabled={userDis}>
                                  <option value="">Select Gender</option>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                  <option value="3">Other</option>
                                </select>
                                <span
                                  className="err-txt"
                                >
                                  {genderErr ? genderErr : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-4">
                            <LoadScript libraries={libraries} googleMapsApiKey={GOOGLE_API_KEY}>
                              <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged}>
                                <input type="text" className="form-control bookinput" id="address"
                                  disabled={userDis}
                                  placeholder="Enter your address" name="address" autoComplete="off" value={userAddress} onChange={(e) => setUserAddress(e.target.value)} />
                              </Autocomplete>
                            </LoadScript>

                            {/* <input
                              type="text"
                              className="form-control bookinput"
                              id="enteraddress"
                              aria-describedby="emailHelp"
                              placeholder="Full address"
                              value={userAddress}
                              onChange={(e) => setUserAddress(e.target.value)}
                            /> */}
                            <span
                              className="err-txt"
                            >
                              {addressErr ? addressErr : ""}
                            </span>
                          </div>
                          {/* <div className="text-center pt-3 pb-3">
                            <a role="button" className="book-in">
                              Already have an account? Log in
                            </a>
                          </div> */}
                          <div className="text-center">
                            <button
                              type="button"
                              className="continue-btn"
                              onClick={handleUserDetails}
                            >
                              Continue
                            </button>
                          </div>
                          <p className="text-center ifyouthink mt-5">
                            If you think you may have a medical
                            emergency, immediately call to 911.
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <Step1 />
      }
    </>
  );
}

export default BookYourDoc;
