import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Store from "../../Redux/Store";
import { useSelector } from 'react-redux';
import { ApiCall,PAYPAL_CLIENT_ID } from '../../common/Const';
import { ToastContainer, toast } from 'react-toastify';



function Paypal(props) {
    // const serviceData = useSelector((state) => state.bookingData);
   
    const LoginData = useSelector((state) => state.login);
    const bookingData = useSelector((state) => state.bookingData);
    const userData = bookingData.userData;
    const cartData = bookingData.bookingData;
    const serviceData = bookingData.serviceData;
    const common_symptoms = bookingData.commonSymptoms;
    let service = serviceData;
    let amount = service.amount.toFixed(2);

    // console.log(amount)

    return (
        <PayPalScriptProvider options={{
            "client-id": PAYPAL_CLIENT_ID,
            currency: "USD",
            "disable-funding": 'credit,card,paylater,giropay,sepa,sofort,venmo'
        }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: amount, // Change this to the amount you want to charge
                                },
                            },
                        ],

                    });
                }}

                onApprove={(data, actions) => {
                    // return actions.order.capture().then(function (details) {
                    // console.log(data, 'ddddddddddd')
                    if (data.paymentID) {
                        const user = {
                            payment_type: "paypal",
                            payment_status: "success",
                            payment_id: data.paymentID,
                            client_secret: null,
                            payment_method: null
                        }
                        // console.log(userData, 'kkkkkkk')
                        Store.dispatch({ type: "updateUserData", payload: user });
                        // setTimeout(()=>{
                            // if (props && props.handler()) {
                            //     props.handler();
                            // }
                            let isAuth = localStorage.getItem("user");

                            let BookData = {
                                patient_id: isAuth,
                                name: userData.name,
                                dob: userData.dob,
                                gender_id: userData.gender,
                                address: userData.address,
                                appointment_date: serviceData.date,
                                appointment_time: serviceData.time,
                                service_id: serviceData.service_id,
                                doctor_id: serviceData.clinic,
                                total_amount: serviceData.amount,
                                symptoms: cartData.length > 0 ? JSON.stringify(cartData) : "",
                                payment_type: "paypal",
                                card_id: "",
                                payment_id:  data.paymentID,
                                payment_method: "",
                                client_secret: "",
                                email: LoginData.email,
                                common_symptoms: common_symptoms.length > 0 ? JSON.stringify(common_symptoms) : "",
                                record_id: userData.record_id ? userData.record_id : ""
                            }
                            // alert(JSON.stringify(BookData))
                            
                            // console.log(BookData,'kk')
                
                            ApiCall("patient_appointmenet", BookData).then((data) => {
                                if (data.message == "Success.") {
                                    props.handler(data.result);
                                    // window.scrollTo({ top: 0, behavior: "smooth" })
                                }
                                else {
                                    toast.error(data.message, {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setBtnLoad(false);
                                }
                            })
                        // },3000)
                       
                    }
                    // });
                }}
            />
                                    <ToastContainer />
                                    
                                    <div>
            <span className='pay-class'>Click Here to Pay</span>
            </div>
        </PayPalScriptProvider>
        

    )
}

export default Paypal