import React, { useEffect, useState } from "react";
import { GOOGLE_API_KEY } from "../../common/Const";
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api';
import Rating from '@mui/material/Rating'
import DatePicker from "react-datepicker";
import moment from "moment";
import { ApiCall } from "../../common/Const";
import Store from "../../Redux/Store";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { images } from "../../common/images";



function ClinicList(props) {

    let services = props.services;

    const bookingData = useSelector((state) => state.bookingData);

    const userData = bookingData.userData;
    const serviceData = bookingData.serviceData;


    const [ClinicList, setClinicList] = useState([])


    const [active, setactive] = useState();
    const [activeDoctor, setActiveDoctor] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [doctorList, setdoctorList] = useState([]);
    const [serviceName, setServiceName] = useState(null);
    const [bookType, setBookType] = useState(1);
    const [specialList, setSpecialList] = useState([])
    const [specialService, setSpecialService] = useState("");
    const [modelData, setmodelData] = useState();
    const [errMessage, setErrMessage] = useState("");



    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {
        lat: parseFloat(userData.lat),
        lng: parseFloat(userData.lng)
    }

    const showModel = (data) => {
        setmodelData(data)
    }

    const handleSpecialist = (item) => {
        var delete_close = document.getElementById('continue');
        delete_close.click();
        setBookType(2);
        setServiceName(item.service_type);
        setactive(item.id);
        setActiveDoctor();
        let selectedService = {};
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
        ApiCall("get_specialist", {}).then((data) => {
            if (data.message == "data listed successfully.") {
                setSpecialList(data.result);
            }
            else {
                setSpecialList([]);
            }
        })
    }

    const handleSpecial = (e) => {

        let value = e.target.value;
        setSpecialService(e.target.value);
        let selectedService = {};
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
        setActiveDoctor();
        if (value) {
            let serviceData = {
                service_id: active,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
                specialist_id: e.target.value
            }
            // console.log(serviceData)

            ApiCall("specialist_list", serviceData).then((data) => {
                if (data.message == "Doctor listed Successful.") {
                    setClinicList(data.result);
                    setErrMessage("");
                }
                else if (data.message == "Specialist Booking is not available for your selected Date and Time*") {
                    setErrMessage(data.message);
                    setClinicList([]);

                } else {
                    setClinicList([]);
                    setErrMessage("");
                }

            })
        }
        else {
            setSpecialService("");
        }

    }

    const onMarkerClick = (key) => {
        ClinicList[key].info = true;
        let data = ClinicList;
        setClinicList([...data]);
    };
    const handleClose = (key) => {
        ClinicList[key].info = false;
        let data = ClinicList;
        setClinicList([...data]);
    }


    const handleServices = (data) => {

        if (startDate != null && startTime != null) {
            setActiveDoctor();
            setactive(data.id);
            setServiceName(data.service_type);
            setBookType(1);
            setSpecialService("");

            let serviceData = {
                service_id: data.id,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
            }

            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });

            ApiCall("list_doctors", serviceData).then((data) => {
                if (data.message == "Doctor listed Successful.") {
                    setdoctorList(data.result);
                }
                else {
                    setdoctorList([]);
                }
            })
        }
        else {
            setactive();
            setServiceName();
            setBookType(1);
            setSpecialService("");
        }
    }

    const handleWalkinDoctor = (e) => {
        let value = e.target.value;
        if (value != "") {
            let clinicData = ClinicList.find((e) => e.id == value);
            const selectedService = {
                service_id: active,
                service_name: serviceName,
                clinic: clinicData.id,
                clinic_name: clinicData.name,
                date: moment(startDate).format("YYYY-MM-DD"),
                time: moment(startTime).format("HH:mm"),
                amount: clinicData.fee
            }
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
        }
        else {
            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
        }

    }

    const handleSelect = (id) => {
        let clinicData = ClinicList.find((e) => e.id == id);

        const selectedService = {
            service_id: active,
            service_name: serviceName,
            clinic: clinicData.id,
            clinic_name: clinicData.name,
            date: moment(startDate).format("YYYY-MM-DD"),
            time: moment(startTime).format("HH:mm"),
            amount: clinicData.fee
        }
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
    }

    const handleDoctor = (item, i) => {

        setActiveDoctor(i)
        const selectedService = {
            service_id: active,
            service_name: serviceName,
            clinic: item.id,
            clinic_name: item.name,
            date: moment(startDate).format("YYYY-MM-DD"),
            time: moment(startTime).format("HH:mm"),
            amount: item.fee
        }
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
    }

    const handleDate = (date) => {
        let selectedService = {};
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
        setStartDate(date);
        setStartTime(date);


        setActiveDoctor();
        setdoctorList([]);
        setSpecialService("");
        if (active == 7) {
            document.getElementById('emptyValue').value = "";
        }
        // if (active != 7) {
        //     setactive();
        //     setServiceName();
        //     setBookType(1);
        // }
        if (active == 6 || active == 9 || active == 10) {
            let serviceData = {
                service_id: active,
                appointment_date: date && date != '' ? moment(date).format("YYYY-MM-DD") : "",
                appointment_time: date && date != '' ? moment(date).format("HH:mm") : "",
                lat: userData.lat ? userData.lat : "",
                lng: userData.lng ? userData.lng : ""
            }
            ApiCall("walkinclinic", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else if (active == 8) {
            let serviceData = {
                service_id: active,
                appointment_date: date && date != '' ? moment(date).format("YYYY-MM-DD") : "",
                appointment_time: date && date != '' ? moment(date).format("HH:mm") : "",
            }
            ApiCall("medical_pharamacy", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        } else if (active != "") {
            let serviceData = {
                service_id: active,
                appointment_date: date && date != '' ? moment(date).format("YYYY-MM-DD") : "",
                appointment_time: date && date != '' ? moment(date).format("HH:mm") : "",
            }

            ApiCall("list_doctors", serviceData).then((data) => {
                if (data.message == "Doctor listed Successful.") {
                    setdoctorList(data.result);
                }
                else {
                    setdoctorList([]);
                }
            })
        }
    }

    const handleTime = (time) => {

        // console.log(time)

        if (moment(new Date()).format('YYYY-MM-DD') == moment(startDate).format('YYYY-MM-DD')) {
            if (new Date().getTime() >= new Date(time).getTime()) {
                toast.error("Time must be greater than current time", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
        }

        let selectedService = {};
        Store.dispatch({ type: "updateServiceData", payload: selectedService });
        setStartTime(time);
        setActiveDoctor();
        setdoctorList([]);
        setSpecialService("");
        if (active == 7) {
            document.getElementById('emptyValue').value = "";
        }
        // if (active != 7) {
        //     setactive();
        //     setServiceName();
        //     setBookType(1);
        // }

        if (active == 6 || active == 9 || active == 10) {
            let serviceData = {
                service_id: active,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: time && time != '' ? moment(time).format("HH:mm") : "",
                lat: userData.lat ? userData.lat : "",
                lng: userData.lng ? userData.lng : ""
            }
            ApiCall("walkinclinic", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else if (active == 8) {
            let serviceData = {
                service_id: active,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: time && time != '' ? moment(time).format("HH:mm") : "",
            }
            ApiCall("medical_pharamacy", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        } else if (active != "") {
            let serviceData = {
                service_id: active,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: time && time != '' ? moment(time).format("HH:mm") : "",
            }

            ApiCall("list_doctors", serviceData).then((data) => {
                if (data.message == "Doctor listed Successful.") {
                    setdoctorList(data.result);
                }
                else {
                    setdoctorList([]);
                }
            })
        }




    }

    const handleWalkInClinic = (data) => {

        // setactive();
        if (startDate != null && startTime != null) {
            setActiveDoctor();
            setactive(data.id);
            setBookType(3);
            setServiceName(data.service_type);
            setSpecialService("");
            let serviceData = {
                service_id: data.id,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
                lat: userData.lat ? userData.lat : "",
                lng: userData.lng ? userData.lng : ""
            }

            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
            ApiCall("walkinclinic", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else {
            setactive();
            setServiceName();
            setSpecialService("");
        }

    }

    const handlePharmacy = (data) => {

        if (startDate != null && startTime != null) {
            setActiveDoctor();
            setactive(data.id);
            setBookType(4);
            setServiceName(data.service_type);
            setSpecialService("");
            let serviceData = {
                service_id: data.id,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
            }

            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
            ApiCall("medical_pharamacy", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else {
            setactive();
            setServiceName();
            setSpecialService("");
        }
    }

    const handleLab = (item) => {
        if (startDate != null && startTime != null) {
            setActiveDoctor();
            setactive(item.id);
            setBookType(5);
            setServiceName(item.service_type);
            setSpecialService("");
            let serviceData = {
                service_id: item.id,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
                lat: userData.lat ? userData.lat : "",
                lng: userData.lng ? userData.lng : ""
            }

            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
            ApiCall("walkinclinic", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else {
            setactive();
            setServiceName();
            setSpecialService("");
        }

    }

    const handleImage = (item) => {
        if (startDate != null && startTime != null) {
            setActiveDoctor();
            setactive(item.id);
            setBookType(6);
            setServiceName(item.service_type);
            setSpecialService("");
            let serviceData = {
                service_id: item.id,
                appointment_date: startDate && startDate != '' ? moment(startDate).format("YYYY-MM-DD") : "",
                appointment_time: startTime && startTime != '' ? moment(startTime).format("HH:mm") : "",
                lat: userData.lat ? userData.lat : "",
                lng: userData.lng ? userData.lng : ""
            }

            let selectedService = {};
            Store.dispatch({ type: "updateServiceData", payload: selectedService });
            ApiCall("walkinclinic", serviceData).then((data) => {
                if (data.message == "Success.") {
                    let clinicData = data.data.length > 0 ? data.data : [];
                    clinicData = clinicData.map(element => {
                        return {
                            ...element, info: false
                        }
                    });
                    setClinicList(clinicData);
                }
                else {
                    setClinicList([]);
                }
            })
        }
        else {
            setactive();
            setServiceName();
            setSpecialService("");
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-5">
                    <div className="form-group mb-2 time-select">
                        {/* <label className="mb-2">Time of Day</label>
                                <select className="form-select">
                                    <option>Morning</option>
                                    <option>Afternoon</option>
                                    <option>Right</option>
                                </select> */}
                        <label className="mb-2 d-block">Date</label>
                        <DatePicker showDateSelect minDate={moment().toDate()}
                            selected={startDate} onChange={(date) => handleDate(date)} dateFormat="MM-dd-yyyy" className="form-control" onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            disabledKeyboardNavigation onFocus={e => e.target.blur()} />

                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group time-select">
                        <label className="mb-2">Time of Day</label>
                        <DatePicker
                            selected={startTime}
                            onChange={(date) => handleTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            minDate={moment().toDate()}
                            disabledKeyboardNavigation onFocus={e => e.target.blur()}
                        />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="info-modal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 note-desc1" id="staticBackdropLabel">Note</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="continue"></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center note-desc">Specialists Booking available only on  Monday to Friday between 2  brackets (9:00AM-1:00PM) and (1:00PM to 5:00PM) only.</p>
                            <p className="text-center note-desc1"> Are you sure want to continue?</p>
                        </div>
                        <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" onClick={() => handleSpecialist(modelData)}>Continue</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal" >No</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <p className="type-head">Available Services</p>
                <div className="book-types">

                    {
                        services.length > 0 ?
                            services.map((item, i) => {
                                return item.id != 6 && item.id != 7 && item.id != 8 && item.id != 9 && item.id != 10 ? (

                                    <button key={i} onClick={() => handleServices(item)} className={active != null && active == item.id ? "d-block type-active" : "d-block"}>{item.service_type}</button>
                                ) : item.id == 6 ? (<button onClick={() => handleWalkInClinic(item)} className={bookType == 3 ? "d-block type-active" : "d-block"}>{item.service_type}</button>) : item.id == 7 ? (<button data-bs-toggle="modal" data-bs-target="#info-modal" onClick={() => showModel(item)} className={bookType == 2 ? "d-block type-active" : "d-block"}>{item.service_type}</button>) : item.id == 8 ? (<button onClick={() => handlePharmacy(item)} className={bookType == 4 ? "d-block type-active" : "d-block"}>{item.service_type}</button>) : item.id == 9 ? (<button onClick={() => handleLab(item)} className={bookType == 5 ? "d-block type-active" : "d-block"}>{item.service_type}</button>) : item.id == 10 ? (<button onClick={() => handleImage(item)} className={bookType == 6 ? "d-block type-active" : "d-block"}>{item.service_type}</button>) : ""
                            })

                            : null
                    }

                    {/* <button onClick={() => setBookType(1)} className={bookType == 1 ? "d-block type-active" : "d-block"}>Housecall</button>
                    <button onClick={() => setBookType(2)} className={bookType == 2 ? "d-block type-active" : "d-block"}>Online Visit</button>
                    <button onClick={() => setBookType(3)} className={bookType == 3 ? "d-block type-active" : "d-block"}>Walk-In-Clinic</button>
                    <button onClick={() => setBookType(4)} className={bookType == 4 ? "d-block type-active" : "d-block"}>Specialist</button> */}
                </div>
            </div>
            {doctorList.length > 0 && bookType == 1 ?

                <div className="mt-4 table-responsive">
                    <table className="table clinic-table">
                        <thead>
                            <tr>
                                <th scope="col">Doctor Name</th>
                                <th scope="col">Price</th>
                            </tr>
                        </thead>

                        <tbody>

                            {doctorList.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        {/* <td>{moment(startTime).format("HH:mm")}</td> */}
                                        <td>{item.name}</td>
                                        <td>$ {item.fee}</td>
                                        <td><button onClick={() => handleDoctor(item, i)} className={activeDoctor != null && activeDoctor == i ? "doc-active" : ""}>Book now</button></td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div> : active == null && bookType == 1 ?

                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-6">
                            <p className="text-center no-data">
                                Choose Service from above list to Book the Doctor.
                            </p>
                        </div>
                    </div>
                    : bookType == 1 ? <>
                        <div className="row mt-5">
                            <p className="text-center no-data">
                                No Doctors available
                            </p>
                        </div>
                    </> : null
            }

            {
                bookType == 2 ?
                    specialList.length > 0 ?
                        <>
                            <div div className="form-group mb-3 mt-5 special-select">
                                {/* <label className="mb-2 d-block">select specialist to book doctor</label> */}
                                <select className="form-select" id="emptyValue" onChange={(e) => handleSpecial(e)}>
                                    <option value="">Select Specialist</option>
                                    {
                                        specialList.map((list) => {
                                            return (
                                                <option value={list.id}>{list.specialist_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                            {
                                specialService == "" ?
                                    <>
                                        <div className="row mt-5">
                                            <p className="text-center no-data">
                                                Select Specialist to Book Doctor
                                            </p>
                                        </div>
                                    </> :
                                    ClinicList.length > 0 ?
                                        <div className="mt-4 table-responsive">
                                            <table className="table clinic-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Doctor Name</th>
                                                        <th scope="col">Price</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {ClinicList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                {/* <td>{moment(startTime).format("HH:mm")}</td> */}
                                                                <td>{item.name}</td>
                                                                <td>$ {item.fee}</td>
                                                                <td><button onClick={() => handleDoctor(item, i)} className={activeDoctor != null && activeDoctor == i ? "doc-active" : ""}>Book now</button></td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>

                                        :
                                        <div className="row mt-5">
                                            {
                                                errMessage != "" ? <p className="text-center err-data">
                                                    {errMessage}
                                                </p> : <p className="text-center no-data">
                                                    No Doctors available
                                                </p>
                                            }

                                        </div>

                            }
                        </> :
                        <>
                            <div className="row mt-5">
                                <p className="text-center no-data">
                                    No Special service available
                                </p>
                            </div>
                        </>
                    : bookType == 3 ?


                        ClinicList.length > 0 ?
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col-md-8 mt-2 p-0">
                                        <p className="choose-clinic col-11">Select the clinic where would you like to make your appointment </p>
                                    </div>
                                    {/* <div className="col-md-4 mt-2">
                                        <div className="form-group location-select">
                                            <label className="mb-2">Location</label>
                                            <select className="form-select" onChange={(e) => handleWalkinDoctor(e)}>
                                                <option value="">Select Location</option>
                                                {
                                                    ClinicList.map((clinic) => {
                                                        return (
                                                            <option value={clinic.id}>{clinic.hospital_address}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="mt-5">

                                    <LoadScript
                                        googleMapsApiKey={GOOGLE_API_KEY} // Replace with your Google Maps API key
                                    >
                                        <GoogleMap mapContainerStyle={containerStyle} center={center}
                                            zoom={12}>
                                            {
                                                ClinicList.map((clinic, key) => {
                                                    return (
                                                        <>

                                                            <Marker position={{ lat: parseFloat(clinic.doctor_lat), lng: parseFloat(clinic.doctor_lng) }} onClick={() => onMarkerClick(key)} icon={images.marker} />
                                                            {clinic.info ?

                                                                <InfoWindow
                                                                    position={{ lat: parseFloat(clinic.doctor_lat), lng: parseFloat(clinic.doctor_lng) }}
                                                                    // onClick={() => handleInfo(key)}
                                                                    onCloseClick={() => handleClose(key)}
                                                                >
                                                                    <div>
                                                                        <div className="info-head">
                                                                            <p className="clinic-name">{clinic.name}</p>
                                                                            <Rating name="size-small" defaultValue={4} sx={{
                                                                                "& .MuiRating-iconFilled": {
                                                                                    color: "#1585FF"
                                                                                }
                                                                            }} readOnly />
                                                                        </div>
                                                                        <p className="clic-addr">
                                                                            {clinic.hospital_address}
                                                                        </p>
                                                                        <button className="addr-btn" onClick={() => handleSelect(clinic.id)}>Select</button>
                                                                    </div>
                                                                </InfoWindow> : null
                                                            }
                                                        </>
                                                    )

                                                })
                                            }

                                            {/* {
                                                ClinicList.map((clinic) => {
                                                    return (
                                                        clinic.Info ?

                                                            <InfoWindow
                                                                position={{ lat: parseFloat(clinic.doctor_lat), lng: parseFloat(clinic.doctor_lng) }}
                                                            // onClick={() => handleInfo(key)}
                                                            // onCloseClick={() => setShowInfoWindow(false)}
                                                            >
                                                                <div>
                                                                    <div className="info-head">
                                                                        <p className="clinic-name">{clinic.name}</p>
                                                                        <Rating name="size-small" defaultValue={4} sx={{
                                                                            "& .MuiRating-iconFilled": {
                                                                                color: "#1585FF"
                                                                            }
                                                                        }} readOnly />
                                                                    </div>
                                                                    <p className="clic-addr">
                                                                        {clinic.hospital_address}
                                                                    </p>
                                                                    <button className="addr-btn">Select</button>
                                                                </div>
                                                            </InfoWindow> : null
                                                    )

                                                })
                                            } */}

                                        </GoogleMap>
                                    </LoadScript>
                                </div>

                            </div> :
                            <div className="row mt-5">
                                <p className="text-center no-data">
                                    No Doctors available
                                </p>
                            </div>

                        : bookType == 4 ?
                            <div className="container mt-4">
                                <div className="mt-5">

                                    <LoadScript
                                        googleMapsApiKey={GOOGLE_API_KEY} // Replace with your Google Maps API key
                                    >
                                        <GoogleMap mapContainerStyle={containerStyle} center={center}
                                            zoom={12}>
                                            {
                                                ClinicList.map((clinic, key) => {
                                                    return (
                                                        <>

                                                            <Marker position={{ lat: parseFloat(clinic.pharmacy_lat), lng: parseFloat(clinic.pharmacy_lng) }} onClick={() => onMarkerClick(key)} icon={images.marker} />
                                                            {clinic.info ?

                                                                <InfoWindow
                                                                    position={{ lat: parseFloat(clinic.pharmacy_lat), lng: parseFloat(clinic.pharmacy_lng) }}
                                                                    // onClick={() => handleInfo(key)}
                                                                    onCloseClick={() => handleClose(key)}
                                                                >
                                                                    <div>
                                                                        <div className="info-head">
                                                                            <p className="clinic-name">{clinic.name}</p>
                                                                            <Rating name="size-small" defaultValue={4} sx={{
                                                                                "& .MuiRating-iconFilled": {
                                                                                    color: "#1585FF"
                                                                                }
                                                                            }} readOnly />
                                                                        </div>
                                                                        <p className="clic-addr">
                                                                            {clinic.pharmacy_name}
                                                                        </p>
                                                                    </div>
                                                                </InfoWindow> : null
                                                            }
                                                        </>
                                                    )

                                                })
                                            }

                                        </GoogleMap>
                                    </LoadScript>
                                </div>

                            </div>
                            : bookType == 5 ?
                                <div className="container mt-4">
                                    <div className="mt-5">

                                        <LoadScript
                                            googleMapsApiKey={GOOGLE_API_KEY} // Replace with your Google Maps API key
                                        >
                                            <GoogleMap mapContainerStyle={containerStyle} center={center}
                                                zoom={12}>
                                                {
                                                    ClinicList.map((clinic, key) => {
                                                        return (
                                                            <>

                                                                <Marker position={{
                                                                    lat: parseFloat(clinic.have_labs_lat
                                                                    ), lng: parseFloat(clinic.have_labs_lng
                                                                    )
                                                                }} onClick={() => onMarkerClick(key)} icon={images.marker} />
                                                                {clinic.info ?

                                                                    <InfoWindow
                                                                        position={{
                                                                            lat: parseFloat(clinic.have_labs_lat
                                                                            ), lng: parseFloat(clinic.have_labs_lng
                                                                            )
                                                                        }}
                                                                        // onClick={() => handleInfo(key)}
                                                                        onCloseClick={() => handleClose(key)}
                                                                    >
                                                                        <div>
                                                                            <div className="info-head">
                                                                                <p className="clinic-name">{clinic.hospital_name
                                                                                }</p>
                                                                                <Rating name="size-small" defaultValue={4} sx={{
                                                                                    "& .MuiRating-iconFilled": {
                                                                                        color: "#1585FF"
                                                                                    }
                                                                                }} readOnly />
                                                                            </div>
                                                                            <p className="clic-addr">
                                                                                {clinic.hospital_address}
                                                                            </p>
                                                                        </div>
                                                                    </InfoWindow> : null
                                                                }
                                                            </>
                                                        )

                                                    })
                                                }

                                            </GoogleMap>
                                        </LoadScript>
                                    </div>

                                </div>
                                : bookType == 6 ?
                                    <div className="container mt-4">
                                        <div className="mt-5">

                                            <LoadScript
                                                googleMapsApiKey={GOOGLE_API_KEY} // Replace with your Google Maps API key
                                            >
                                                <GoogleMap mapContainerStyle={containerStyle} center={center}
                                                    zoom={12}>
                                                    {
                                                        ClinicList.map((clinic, key) => {
                                                            return (
                                                                <>

                                                                    <Marker position={{
                                                                        lat: parseFloat(clinic.have_labs_lat
                                                                        ), lng: parseFloat(clinic.have_labs_lng
                                                                        )
                                                                    }} onClick={() => onMarkerClick(key)} icon={images.marker} />
                                                                    {clinic.info ?

                                                                        <InfoWindow
                                                                            position={{
                                                                                lat: parseFloat(clinic.have_labs_lat
                                                                                ), lng: parseFloat(clinic.have_labs_lng
                                                                                )
                                                                            }}
                                                                            // onClick={() => handleInfo(key)}
                                                                            onCloseClick={() => handleClose(key)}
                                                                        >
                                                                            <div>
                                                                                <div className="info-head">
                                                                                    <p className="clinic-name">{clinic.hospital_name
                                                                                    }</p>
                                                                                    <Rating name="size-small" defaultValue={4} sx={{
                                                                                        "& .MuiRating-iconFilled": {
                                                                                            color: "#1585FF"
                                                                                        }
                                                                                    }} readOnly />
                                                                                </div>
                                                                                <p className="clic-addr">
                                                                                    {clinic.hospital_address}
                                                                                </p>
                                                                            </div>
                                                                        </InfoWindow> : null
                                                                    }
                                                                </>
                                                            )

                                                        })
                                                    }

                                                </GoogleMap>
                                            </LoadScript>
                                        </div>

                                    </div>
                                    :
                                    ""

            }


        </>

    );

}

export default ClinicList;