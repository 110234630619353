import React, { useEffect, useState } from 'react'

import { JaaSMeeting } from '@jitsi/react-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiCall } from '../../common/Const';
import "../../css/thankyoufinal.css";
import { images } from "../../common/images";



export default function Meet() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [checkRoom, setCheckRoom] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const name = queryParameters.get("user")
    useEffect(() => {
        ApiCall("checkroomname", { jitsi_url: id }).then((data) => {
            if (data.message == "Valid url.") {
                setCheckRoom(true);
            }
        })
    }, [])

    const handleClose = () => {
        navigate('/thankyou')
    }


    return (

        checkRoom ? <JaaSMeeting
            appId="vpaas-magic-cookie-402c1c1303034f8ba8a4daa362153776"
            roomName={id}
            configOverwrite={{
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
                disableProfile: true,
            }}
            interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,

            }}
            userInfo={{
                displayName: name,
                disableInviteFunctions: true
            }}

            onApiReady={(externalApi) => { }}

            onReadyToClose={() => handleClose()}
            getIFrameRef={(iframeRef) => { iframeRef.style.height = '100vh'; }}
        /> : <>

            <div className="meetEnd">
                <div className='row'>
                    <div className='col-12'>
                        <img
                            src={images.footer_logo}
                            alt="footer-logo"
                            className="meet-logo"
                        />
                    </div>
                </div>
                <h4>Link Expired !</h4>
                <div style={{ marginBottom: "0.3em" }}></div>
                <div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
                    <p>You can go back home and create another booking !</p>
                    <button
                        className='go-btn'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Go Back Home
                    </button>
                </div>
            </div>

        </>

    )
}
