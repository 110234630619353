import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";

function About() {
    return (
        <>
            <Header common_bg={true} about={true} />
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6">
                        <p className="ans-desc">Doctor AllWays was created by the founders of the American
                            Medical Network (AMN), global leaders in on-demand
                            healthcare For travelers. AMN knows the importance of an
                            easy-to-use system that allows you to quickly get reliable and
                            affordable healthcare. They drew on this extensive
                            experience when founding our system-centered network,
                            making accessibility and reliability core principles of Doctor
                            Allways.<br />
                            Our on-demand platform puts you in control of your
                            healthcare, giving you access to what you need when you
                            need it, whether you're at home, at work or travelling. Doctor
                            Allways is the only all-in-one platform offering the best
                            services to fit your needs.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="row justify-content-around">
                            <div className="col-md-3 col-6 text-center">
                                <img src={images.icon1_serv} alt="service-img" className="" />
                                <p className="service-title">Telehealth</p>

                            </div>
                            <div className="col-md-3 col-6 text-center">
                                <img src={images.icon2_serv} alt="service-img" className="" />
                                <p className="service-title">Housecall Visits</p>

                            </div>
                        </div>
                        <div className="row justify-content-around">
                            <div className="col-md-3 col-6 text-center">
                                <img src={images.icon3_serv} alt="service-img" className="" />
                                <p className="service-title">Urgent Care / Walk in Clinics</p>

                            </div>
                            <div className="col-md-3 col-6 text-center">
                                <img src={images.icon4_serv} alt="service-img" className="" />
                                <p className="service-title">Specialist Appointments</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <p className="service-head">How Doctor AllWays Works</p>
                <div className="row">
                    <div className="col-md-6">
                        <p className="how-head">Provide Your Symptoms</p>
                        <p className="how-desc">Get started by telling us your symptoms and their intensity -from
                            bumps and bruises to fevers and fractures. This first step helps us
                            understand your needs, and begins your journey back to better health.
                        </p>
                        <p className="how-head">Choose Your Service</p>
                        <p className="how-desc">Choose the type of service that works best for you. Doctor AllWays
                            offers a wide range of medical services, allowing you to tailor your
                            experience to your requirements. Not sure what you need? We've got
                            a team of trained specialists who can help.</p>
                        <p className="how-head">Confirm & Pay</p>
                        <p className="how-desc">Once you select your date and time, Doctor AllWays will do the magic
                            of making healthcare a seamless and transparent experience.
                        </p>
                    </div>
                    <div className="col-md-6 work-img d-flex justify-content-center">
                        <img src={images.how_it_works} alt="work-img" className="" />
                    </div>
                </div>
            </div>
            <Footer first={true} />
        </>
    );
}

export default About;