import React, { useEffect, useState } from 'react'
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { ApiCall } from '../../common/Const';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '@mui/material';
import moment from "moment";

export default function ServicesProfile() {
  const navigate = useNavigate();

  const [serviceList, setserviceList] = useState([])
  const [modelData, setmodelData] = useState([])
  const [totalCount, setTotalCount] = useState(0);
  const [userId, setUserId] = useState('');

  const [windowSize, setWindowSize] = useState(0)


  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowSize(window.innerWidth);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    let isAuth = localStorage.getItem("user");
    if (isAuth == null) {
      navigate("/login");
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    setUserId(isAuth);
    const user = {
      patient_id: isAuth
    }
    getBookingHistory(user);
  }, [])

  const getBookingHistory = (user, page = 0) => {
    user.page = page;
    ApiCall("patient_servicelist", user)
      .then((data) => {
        if (data.message == "Success.") {
          setTotalCount(data.total_count)
          setserviceList(data.data)
        }
      })
      .catch((err) => console.log(err));
  }

  const showModel = (data) => {
    setmodelData(data)
  }

  const handleChangePage = (page) => {
    const user = {
      patient_id: userId
    }
    getBookingHistory(user, (page - 1) * 6)
  }




  const dummy = [
    {
      id: 1,
      date: "03 jun,15.45",
      service: "Houscall",
      location: "57,2 Lorem Ipsum",
      status: 2,
    },
    {
      id: 2,
      date: "04 jun,05.45",
      service: "Houscall",
      location: "57,2 Lorem Ipsum",
      status: 3,
    },
    {
      id: 3,
      date: "06 jun,01.05",
      service: "video call",
      location: "57,2 Lorem Ipsum",
      status: 4,
    },
  ];
  return (
    <>
      {/* <div className='row justify-content-end'>

        <div className="col-8 col-md-6 col-lg-6 upcoming">
          <button
            type="button"
            className="btn btn-outline-primary  upcoming-btn"
          >
            Upcoming
          </button>
          <button type="button" className="btn btn-outline-primary  past-btn">
            Past
          </button>
        </div>
      </div> */}
      <div className='table-responsive'>
        <table className="table tableclass mt-4">
          <thead className="tableheader">
            <tr>
              <th scope="col">Booking ID</th>
              <th scope="col">Date</th>
              <th scope="col">Service</th>
              <th scope="col">Location</th>
              <th scope="col">Status</th>
              <th scope="col">View Details</th>
            </tr>
          </thead>
          <tbody>

            {

              serviceList.length > 0 ?

                serviceList.map((data, key) => {
                  return (

                    <tr className="fortablelists" tabIndex={data.id}>
                      <td>{data.id}</td>
                      <td>{moment(data.date, 'YYYY-MM-DD').format('MM-DD-YYYY')}, {data.time}</td>
                      <td>{data.service_name}</td>
                      <td>{data.patient_address}</td>
                      <td>
                        {data.status === 10 ? (
                          <button
                            type="button"
                            className="btn btn-primary status_buttons"
                          >
                            upcoming
                          </button>
                        ) : data.status === 1 ? (
                          <button
                            type="button"
                            className="btn btn-warning status_buttons"
                          >
                            Confirmed
                          </button>
                        ) : data.status === 2 ? (
                          <button
                            type="button"
                            className="btn btn-success status_buttons"
                          >
                            Completed
                          </button>
                        ) : data.status === 4 ? (
                          <button
                            type="button"
                            className="btn btn-danger status_buttons"
                          >
                            Cancelled
                          </button>
                        ) : (
                          "----"
                        )}
                      </td>
                      <td className='text-center' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => showModel(data)}>
                        <RemoveRedEyeRoundedIcon style={{ cursor: "pointer" }} /></td>
                    </tr>
                  );
                }

                )

                : null

            }
          </tbody>

        </table>
      </div>
      {serviceList.length == 0 ?
        <>
          <div className='emp-serv'>
            <p>No Services booked!</p>
            <button onClick={() => {
              navigate('/bookus');
              window.scrollTo({ top: 0, behavior: "smooth" })

            }}>book now</button>
          </div>
        </>
        :
        <div className='mt-4 d-flex justify-content-center'>
          <Pagination count={(totalCount / 6).toString().split(".")[1] == undefined ? parseInt((totalCount / 6).toString().split(".")[0]) : parseInt((totalCount / 6).toString().split(".")[0]) + 1} color="primary" siblingCount={0} boundaryCount={2} onChange={(e, page) => { handleChangePage(page) }} defaultPage={1} size={windowSize < 768 ? "small" : "large"} />
        </div>
      }

      {/* see model */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Booking Details</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className='table'>
                <tr className='book-table'>
                  <td>Booking ID	</td>
                  <td>:	</td>
                  <td>{modelData?.id}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Appointment ID	</td>
                  <td>:	</td>
                  <td>{modelData?.appointment_id}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Booked Date/Time	</td>
                  <td>:	</td>
                  <td>{moment(modelData.date, 'YYYY-MM-DD').format('MM-DD-YYYY')}	{modelData?.time}</td>
                </tr>
                <tr className='book-table'>
                  <td>Booked By	</td>
                  <td>:	</td>
                  <td>{modelData?.name}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Doctor Name	</td>
                  <td>:	</td>
                  <td>{modelData?.doctor_name}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Hospital Name	</td>
                  <td>:	</td>
                  <td>{modelData?.hospital_name}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Hospital Address	</td>
                  <td>:	</td>
                  <td>{modelData?.hostpital_address}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Patient Name	</td>
                  <td>:	</td>
                  <td>{modelData?.name}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Patient Email	</td>
                  <td>:	</td>
                  <td>{modelData?.patient_email}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Patient DOB	</td>
                  <td>:	</td>
                  <td>{moment(modelData.patient_dob, 'YYYY-MM-DD').format('MM-DD-YYYY')}</td>
                </tr>
                <tr className='book-table'>
                  <td>Patient Address	</td>
                  <td>:	</td>
                  <td>{modelData?.patient_address}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Patient Gender	</td>
                  <td>:	</td>
                  <td>{modelData?.patient_gender}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Symptom	</td>
                  <td>:	</td>
                  <td>{modelData?.symptom}	</td>
                </tr>
                <tr className='book-table'>
                  <td>CommonSymptom</td>
                  <td>:	</td>
                  <td>{modelData?.common_symptoms}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Service</td>
                  <td>:	</td>
                  <td>{modelData?.service_name}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Service Status	</td>
                  <td>:	</td>
                  <td>{modelData?.status == 10 ? 'Upcoming' : modelData?.status == 1 ? 'Confirmed' : modelData?.status == 2 ? 'Completed' : modelData?.status == 4 ? 'Cancel' : '---'}	</td>
                </tr>
                <tr className='book-table'>
                  <td>Payment Type	</td>
                  <td>:	</td>
                  <td>{modelData?.payment_type}	</td>
                </tr>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
