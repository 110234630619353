import React from "react";
import "../../css/thankyoufinal.css";
import Header from "../pages/Header";
import { images } from "../../common/images";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { Web_url } from "../../common/Const";
import moment from "moment";

function ThankyouPage(props) {
  const LoginData = useSelector((state) => state.login);

  return (
    <>
      <div className="thankyoufinal_bg">
        <div className="container centerclasssthankyou py-5">
          <div className="row classrows justify-content-center align-items-center">
            <div className="col-12 col-lg-9">
              <div className="box-3">
                <div className="box-4">
                  <div className="text-center pt-4 pb-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <h3 className="than">Thank You!</h3>
                        <img
                          src={images.Sucsss_img}
                          alt="order_img"
                          width="8%"
                        ></img>
                        <h6 className="booking_ids">Booking ID-#{props.bookingDetails.appointment_id
                        }</h6>
                        <p className="thankyoupara">
                          Get back to Better Health Your way. Whether at Home, at Work or Traveling, Doctor Allways has the right service for you.
                        </p>
                        <hr style={{ color: "white" }}></hr>
                        <div className="row pt-4 pb-4 this_is_row2">
                          <div className="col-lg-6 col-md-6 col-12 text-start">
                            <div className="">
                              <BookmarkIcon style={{ color: "white" }} />
                              <span
                                className="part2"
                                style={{ color: "white", paddingLeft: "6px" }}
                              >
                                {props.bookingDetails.service_name}
                              </span>
                            </div>
                            <div
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                            >
                              <EventIcon style={{ color: "white" }} />
                              <span
                                className="part2"
                                style={{ color: "white", paddingLeft: "6px" }}
                              >
                                {moment(props.bookingDetails.date, 'YYYY-MM-DD').format('MM-DD-YYYY')},&nbsp; {props.bookingDetails.time}
                              </span>
                            </div>
                            <div>
                              <LocationOnIcon style={{ color: "white" }} />
                              <span
                                className="part2"
                                style={{ color: "white", paddingLeft: "6px" }}
                              >
                                {props.bookingDetails.address}
                              </span>
                            </div>
                            {/* <div className="d-flex flex-row pt-3">
                              <div className="pe-2">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary google-cal"
                                  style={{ color: "white" }}
                                >
                                  Google calender
                                </button>
                              </div>
                              <button
                                type="button"
                                className="btn btn-outline-primary google-cal"
                                style={{ color: "white" }}
                              >
                                Apple calender
                              </button>
                            </div> */}
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 text-end this_is_your_firstcol-6">
                            <div className="this_is_your_first text-center">
                              <h4 className="firstserviceh3">
                                This is your
                              </h4>
                              <p className="firstserviceh3">
                                {" "}
                                {props.bookingDetails.service_name} Service!{" "}
                              </p>
                              <p className="firstservicepara">
                                Thank You for your Booking.
                                {props.bookingDetails.payment_type == "Card" ? "Once Doctor confirm, the amount will be deducted from your Card." : props.bookingDetails.payment_type == "Wallet" ? " Once Doctor confirm, the amount will be deducted from your Wallet." : null}
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr style={{ color: "white" }}></hr>

                        {/* <div className="row this_is_row3">
                          <div className="col-lg-6 col-md-6 col-12 text-start ">
                            <p className="contact-us-through">
                              If you have any problem for your service,
                            </p>
                            <p className="contact-us-through">
                              Please contact us through
                            </p>
                            <div className="pt-3">
                              <img
                                src={images.mobile_png}
                                alt="mobile"
                                width="8%"
                              ></img>
                              <img
                                src={images.whatsappthankyou}
                                alt="mobile"
                                width="8%"
                                style={{ paddingLeft: "5px" }}
                              ></img>
                              <img
                                src={images.messagethankyou}
                                alt="mobile"
                                width="8%"
                                style={{ paddingLeft: "5px" }}
                              ></img>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="book-data">
                              <button
                                type="button"
                                className="btn btn-primary px-4"
                              >
                                {props.bookingDetails.service_name}
                              </button>
                             
                              <Avatar
                                alt={LoginData.id ? LoginData.name : ""}
                                src={
                                  LoginData && LoginData.avatar && LoginData.avatar != ""
                                    ? Web_url.Domain_url + LoginData.avatar
                                    : images.user
                                }
                                sx={{ width: 45, height: 40 }}
                                className="user-pic"
                              />
                            </div>

                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankyouPage;
