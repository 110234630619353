import logo from "../images/top_logo.png";
import user from "../images/user.png";
import common_bg from "../images/common_BG.png";
import login_bg from "../images/login_bg1.png";
import home_bg from "../images/home_bg.png";
import doctor from "../images/doctor.png";
import group from "../images/group.png";
import icon1_serv from "../images/icon1_serv.png";
import icon2_serv from "../images/icon2_serv.png";
import icon3_serv from "../images/icon3_serv.png";
import icon4_serv from "../images/icon4_serv.png";
import get_access1 from "../images/get_access1.png";
import get_access2 from "../images/get_access2.png";
import get_access3 from "../images/get_access3.png";
import right1 from "../images/right-arrow (1).png";
import right2 from "../images/right-arrow (2).png";
import mobile from "../images/mbl_img.png";
import footer_logo from "../images/Doctor_allways_logo_footer.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import pinterest from "../images/pinterest.png";
import doctor2 from "../images/doctor-2.png";
import how_it_works from "../images/how_it_works.png";
import service_bg from "../images/banner.png";
import service1 from "../images/services-1.png";
import service2 from "../images/services-2.png";
import service3 from "../images/services-3.png";
import service4 from "../images/services-4.png";
import Choose_bg from "../images/why_choose_bg.png";
import Choose_bg1 from "../images/why_choose_us_banner.png";
import article_bg from "../images/banner_article.png";
import question_img from "../images/question (2).png";
import chat from "../images/chat (2).png";
import blog_bg from "../images/blog_banner.png";
import blog1 from "../images/blog-1.png";
import blog1a from "../images/blog-1a.png";
import blog2 from "../images/blog-2.png";
import blog2a from "../images/blog-2a.png";
import blog3 from "../images/blog-3.png";
import blog3a from "../images/blog-3a.png";
import help_bg from "../images/help_banner.png";
import help_quest from "../images/help-question.png";
import google from "../images/google.png";
import twitter from "../images/twitter.png";
import fb from "../images/fb.png";
import be from "../images/Be.png";
import person from "../images/person.png";
import news_img1 from "../images/Newsroom/img-1.png";
import news_img2 from "../images/Newsroom/img-2.png";
import news_img3 from "../images/Newsroom/img-3.png";
import profile_pic from "../images/profile_pics.png";
import paymentcard from "../images/card.png";
import news_img4 from "../images/Newsroom/img-4.png";
import news_img5 from "../images/Newsroom/img-5.png";
import news_img6 from "../images/Newsroom/img-6.png";
import like from "../images/Newsroom/like.png";
import comment from "../images/Newsroom/comments.png";
import Latest1 from "../images/Newsroom/latestnews_1.png";
import Latest2 from "../images/Newsroom/latestnews_2.png";
import Latest3 from "../images/Newsroom/latestnews_3.png";
import Latest4 from "../images/Newsroom/latestnews_4.png";
import Latest5 from "../images/Newsroom/latestnews_5.png";
import Latest6 from "../images/Newsroom/latestnews_6.png";
import Sucsss_img from "../images/Success_img.png";
import mobile_png from "../images/mobile_thankyou.png";
import messagethankyou from "../images/messagethankyou.png";
import whatsappthankyou from "../images/whatsappthankyou.png";
import password_reset from "../images/password_reset.png";
import pharmacy from "../images/pharmacy.png";
import lab from "../images/lab.png";
import imaging from "../images/imaging.png";
import marker from "../images/marker.png";






export const images = {
  Hlogo: logo,
  common_bg: common_bg,
  login_bg: login_bg,
  user: user,
  home_bg,
  home_bg,
  doctor: doctor,
  group: group,
  icon1_serv: icon1_serv,
  icon2_serv: icon2_serv,
  icon3_serv: icon3_serv,
  icon4_serv: icon4_serv,
  get_access1: get_access1,
  get_access2: get_access2,
  get_access3: get_access3,
  right1: right1,
  right2,
  right2,
  mobile: mobile,
  footer_logo: footer_logo,
  instagram: instagram,
  facebook: facebook,
  pinterest: pinterest,
  doctor2: doctor2,
  how_it_works: how_it_works,
  service_bg: service_bg,
  service1: service1,
  service2: service2,
  service3: service3,
  service4: service4,
  Choose_bg: Choose_bg,
  Choose_bg1: Choose_bg1,
  article_bg: article_bg,
  question_img: question_img,
  chat: chat,
  blog_bg: blog_bg,
  blog1: blog1,
  blog1a: blog1a,
  blog2: blog2,
  blog2a: blog2a,
  blog3: blog3,
  blog3a: blog3a,
  help_bg: help_bg,
  help_quest: help_quest,
  google: google,
  twitter: twitter,
  fb: fb,
  be: be,
  person: person,
  news_img1: news_img1,
  profile_pic: profile_pic,
  paymentcard: paymentcard,
  news_img2: news_img2,
  news_img3: news_img3,
  news_img4: news_img4,
  news_img5: news_img5,
  news_img6: news_img6,
  like: like,
  comment: comment,
  Latest1: Latest1,
  Latest2: Latest2,
  Latest3: Latest3,
  Latest4: Latest4,
  Latest5: Latest5,
  Latest6: Latest6,
  Sucsss_img: Sucsss_img,
  mobile_png: mobile_png,
  messagethankyou: messagethankyou,
  whatsappthankyou: whatsappthankyou,
  password_reset: password_reset,
  pharmacy: pharmacy,
  lab: lab,
  imaging: imaging,
  marker: marker,
};
