import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { ApiCall, GOOGLE_API_KEY } from "../../common/Const";
import Geocode from "react-geocode";
import { ToastContainer, toast } from 'react-toastify';
import { Pagination } from '@mui/material';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BiSolidEdit } from 'react-icons/bi';








function UserProfle(props) {

    const [cardList, setCardList] = useState([]);
    const [currentpage, setCurrentpage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [callApi, setcallApi] = useState(false);
    const [deleteId, setDeleteId] = useState("");





    const getCardlist = (page = 0) => {

        ApiCall("billing_profile_list", { patient_id: props.user, page: page }).then((data) => {
            // console.log(data)
            if (data.message == "Success") {
                setTotalCount(data.total_count)
                setCardList(data.result);
            }
            else{
                setCardList([]);
            }
        })
    }

    useEffect(() => {
        getCardlist();
        setCurrentpage(1);
    }, [callApi])

    let user = props.user;

    const [from_places, setFromPlaces] = useState("");

    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");
    const [libraries, setLibraries] = useState(["places"])
    const [userName, setUserName] = useState('');
    const [title, setTitle] = useState('');

    const [userAddress, setUserAddress] = useState("");
    const [userDob, setUserDob] = useState("");
    const [userGender, setUserGender] = useState("");
    const [userLat, setUserLat] = useState('');
    const [userLng, setUserLng] = useState('');
    const [nameErr, setNameErr] = useState("");
    const [tiltleErr, setTiltleErr] = useState("");
    const [addressErr, setAddressErr] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);
    const [birthDateErr, setBirthDateErr] = useState("");
    const [genderErr, setGenderErr] = useState("");
    const [editId, setEditId] = useState("")
    const [bill_places, setBillPlaces] = useState("");


    const handleChangePage = (page) => {
        // console.log(page)
        setCurrentpage(page)
        getCardlist((page - 1) * 6)
    }

    const fromLoad = (autocomplete) => {
        setFromPlaces(autocomplete)
    }

    const onFromPlaceChanged = async () => {
        if (from_places !== null && from_places) {
            var Lat = from_places.getPlace().geometry.location.lat();
            var Lon = from_places.getPlace().geometry.location.lng();
            var address = from_places.getPlace().formatted_address;

            setUserAddress(address);
            setUserLat(Lat);
            setUserLng(Lon);
        }
    }

    const billLoad = (autocomplete) => {
        setBillPlaces(autocomplete)
    }

    const onbillPlaceChanged = async () => {
        if (bill_places !== null && bill_places) {
            console.log(bill_places)
            var Lat = bill_places.getPlace().geometry.location.lat();
            var Lon = bill_places.getPlace().geometry.location.lng();
            var address = bill_places.getPlace().formatted_address;
            setUserAddress(address);
            setUserLat(Lat);
            setUserLng(Lon);
        }
    }


    const options = [
        {
            label: "Choose Gender",
            value: "0",
        },
        {
            label: "Male",
            value: "1",
        },
        {
            label: "Female",
            value: "2",
        },
        {
            label: "other",
            value: "3",
        },
    ];

    const saveUser = (e) => {

        e.preventDefault();
        setBtnLoad(true);
        if (updateValidation()) {
            const updatedata = {
                patient_id: user,
                name: userName,
                title: title,
                billing_address: userAddress,
                dob: userDob && userDob != '' ? moment(userDob).format("YYYY-MM-DD") : "",
                gender: userGender,
                lat: userLat,
                lng: userLng,
            };
            ApiCall("billing_profile", updatedata).then((data) => {
                if (data.message == "Success") {
                    toast.success("User Saved Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        var card_close = document.getElementById('card-close');
                        card_close.click();
                        document.getElementById('user-form').reset();
                        setUserName("");
                        setTitle("")
                        setUserAddress("");
                        setUserLat("")
                        setUserLng("")
                        setUserDob("")
                        setUserGender("")
                        setBtnLoad(false);
                        setcallApi(!callApi)
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }, 3000)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setBtnLoad(false);
                    }, 2000);
                }
            })

        }
        else {
            setBtnLoad(false);
        }


    }

    const updateValidation = () => {

        let isValid = true;
        if (title == "") {
            setTiltleErr("Enter your Title");
            isValid = false;
        }
        else {
            setTiltleErr("");
        }
        if (userName == "") {
            setNameErr("Enter your name");
            isValid = false;
        }
        else {
            setNameErr("");
        }
        if (userDob == "") {
            setBirthDateErr("Choose birth date");
            isValid = false;
        }
        else {
            setBirthDateErr("");
        }
        if (userGender == "") {
            setGenderErr("Choose gender");
            isValid = false;
        }
        else {
            setGenderErr("");
        }
        if (userAddress == "") {
            setAddressErr("Enter your address");
            isValid = false;
        }
        else {
            setAddressErr("");
        }
        if (userLat == "" || userLng == "") {
            setAddressErr("Enter Valid address");
            isValid = false;
        }

        return isValid;
    }

    const editUser = (e) => {
        e.preventDefault();
        setBtnLoad(true);
        if (updateValidation()) {
            const updatedata = {
                billing_profile_id: editId,
                name: userName,
                title: title,
                billing_address: userAddress,
                dob: userDob && userDob != '' ? moment(userDob).format("YYYY-MM-DD") : "",
                gender: userGender,
                lat: userLat,
                lng: userLng,
            };
            ApiCall("billing_profile_edit", updatedata).then((data) => {
                // console.log(data)
                if (data.message == "success") {
                    toast.success("User Saved Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        var card_close = document.getElementById('edit-close');
                        card_close.click();
                        document.getElementById('user-form').reset();
                        setUserName("");
                        setTitle("")
                        setUserAddress("");
                        setUserLat("")
                        setUserLng("")
                        setUserDob("")
                        setUserGender("")
                        setBtnLoad(false);
                        setcallApi(!callApi)
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }, 3000)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setBtnLoad(false);
                    }, 2000);
                }
            })

        }
        else {
            setBtnLoad(false);
        }

    }

    const deleteUser = () => {
        let user = {
            billing_profile_id: deleteId
        }
        ApiCall("billing_profile_delete", user).then((data) => {
            if (data.message == "success") {
                toast.success("User Deleted Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                setcallApi(!callApi)
                window.scrollTo({ top: 0, behavior: "smooth" })

            }
            else {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        })

    }

    const changeState = () => {
        setUserName("");
        setTitle("")
        setUserAddress("");
        setUserLat("")
        setUserLng("")
        setUserDob("")
        setUserGender("")
        setAddressErr("")
        setBirthDateErr("")
        setGenderErr("")
        setNameErr("")
        setTiltleErr("")
    }

    const showModel = (data) => {
        // console.log(data)
        setEditId(data.id)
        setUserName(data.name);
        setTitle(data.title)
        setUserDob(new Date(data.dob))
        setUserGender(data.gender)
        setUserAddress(data.billing_address)
        setUserLat(data.lat)
        setUserLng(data.lng)

        // setmodelData(data)
    }

    const handleId = (id) => {
        setDeleteId(id)
    }

    return (
        <>
            <div className='card-sec'>
                <p>User Profile List</p>
                <button type="button" className="btn btn-outline-primary px-4" data-bs-toggle="modal"
                    data-bs-target="#user-save">
                    Add new user
                </button>
            </div>
            <table className="table tableclass mt-4">
                <thead className="tableheader">
                    <tr>
                        <th scope="col">S.NO</th>
                        <th scope="col">Title</th>
                        <th scope="col">Name</th>
                        <th scope="col">Billing Address</th>
                        <th scope="col">Date Of Birth</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>

                <tbody>
                    {cardList.length > 0 && cardList.map((data, key) => {
                        return (
                            <tr className="fortablelists" key={data.id}>
                                <td>{key + 1}</td>
                                <td>{data.title}</td>
                                <td>{data.name}</td>
                                <td>{data.billing_address}</td>
                                <td>{data.dob}</td>
                                <td>{data.gender == 1 ? "Male" : data.gender == 2 ? "Female" : "Other"}</td>
                                <td> <BiSolidEdit role='button' data-bs-toggle="modal" data-bs-target="#user-edit" onClick={() => showModel(data)} />&nbsp;<RiDeleteBin6Line role='button' data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={() => handleId(data.id)} /></td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>


            {cardList.length == 0 ?

                <div className='emp-serv'>
                    <p>No User added!</p>
                </div> :
                <div className='mt-4 d-flex justify-content-center'>
                    <Pagination count={(totalCount / 6).toString().split(".")[1] == undefined ? parseInt((totalCount / 6).toString().split(".")[0]) : parseInt((totalCount / 6).toString().split(".")[0]) + 1} color="primary" onChange={(e, page) => { handleChangePage(page) }} page={currentpage} siblingCount={0} boundaryCount={2} defaultPage={1} size="small" />
                </div>
            }

            <LoadScript libraries={libraries} googleMapsApiKey={GOOGLE_API_KEY}>

                <div className="modal fade" id="user-save" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header logout-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Save User</h1>
                                <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="card-close" onClick={changeState}></button>
                            </div>
                            <div className="modal-body">
                                <form id="user-form">
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control forinputborder"
                                            id="fullname"
                                            aria-describedby="emailHelp"
                                            value={title}
                                            placeholder="Enter your Title"
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <span
                                            className="err-txt"
                                        >
                                            {tiltleErr ? tiltleErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control forinputborder"
                                            id="fullname"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter your name"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <span
                                            className="err-txt"
                                        >
                                            {nameErr ? nameErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Address
                                        </label>

                                        <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged}>
                                            <input type="text" className="form-control forinputborder" id="address"
                                                placeholder="Enter your address" name="address" autoComplete="off" value={userAddress} onChange={(e) => setUserAddress(e.target.value)} />
                                        </Autocomplete>


                                        <span
                                            className="err-txt"
                                        >
                                            {addressErr ? addressErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label className="d-block" style={{ paddingBottom: "15px" }}>DOB</label>
                                        <DatePicker showYearDropdown maxDate={moment().toDate()} onChange={(date) => setUserDob(date)} dateFormat="MM-dd-yyyy" className="form-control profile-date" selected={userDob} onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} placeholderText="Birth date" yearDropdownItemNumber={100} scrollableYearDropdown onFocus={e => e.target.blur()} />
                                        <span
                                            className="err-txt"
                                        >
                                            {birthDateErr ? birthDateErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 gen-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Gender
                                        </label>
                                        <select className="form-select" onChange={(e) => setUserGender(e.target.value)} value={userGender}>
                                            {options.map((option, key) => (
                                                <option value={option.value} key={key}>{option.label}</option>
                                            ))}
                                        </select>
                                        <span
                                            className="err-txt"
                                        >
                                            {genderErr ? genderErr : ""}
                                        </span>
                                    </div>
                                    <button type="button" className={btnLoad ? "book-spinner dot-spinner spinner-border spinner-border-sm mt-3" : "card-btn"} onClick={(e) => saveUser(e)} disabled={btnLoad} >{btnLoad ? "" : "Save"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="user-edit" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header logout-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit User</h1>
                                <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="edit-close" onClick={changeState}></button>
                            </div>
                            <div className="modal-body">
                                <form id="user-form">
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control forinputborder"
                                            id="fullname"
                                            aria-describedby="emailHelp"
                                            value={title}
                                            placeholder="Enter your Title"
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <span
                                            className="err-txt"
                                        >
                                            {tiltleErr ? tiltleErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control forinputborder"
                                            id="fullname"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter your name"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <span
                                            className="err-txt"
                                        >
                                            {nameErr ? nameErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Address
                                        </label>

                                        <Autocomplete onLoad={billLoad} onPlaceChanged={onbillPlaceChanged}>
                                            <input type="text" className="form-control forinputborder" id="address"
                                                placeholder="Enter your address" name="address" autoComplete="off" value={userAddress} onChange={(e) => setUserAddress(e.target.value)} />
                                        </Autocomplete>


                                        <span
                                            className="err-txt"
                                        >
                                            {addressErr ? addressErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 profile-input">
                                        <label className="d-block" style={{ paddingBottom: "15px" }}>DOB</label>
                                        <DatePicker showYearDropdown maxDate={moment().toDate()} onChange={(date) => setUserDob(date)} dateFormat="MM-dd-yyyy" className="form-control profile-date" selected={userDob} onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} placeholderText="Birth date" yearDropdownItemNumber={100} scrollableYearDropdown onFocus={e => e.target.blur()} />
                                        <span
                                            className="err-txt"
                                        >
                                            {birthDateErr ? birthDateErr : ""}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 gen-input">
                                        <label style={{ paddingBottom: "15px" }}>
                                            Gender
                                        </label>
                                        <select className="form-select" onChange={(e) => setUserGender(e.target.value)} value={userGender}>
                                            {options.map((option, key) => (
                                                <option value={option.value} key={key}>{option.label}</option>
                                            ))}
                                        </select>
                                        <span
                                            className="err-txt"
                                        >
                                            {genderErr ? genderErr : ""}
                                        </span>
                                    </div>
                                    <button type="button" className={btnLoad ? "book-spinner dot-spinner spinner-border spinner-border-sm mt-3" : "card-btn"} onClick={(e) => editUser(e)} disabled={btnLoad} >{btnLoad ? "" : "Save"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadScript>

            <div className="modal fade" id="delete_modal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header logout-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Delete User</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center logout-desc">Are you sure want to delete?</p>
                        </div>
                        <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={deleteUser}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>


            <ToastContainer />

        </>
    )
}

export default UserProfle