import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardModel from './CardModel';
import { ApiCall } from '../../common/Const';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { STRIPE_API_KEY } from '../../common/Const';




export default function UserCard() {

    const [cardList, setCardList] = useState([]);
    const [deleteCard, setDeleteCard] = useState(0);
    const [btnLoad, setBtnLoad] = useState(false);
    const [stripePromise, setStripePromise] = useState(() => loadStripe(STRIPE_API_KEY))

    let isAuth = localStorage.getItem("user");

    const getCardlist = () => {

        ApiCall("patient_cardlist", { patient_id: isAuth }).then((data) => {
            if (data.message == "Card listed successfully.") {
                setCardList(data.data);
            }
        })
    }

    useEffect(() => {
        getCardlist();
    }, [])

    const deleteCardFun = (e) => {

        setDeleteCard(e);
    }

    const handleApi = () => {
        getCardlist();
    }

    const setDefault = (e) => {
        setBtnLoad(true);
        ApiCall("patient_cardUpdate", { patient_id: isAuth, card_id: e }).then((data) => {
            if (data.message == "Card default Updated successfull.") {
                getCardlist();
                toast.success("Default card updated successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    setBtnLoad(false);
                }, 3000)
            }
            else {
                setBtnLoad(false);
            }
        })
    }

    const handleDeleteCard = () => {
        setBtnLoad(true);

        ApiCall("patient_deletecard", { patient_id: isAuth, card_id: deleteCard }).then((data) => {
            if (data.message == "Your card details deleted successfull.") {
                getCardlist();
                toast.success("Card deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    setBtnLoad(false);
                    var delete_close = document.getElementById('delete-yes');
                    delete_close.click();
                }, 3000)
            }
            else {
                setBtnLoad(false);
            }
        })
    }


    return (
        <>
            <div className='card-sec'>
                <p>Card List</p>
                <button type="button" className="btn btn-outline-primary px-4" data-bs-toggle="modal"
                    data-bs-target="#card-save">
                    Add Card
                </button>
            </div>
            <table className="table tableclass mt-4">
                <thead className="tableheader">
                    <tr>
                        <th scope="col">S.NO</th>
                        <th scope="col">Card Number</th>
                        <th scope="col">Default</th>
                    </tr>
                </thead>
                <tbody>
                    {cardList.length > 0 && cardList.map((data, key) => {
                        return (
                            <tr className="fortablelists" key={data.id}>
                                {/* <th scope="row">1</th> */}
                                <td>{key + 1}</td>
                                <td>********{data.card_number}</td>
                                <td>{data.card_default == 1 ? "Default card" : <> <button className='default-btn' onClick={() => setDefault(data.id)} disabled={btnLoad}>Set As Default</button>&nbsp;<IconButton onClick={() => deleteCardFun(data.id)}><DeleteForeverOutlined className='delete-card' data-bs-toggle="modal"
                                    data-bs-target="#delete-card"
                                /></IconButton></>
                                }</td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>
            {cardList.length == 0 ?

                <div className='emp-serv'>
                    <p>No Card added!</p>
                </div> : null
            }

            <div className="modal fade" id="card-save" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header logout-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Save Card</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="card-close"></button>
                        </div>
                        <div className="modal-body">
                            <Elements stripe={stripePromise}>

                                <CardModel handler={handleApi} />

                            </Elements>
                        </div>
                        {/* <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={handleLogout}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="modal fade" id="delete-card" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header logout-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Delete Card</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="delete-yes"></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center logout-desc">Are you sure want to delete this card ?</p>
                        </div>
                        <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" onClick={() => handleDeleteCard()} disabled={btnLoad}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal" disabled={btnLoad}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}
