import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";


function Viewblog() {
    return (
        <>
            <Header blog={true} blog_bg={true} />
            <div className="container mt-5">
                <div className="row my-5">
                    <div className="col-md-6">
                        <p className="blog-sm-date">June, 2023</p>
                        <p className="blog-title">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. </p>
                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>

                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>
                        <p className="blog-sub-title">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. </p>
                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>

                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>
                        <p className="blog-sub-title">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. </p>
                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>

                        <p className="blog-desc">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,</p>

                    </div>
                    <div className="col-md-6">
                        <button className="recent-btn">Recent Post</button>
                        <div className="blog-list">
                            <img src={images.blog1a} alt="blog-img" className="blog-sm-img" />
                            <div>
                                <p className="blog-sm-date">June, 2023</p>
                                <p className="blog-title">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. </p>
                            </div>
                        </div>
                        <div className="blog-list">
                            <img src={images.blog2a} alt="blog-img" className="blog-sm-img" />
                            <div>
                                <p className="blog-sm-date">June, 2023</p>
                                <p className="blog-title">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. </p>
                            </div>
                        </div>
                        <div className="blog-list">
                            <img src={images.blog3a} alt="blog-img" className="blog-sm-img" />
                            <div>
                                <p className="blog-sm-date">June, 2023</p>
                                <p className="blog-title">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. </p>
                            </div>
                        </div>
                        <div className="mt-5">
                            <button className="comment-btn d-block">Recent Comments</button>
                            <button className="category-btn d-block">Categories</button>
                            <button className="post-btn d-block">Related Post</button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer first={true} />

        </>

    );

}

export default Viewblog;