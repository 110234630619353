import React from 'react'
import { images } from '../../common/images';
import { DeleteForeverOutlined } from '@mui/icons-material';
function Payment() {



      const dummy = [
        {
          id: 1,
          date: "**** ***** 4587",
          service: "",
          location: "",
          status: 2,
        },
        {
          id: 2,
          date: "**** ***** 3215",
          service: "",
          location: "",
          status: 3,
        },
      ];

  return (
    <>
      <table className="table table-striped table-borderless tableclass">
        <thead className="tableheader">
          <tr>
            {/* <th scope="col">No</th> */}
            <th scope="col">Date</th>
            <th scope="col">Service</th>
            <th scope="col">Store</th>
            <th scope="col">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {dummy.map((data,index) => {
            return (
              <tr className="fortablelists" key={index}>
                {/* <th scope="row">1</th> */}
                <td>
                  <img src={images.paymentcard} alt="no_image" width="8%" />{" "}
                  {data.date}
                </td>
                <td>{data.service}</td>
                <td>{data.location}</td>
                <td>
                  <DeleteForeverOutlined style={{ cursor: "pointer" }} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Payment;
