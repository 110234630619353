import React from 'react'
import { useNavigate } from 'react-router-dom';
import "../../css/thankyoufinal.css";
import { images } from "../../common/images";



export default function ThankYouPage() {
    const navigate = useNavigate();

    return (
        <div className="meetEnd">
            <div className='row'>
                <div className='col-12'>
                    <img
                        src={images.footer_logo}
                        alt="footer-logo"
                        className="meet-logo"
                    />
                </div>
            </div>
            <h4>Thank You!</h4>
            <div style={{ marginBottom: "0.3em" }}></div>
            <div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
                <p>You can go back home and create another booking !</p>
                <button
                   className='go-btn'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Go Back Home
                </button>
            </div>
        </div>
    )
}
