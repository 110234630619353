import React, { useState } from "react";
import Header from "./Header";
import "../../css/password.css";
import { images } from "../../common/images";
import { ApiCall } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


function ConfirmPassword() {

  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const usertoken = queryParameters.get("token");

  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState("");
  const [token, setToken] = useState(usertoken);

  const [passwordErr, setPasswordErr] = useState('');
  const [confirmPassErr, setConfirmPassErr] = useState('');
  const [btnLoad, setBtnLoad] = useState(false);

  const updatePass = (e) => {

    e.preventDefault();
    setBtnLoad(true);

    if (passValidation()) {
      const passData = {
        new_password: password,
        forgotpassword_token: token
      };
      ApiCall("auth/patient_resetpassword", passData).then((data) => {
        if (data.message == "Success.") {
          toast.success("Password changed successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            setPassword('');
            setConfirmPass('');
            setBtnLoad(false);
            navigate('/login');
            window.scrollTo({ top: 0, behavior: "smooth" })
          }, 3000)
        }
        else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => setBtnLoad(false), 2000);
        }
      })
    } else {
      setBtnLoad(false);
    }
  }

  const passValidation = () => {
    let isValid = true;
    if (password == '') {
      setPasswordErr("Enter new password");
      isValid = false;
    }
    else {
      setPasswordErr("");
    }

    if (confirmPass == "") {
      setConfirmPassErr("Enter confirm password");
      isValid = false;
    }
    else if (password != confirmPass) {
      setConfirmPassErr("password and confirm password must be same");
      isValid = false;
    }
    else {
      setConfirmPassErr("");
    }

    return isValid;
  }

  return (
    <>
      <Header display={true} />
      <div className="password_reset_bg">
        <div className="container py-5 ">
          <div className="row forbackborder g-0">
            <div className="col-md-5  password_left">
              <img src={images.password_reset} alt="login-bg" width="100%" height="100%" />
            </div>
            <div className="col-md-7 password-right">
              <div className="row rowpaddings justify-content-center">
                <div className="col-lg-11 col-md-11 col-11">
                  <div className="inner_content_right">
                    <h3 className="text_pswd_reset">Password Reset</h3>
                    <p className="passworddescrib">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standardLorem Ipsum has been the industry's standard
                    </p>
                    <div className="form_of_forgotpassword">
                      <form>
                        {/* <div class="form-group mb-4"> */}
                        <div className="pass-input mb-3">
                          <input type="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} autoComplete="off" value={password}></input>
                          <span
                            className="err-txt"
                          >
                            {passwordErr ? passwordErr : ""}
                          </span>
                        </div>
                        <div className="pass-input mb-3">
                          <input type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPass(e.target.value)} autoComplete="off" value={confirmPass}></input>
                          <span
                            className="err-txt"
                          >
                            {confirmPassErr ? confirmPassErr : ""}
                          </span>
                        </div>
                        <div className="text-end mb-2">
                          <div>
                            <button className={btnLoad ? "send-spinner spinner-border spinner-border-sm" : "btn btn-sm forgotsendbtn"} onClick={updatePass} disabled={btnLoad} >{btnLoad ? "" : "SEND"}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ConfirmPassword;
