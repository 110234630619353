import { legacy_createStore } from "redux";
import Reducer from "./Reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, Reducer)
const store = legacy_createStore(persistedReducer)
export const persist = persistStore(store)
export default store;
