import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";

function Choose() {
    
    return (
        <>
            <Header common_bg={true} choose={true} />
            <div className="container mt-5">
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-6 col-10">
                        <div className="serv-img1">
                        </div>
                    </div>
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center ">
                        <p className="how-head">Qualified and Experienced Providers</p>
                        <p className="how-desc">We put our expert providers - both individuals and fully-staffed
                            urgent care/walk-in clinics - through a rigorous screening process to
                            make sure you receive the best possible quality care. All credentials
                            are verified and background checks are conducted, ensuring that
                            each and every interaction vou have will meet the high standards set
                            by Doctor Allways.</p>
                        {/* <div className="serv-read">
                            <button className="choose-read-btn">READ MORE</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row d-flex justify-content-center ser2-sec">
                    <div className="ser1-sec col-md-6 col-11 d-flex flex-column justify-content-center">
                        <p className="how-head">Reliable Care for All</p>
                        <p className="how-desc">Doctor AllWays is committed to treating all patients, and does not
                            discriminate on the basis of age, gender preference, sexual
                            orientation, culture, disability or race. Our high standards and
                            rigorous screening process ensure that you will receive
                            judgement-free healthcare in a safe environment, regardless of the
                            type of visit.</p>
                        <p className="how-desc">Doctor AllWays offers on-demand healthcare where, when and how
                            you want it. Get back to better health with telemedicine, housecall
                            visits, urgent care/walk-in services and specialist appointments - all
                            through our easily accessible platform.</p>
                        <p className="how-desc">Doctor AllWays has clear, up-front pricing and prepayment options,
                            providing you with a no-nonsense, affordable healthcare option.</p>
                        {/* <div className="serv-read">
                            <button className="choose-read-btn">READ MORE</button>
                        </div> */}
                    </div>

                    <div className="col-md-6 col-10 d-flex align-items-md-end justify-content-md-end choose-img">
                        <div className="serv-img2">
                        </div>
                    </div>
                </div>
            </div>
            <Footer first={true} />

        </>

    );

}

export default Choose;