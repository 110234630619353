import React, { useEffect, useState } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../css/home.css";
import BookLogin from "../booking/BookLogin";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import BookYourDoc from "./BookYourDoc";
import Step2 from "./Step2";
import Step1 from "./Step1";
import Payment from "./Payment";
import ThankyouPage from "./ThankyouPage";
import { ThreeDots } from "react-loader-spinner";
import { ApiCall } from "../../common/Const";
import Store from "../../Redux/Store";
import moment from "moment";




function Step3() {

    const [bookHome, setBookHome] = useState(0);
    const [term, setTerm] = useState(false);

    const [login, setLogin] = useState(1);
    const [btnLoad, setBtnLoad] = useState(false);
    const LoginData = useSelector((state) => state.login);
    const bookingData = useSelector((state) => state.bookingData);
    // const cartData = useSelector((state) => state.bookingData.bookingData);
    // const serviceData = useSelector((state) => state.bookingData.serviceData);
    // const common_symptoms = useSelector((state) => state.bookingData.commonSymptoms);

    const userData = bookingData.userData;
    const cartData = bookingData.bookingData;
    const serviceData = bookingData.serviceData;
    const common_symptoms = bookingData.commonSymptoms;
    const [bookingDetails, setbookingDetails] = useState({});

    const severeData = [
        {
            id: 1,
            name: "No pain"
        },
        {
            id: 2,
            name: "Mild"
        },
        {
            id: 3,
            name: "Moderate"
        },
        {
            id: 4,
            name: "Severe"
        },
        {
            id: 5,
            name: "Very Severe"
        },
        {
            id: 6,
            name: "Worst Pain"
        },
    ];

    useEffect(() => {
        let isAuth = localStorage.getItem("user");
        if (isAuth != null && isAuth != '') {
            setLogin(0);
        }
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [LoginData])

    const [step, setStep] = useState(3);

    const addBooking = () => {

        let isAuth = localStorage.getItem("user");
        setBtnLoad(true);
        if (isAuth == "" || isAuth == null) {
            setLogin(1);
            toast.error("Login to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        else if (!userData.payment_type) {
            toast.error("choose payment method to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        else if (userData.payment_type == "card" && userData.card_id == null) {
            toast.error("choose card to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        else if (userData.payment_type == "paypal" && userData.payment_id == null) {
            toast.error("Complete payment to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        else if (userData.payment_type == "googlePay" && userData.payment_id == null) {
            toast.error("Complete payment to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        else if (userData.payment_type == "applePay" && userData.payment_id == null) {
            toast.error("Complete payment to confirm", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBtnLoad(false);
        }
        // else if (!term) {
        //     toast.error("Agree terms and conditions", {
        //         position: "top-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //     });
        //     setBtnLoad(false);
        // }
        else {
            let BookData = {
                patient_id: isAuth,
                name: userData.name,
                dob: userData.dob,
                gender_id: userData.gender,
                address: userData.address,
                appointment_date: serviceData.date,
                appointment_time: serviceData.time,
                service_id: serviceData.service_id,
                doctor_id: serviceData.clinic,
                total_amount: serviceData.amount,
                symptoms: cartData.length > 0 ? JSON.stringify(cartData) : "",
                payment_type: userData.payment_type,
                card_id: userData.payment_type == "card" ? userData.card_id : "",
                payment_id: userData.payment_type == "paypal" || userData.payment_type == "googlePay" || userData.payment_type == "applePay" ? userData.payment_id : "",
                payment_method: userData.payment_type == "googlePay" || userData.payment_type == "applePay" ? userData.payment_method : "",
                client_secret: userData.payment_type == "googlePay" || userData.payment_type == "applePay" ? userData.client_secret : "",
                email: LoginData.email,
                common_symptoms: common_symptoms.length > 0 ? JSON.stringify(common_symptoms) : "",
                record_id: userData.record_id ? userData.record_id : ""
            }
            // alert(JSON.stringify(BookData))

            // console.log(BookData,'kk')

            ApiCall("patient_appointmenet", BookData).then((data) => {
                if (data.message == "Success.") {
                    setbookingDetails(data.result);
                    toast.success("Booking placed successfully.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    Store.dispatch({ type: "clearBookingData", payload: {} });
                    setStep(4);
                    window.scrollTo({ top: 0, behavior: "smooth" })
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setBtnLoad(false);
                }
            })
        }
    }

    const changeStep = (data) => {
        // console.log(data)
        setbookingDetails(data);
        toast.success("Booking placed successfully.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        Store.dispatch({ type: "clearBookingData", payload: {} });
        setStep(4);
        window.scrollTo({ top: 0, behavior: "smooth" })
    }


    return (
        <>
            {
                bookHome == 0 ?
                    <>
                        {
                            step == 3 ?
                                <div className="common-bg py-5">
                                    <div className="container book-content">
                                        <div className="row px-lg-5 px-2">
                                            <div className="col-md-8">
                                                <p className="step-head">Book your Service</p>
                                                <p className="step-desc">Get back to Better Health Your way. Whether at Home, at Work or Traveling, Doctor Allways has the right service for you.</p>
                                            </div>
                                            <div className="col-md-4 d-flex flex-column justify-content-end align-items-center">
                                                <div className="step-list">
                                                    <a onClick={() => (setStep(1))} className={step == 1 ? "step-left1 step-active" : "step-left1"} role="button">1</a>
                                                    <a onClick={() => (setStep(2))} className={step == 2 ? "step-left step-active" : "step-left"} role="button">2</a>
                                                    <a onClick={() => (setStep(3))} className={step == 3 ? "step-active" : ""} role="button">3</a>
                                                </div>
                                                <p className="right-head">{step == 1 ? "Symptoms" : step == 2 ? "Service" : "Checkout"}</p>
                                            </div>
                                        </div>
                                        <div className="row px-lg-5 px-2 mt-1">
                                            <div className="col-md-7 left-content-steps">
                                                <Payment handler={changeStep} />
                                                {login == 1 ?
                                                    <BookLogin /> : ""}
                                            </div>
                                            <div className="col-md-5 px-lg-5 px-2 mt-md-1 mt-4 d-flex flex-column justify-content-between">
                                                <div>
                                                    <div className="right-name-sec">
                                                        <p className="name-con">{userData.name} / {userData.age}</p>
                                                        <a className="name-edit" role="button" onClick={() => setBookHome(1)}>Edit</a>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="right-sym-sec">
                                                            <p className="sym-head mb-1">Your Symptoms</p>
                                                            <a className="name-edit mt-2" role="button" onClick={() => setStep(1)}>Edit</a>
                                                        </div>
                                                        {/* <i className="fa-regular fa-money-bill" style={{ color: "black" }}></i> */}
                                                        {cartData.map((symptom, k) => {
                                                            return (
                                                                <div className="right-name-sec mt-3" key={k}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="name-con">{symptom.symptoms_name}</p>
                                                                    </div>
                                                                    <div className="sym-status">
                                                                        {severeData.map((ele) => {

                                                                            if (ele.id == symptom.severe_id)
                                                                                return ele.name
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )

                                                        })}
                                                        {common_symptoms.map((symptom, k) => {

                                                            return (


                                                                <div className="right-name-sec mt-3" key={k}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="name-con">{symptom.symptoms_name}</p>
                                                                    </div>
                                                                    <div className="sym-status">

                                                                        {severeData.map((ele) => {

                                                                            if (ele.id == symptom.severe_id)
                                                                                return ele.name
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )

                                                        })}
                                                    </div>
                                                    {

                                                        serviceData.service_name ?
                                                            <div className="mt-4">
                                                                <div className="right-sym-sec">
                                                                    <p className="sym-head mb-1">Scheduling</p>
                                                                    <a className="name-edit mt-2" role="button" onClick={() => setStep(2)}>Edit</a>
                                                                </div>
                                                                <div className="schedule-class row">
                                                                    <div className="col-lg-6">
                                                                        <div className="show-service">

                                                                            <BookmarkIcon style={{ color: "#1585FF" }} />
                                                                            <p>

                                                                                {serviceData.clinic_name},<br />{serviceData.service_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 p-lg-0">
                                                                        <p><EventIcon style={{ color: "#1585FF" }} />&nbsp;&nbsp;{moment(serviceData.date, 'YYYY-MM-DD').format('MM-DD-YYYY')},&nbsp;{serviceData.time}</p>
                                                                    </div>
                                                                    {/* <div className="col-md-10">
                                                                        <p><BookmarkIcon style={{ color: "#1585FF" }} />&nbsp;&nbsp;{userData.address}</p>
                                                                    </div> */}
                                                                </div>
                                                            </div> : null
                                                    }

                                                </div>
                                                <div>
                                                    <div className="d-flex flex-column align-items-end pb-4">
                                                        <span className="total-text">Total</span>
                                                        <p className="total-amount mb-1">${serviceData.amount}</p>
                                                        {/* <a role="button" className="promo-class">Have a promo code?</a>
                                                        <div className="mt-2 terms-class">
                                                            <label>I agree the terms & conditions</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <input type="checkbox" id="terms" name="terms" onChange={(e) => setTerm(e.target.checked)} />
                                                        </div> */}
                                                    </div>

                                                    {
                                                        userData.payment_type =="paypal" || userData.payment_type =="googlePay" || userData.payment_type =="applePay"?
                                                        null : 
                                                        btnLoad ? <div className="dot-spinner">
                                                            <ThreeDots
                                                                height="80"
                                                                width="80"
                                                                radius="9"
                                                                color="#1585FF"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div> : <button className="step-continue" onClick={() => addBooking()}>CONFIRM</button>
                                                    }


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <ToastContainer />
                                </div> : step == 2 ?
                                    <Step2 userData={userData} cartData={cartData} /> : step == 1 ? <Step1 /> : step == 4 ? <ThankyouPage bookingDetails={bookingDetails} /> : null
                        }

                    </>
                    : <BookYourDoc />
            }
        </>
    );

}

export default Step3;