import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import Faq from "./Faq";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';




function Help() {
    const navigate = useNavigate();

    const [message, setMessage] = useState('')
    const [messageErr, setMessageErr] = useState('')

    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState('')

    const [name, setName] = useState('')
    const [nameErr, setNameErr] = useState('')

    const [topic, setTopic] = useState('')
    const [topicErr, setTopicErr] = useState('')

    const [checkActive, setCheckActive] = useState("general")

    const handleQues = (value) => {

        setCheckActive(value)
    }

    const sendMessage = () => {
        if (validateMsg()) {
            let msgData = {
                message: message,
                email: email,
                name: name,
                topic: topic
            }

            ApiCall("helpers", msgData).then((data) => {
                if (data.message == "Success.") {
                    toast.success("Request sent successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setEmail('');
                    setMessage('');
                    setName('')
                    setTopic('')
                    document.getElementById('help-form').reset();
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })



        }
    }

    const validateMsg = () => {

        let isvalid = true;
        if (message == "") {
            setMessageErr("Enter message")
            isvalid = false;
        }
        else {
            setMessageErr("")
        }

        if (email == "") {
            setEmailErr("Enter email")
            isvalid = false;
        }
        else {
            setEmailErr("")
        }

        if (email != "") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isvalid = false;
                setEmailErr("Enter valid Email");
            }
        }
        if (name == "") {
            setNameErr("Enter name")
            isvalid = false;
        }
        else {
            setNameErr("")
        }
        if (topic == "") {
            setTopicErr("Select topic")
            isvalid = false;
        }
        else {
            setTopicErr("")
        }
        return isvalid;
    }



    return (
        <>
            <Header help={true} />
            <div className="help-con-bg py-5">
                <div className="container pb-5">
                    <p className="help-sec-head">Get In Touch, We'd Love<br /> to Hear From You</p>
                    <form id="help-form">
                        <div className="row justify-content-center mt-4">
                            <div className="col-9">
                                <p className="help-sec-desc">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s.</p>
                                <div className="form-group mb-3 help-text">
                                    <textarea className="form-control textHelp" id="exampleFormControlTextarea1" rows="3" placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                    <span
                                        className="err-txt"
                                    >
                                        {messageErr ? messageErr : ""}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="help-name">
                                            <input type="email" className="form-control textHelp" id="exampleFormControlInput1" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                            <span
                                                className="err-txt"
                                            >
                                                {emailErr ? emailErr : ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="help-name">
                                            <input type="text" className="form-control textHelp" id="" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                            <span
                                                className="err-txt"
                                            >
                                                {nameErr ? nameErr : ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group help-select">
                                            <select className="form-select" onChange={(e) => setTopic(e.target.value)}>
                                                <option value="">Select Topic</option>
                                                <option value="Query">Query</option>
                                                <option value="Question">Question</option>
                                                <option value="Enquiry">Enquiry</option>
                                            </select>
                                            <span
                                                className="err-txt"
                                            >
                                                {topicErr ? topicErr : ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="send-btn" onClick={sendMessage}>SEND</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <hr className="help-line"></hr>
                <div className="container-fluid p-5">
                    <p className="help-sec1-head">FAQ</p>
                    <p className="help-sec1-head1">Frequent Questions</p>
                    <div className="row mt-5">
                        <div className="col-lg-2 col-md-3">
                            <ul className="questions-head">
                                <li className={checkActive == "general" ? "list-group-item help-active" : "list-group-item"}><a role="button" onClick={() => handleQues("general")}>General</a></li>
                                <li className={checkActive == "booking" ? "list-group-item help-active" : "list-group-item"}><a onClick={() => handleQues("booking")} role="button">Booking</a></li>
                                <li className={checkActive == "services" ? "list-group-item help-active" : "list-group-item"}><a onClick={() => handleQues("services")} role="button">Services</a></li>
                                <li className={checkActive == "pricing" ? "list-group-item help-active" : "list-group-item"}><a onClick={() => handleQues("pricing")} role="button">Pricing</a></li>
                                <li className={checkActive == "medicals" ? "list-group-item help-active" : "list-group-item"}><a onClick={() => handleQues("medicals")} role="button">Medicals</a></li>
                                <li className={checkActive == "privacy" ? "list-group-item help-active" : "list-group-item"}><a onClick={() => handleQues("privacy")} role="button">Privacy</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-10 col-md-9">
                            <Faq />
                        </div>
                    </div>
                </div>
            </div>
            <div className="help-last-bg">
                <div className="container pt-4">
                    <div className="row justify-content-center mt-md-5 pb-2">
                        <div className="col-md-10 d-flex flex-column align-items-center">
                            <p className="help-head text-center">Time to meet your doctor</p>
                            <p className="help-desc text-center">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a
                                type specimen book.</p>
                            <button className="book-doc-btn mt-3" onClick={() => navigate("/bookus")}>BOOK NOW</button>
                        </div>
                    </div>
                </div>

            </div>

            <ToastContainer />

            <Footer first={false} />

        </>

    );

}

export default Help;