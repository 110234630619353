import React, { useState } from "react";
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { ApiCall, GOOGLE_API_KEY } from "../../common/Const";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import Store from "../../Redux/Store";
import moment from "moment";



function PersonalInfo(props) {

  Geocode.setApiKey(GOOGLE_API_KEY);
  Geocode.setLanguage("en");
  const [libraries, setLibraries] = useState(["places"])


  let { name, email, phone_number, city, state, address, dob, gender, lat, lng, billing_address, billing_status, billing_lat, billing_lng } = props.LoginData;
  let user = props.user;


  const [userName, setUserName] = useState(name);
  const [userEmail, setuserEmail] = useState(email);
  const [phoneNumber, setphoneNumber] = useState(phone_number ? phone_number : "");
  const [from_places, setFromPlaces] = useState("");
  const [userAddress, setUserAddress] = useState(address ? address : "");
  const [billingAddress, setBillingAddress] = useState(billing_address ? billing_address : "");
  const [bill_places, setBillPlaces] = useState("");
  const [billLat, setBillLat] = useState(billing_lat ? billing_lat : "");
  const [billLng, setBillLng] = useState(billing_lng ? billing_lng : "");
  const [billStatus, setBillStatus] = useState(billing_status == 1 ? true : false);


  const [userCity, setUserCity] = useState(city ? city : "");
  const [userState, setUserState] = useState(state ? state : "");
  const [userDob, setUserDob] = useState(dob ? new Date(dob) : "");
  const [userGender, setUserGender] = useState(gender ? gender : "");


  // const [country, setCountry] = useState("");
  const [userLat, setUserLat] = useState(lat);
  const [userLng, setUserLng] = useState(lng);

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumErr, setphoneNumErr] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const fromLoad = (autocomplete) => {
    setFromPlaces(autocomplete)
  }

  const onFromPlaceChanged = async () => {
    if (from_places !== null && from_places) {
      var Lat = from_places.getPlace().geometry.location.lat();
      var Lon = from_places.getPlace().geometry.location.lng();
      var address = from_places.getPlace().name;
      var city = from_places.getPlace().address_components.find((item) =>
        item.types.includes("locality")
      );
      var state = from_places.getPlace().address_components.find((item) =>
        item.types.includes("administrative_area_level_1")
      );
      var country = from_places.getPlace().address_components.find((item) =>
        item.types.includes("country")
      );
      setUserAddress(address);
      setUserLat(Lat);
      setUserLng(Lon);
      setUserCity(city ? city.long_name : "");
      setUserState(state ? state.long_name : "");
      // setCountry(country ? country.long_name : "");
    }
  }
  const billLoad = (autocomplete) => {
    setBillPlaces(autocomplete)
  }

  const onbillPlaceChanged = async () => {
    if (bill_places !== null && bill_places) {
      console.log(bill_places)
      var Lat = bill_places.getPlace().geometry.location.lat();
      var Lon = bill_places.getPlace().geometry.location.lng();
      var address = bill_places.getPlace().formatted_address;

      setBillingAddress(address);
      setBillLat(Lat);
      setBillLng(Lon);
    }
  }
  const updateProfile = (e) => {

    e.preventDefault();
    setBtnLoad(true);

    if (updateValidation()) {
      const updatedata = {
        id: user,
        name: userName,
        email: userEmail,
        address: userAddress,
        city: userAddress,
        state: userState,
        phone_number: phoneNumber,
        dob: userDob && userDob != '' ? moment(userDob).format("YYYY-MM-DD") : "",
        gender: userGender,
        customer_lat: userLat,
        customer_lng: userLng,
        billing_status: billStatus ? 1 : 0,
        billing_address: billingAddress,
        billing_lat: billLat,
        billing_lng: billLng
      };
      console.log(updatedata)
      ApiCall("profile_update", updatedata).then((data) => {
        if (data.message == "Success.") {
          let updateData = {
            name: data.result.name,
            email: data.result.email,
            phone_number: data.result.phone_number,
            address: data.result.address,
            city: data.result.city,
            state: data.result.state,
            avatar: data.result.profile_picture,
            dob: data.result.dob,
            gender: data.result.gender,
            lat: data.result.customer_lat,
            lng: data.result.customer_lng,
            wallet: data.result.wallet_balance,
            billing_status: data.result.billing_status,
            billing_address: data.result.billing_address,
            billing_lat: data.result.billing_lat,
            billing_lng: data.result.billing_lng
          };
          console.log(data)
          Store.dispatch({ type: "addData", payload: updateData });
          toast.success("Profile Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setBtnLoad(false);
            window.scrollTo({ top: 0, behavior: "smooth" })
          }, 3000)
        }
        else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => setBtnLoad(false), 2000);
        }
      })
    }
    else {
      setBtnLoad(false);
    }
  }

  const updateValidation = () => {

    let isValid = true;

    if (userName == "") {
      setNameErr("Enter your name");
      isValid = false;
    }
    else {
      setNameErr("");
    }

    if (userEmail == "") {
      setEmailErr("Enter your email");
      isValid = false;
    } else {
      setEmailErr("");
    }
    if (userEmail != "") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
          lastDotPos > 2
        )
      ) {
        isValid = false;
        setEmailErr("Enter valid Email");
      }
    }

    if (phoneNumber == "") {
      setphoneNumErr("Enter Phone number");
      isValid = false;
    } else if (phoneNumber.length < 10 || phoneNumber.length > 12) {
      setphoneNumErr("Enter valid number");
      isValid = false;
    }
    else {
      setphoneNumErr("");
    }
    return isValid;
  }

  const checkPhoneNumber = (e) => {
    let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if (!pattern.test(e.target.value)) {
      setphoneNumErr("Enter digits only");
      e.target.value = "";
    }
    else {
      setphoneNumErr("");
      setphoneNumber(e.target.value);
    }
  }
  const options = [
    {
      label: "Choose Gender",
      value: "0",
    },
    {
      label: "Male",
      value: "1",
    },
    {
      label: "Female",
      value: "2",
    },
    {
      label: "other",
      value: "3",
    },
  ];
  const addressCheck = (e) => {
    setBillStatus(e.target.checked)

  }
  return (

    <>
      <LoadScript libraries={libraries} googleMapsApiKey={GOOGLE_API_KEY}>
        <form>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 personalinfos">
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control forinputborder"
                  id="fullname"
                  aria-describedby="emailHelp"
                  placeholder="Enter your full name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <span
                  className="err-txt"
                >
                  {nameErr ? nameErr : ""}
                </span>
              </div>
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  Address
                </label>
                <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged}>
                  <input type="text" className="form-control forinputborder" id="address"
                    placeholder="Enter your address" name="address" autoComplete="off" value={userAddress} onChange={(e) => setUserAddress(e.target.value)} />
                </Autocomplete>
              </div>
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  State
                </label>
                <input
                  type="text"
                  className="form-control forinputborder"
                  id="state"
                  placeholder="Enter your state"
                  value={userState}
                  onChange={(e) => setUserState(e.target.value)}
                />
              </div>

              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  City
                </label>
                <input
                  type="text"
                  className="form-control forinputborder"
                  id="city"
                  placeholder="Enter your City"
                  value={userCity}
                  onChange={(e) => setUserCity(e.target.value)}
                />
              </div>
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }} className="d-block">
                  Billing Address

                </label>
                <input type="checkbox" onChange={(e) => addressCheck(e)} checked={billStatus}></input>&nbsp;&nbsp;&nbsp;

                <label style={{ paddingBottom: "15px" }}>
                  Same as Address

                </label>
                {
                  billStatus ? null :
                    <Autocomplete onLoad={billLoad} onPlaceChanged={onbillPlaceChanged}>
                      <input type="text" className="form-control forinputborder" id="bill_address"
                        placeholder="Enter your address" name="Billing address" autoComplete="off" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
                    </Autocomplete>
                }

              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 personalinfos">
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control forinputborder"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email"
                  value={userEmail}
                  onChange={(e) => setuserEmail(e.target.value)}
                />
                <span
                  className="err-txt"
                >
                  {emailErr ? emailErr : ""}
                </span>
              </div>
              <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control forinputborder"
                  id="phonenumber"
                  placeholder="Enter your Phone Number"
                  value={phoneNumber}
                  onChange={(e) => checkPhoneNumber(e)}
                />
                <span
                  className="err-txt"
                >
                  {phoneNumErr ? phoneNumErr : ""}
                </span>
              </div>

              <div className="form-group mb-4 profile-input">
                <label className="d-block" style={{ paddingBottom: "15px" }}>DOB</label>
                <DatePicker showYearDropdown selected={userDob ? userDob : ""} maxDate={moment().toDate()} onChange={(date) => setUserDob(date)} dateFormat="MM-dd-yyyy" className="form-control profile-date" value={userDob} onKeyDown={(e) => {
                  e.preventDefault();
                }} placeholderText="Birth date" yearDropdownItemNumber={100} scrollableYearDropdown />
              </div>

              <div className="form-group mb-4 gen-input">
                <label style={{ paddingBottom: "15px" }}>
                  Gender
                </label>
                <select className="form-select" value={userGender} onChange={(e) => setUserGender(e.target.value)}>
                  {options.map((option, key) => (
                    <option value={option.value} key={key}>{option.label}</option>
                  ))}
                </select>
              </div>

              {/* <div className="form-group mb-4 profile-input">
                <label style={{ paddingBottom: "15px" }}>
                  Country
                </label>
                <input
                  type="text"
                  className="form-control forinputborder"
                  id="country"
                  placeholder="Enter your Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div> */}
              <input
                type="hidden"
                id="lat"
                value={userLat}
                onChange={(e) => setUserLat(e.target.value)}
              />
              <input
                type="hidden"
                id="lng"
                value={userLng}
                onChange={(e) => setUserLng(e.target.value)}
              />

              <div className="text-end mt-5">
                <button type="button" className={btnLoad ? "update-spinner spinner-border spinner-border-sm" : "btn btn-primary"} onClick={updateProfile} disabled={btnLoad} >{btnLoad ? "" : "Save"}</button>
              </div>
            </div>
          </div>

        </form>
      </LoadScript>
      <ToastContainer />
    </>
  );
}

export default PersonalInfo;
