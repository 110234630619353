import React from "react";
import "../../css/home.css";
import { images } from "../../common/images";



function LatestNews() {

    return (
        <>

            <div className="container mt-5">
                <p className="lat-head">Latest News </p>
                <div className="row justify-content-center">
                    <div className="col-9">
                        <p className="lat-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 d-flex justify-content-center">

                <button type="button" className="event-btn" >Events</button>
                <button type="button" className="arti-btn">Articles</button>
                <button type="button" className="arti-btn" >Tips</button>
                <button type="button" className="news-grp-btn">News</button>

            </div>
            <div className="container mt-2">
                <div className="row">
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest1} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest2} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest3} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest4} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest5} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={images.Latest6} alt="news-img" className="news-right-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <span className="dura-text">duration 08 min</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <button type="button" className="more-btn">VIEW MORE</button>
            </div>

        </>

    );

}

export default LatestNews;