import React, { useEffect, useState } from 'react'
import { TbCashBanknote } from 'react-icons/tb';
import { BiLogoPaypal } from 'react-icons/bi';
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import { GiWallet } from 'react-icons/gi'
import { FaCcVisa } from 'react-icons/fa';
import { RiMastercardLine } from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements
} from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import Store from "../../Redux/Store";
import { useSelector } from 'react-redux';
import { ApiCall } from '../../common/Const';
import CardModel from '../card/CardModel';
import store from "../../Redux/Store";
import { STRIPE_API_KEY } from '../../common/Const';
import Paypal from './Paypal';
import Gpay from './Gpay';

export default function Payment(props) {

    const [cardList, setCardList] = useState([]);
    const [cardActive, setCardActive] = useState('');
    const [callApi, setCallApi] = useState(false);
    const [user_id, setUser_id] = useState('')
    const [stripePromise, setStripePromise] = useState(() => loadStripe(STRIPE_API_KEY))

    const [recordList, setRecordList] = useState([]);

    const LoginData = useSelector((state) => state.login);
    const serviceData = useSelector((state) => state.bookingData);
    const userData = serviceData.userData;


    useEffect(() => {

        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            const user = {
                patient_id: isAuth
            }
            ApiCall("profile_view", user).then((data) => {
                if (data.message == "Success.") {
                    let wallet = {
                        wallet: data.result.wallet_balance
                    }
                    store.dispatch({ type: "updateWalletData", payload: wallet });
                }
            })
        }

    }, [])

    useEffect(() => {
        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            setUser_id(isAuth);
            const user = {
                patient_id: isAuth
            }
            ApiCall("medical_records_list", user).then((data) => {
                if (data.message == "Success.") {
                    setRecordList(data.result)
                }
            })
        }

    }, [callApi, LoginData])

    useEffect(() => {
        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            const user = {
                patient_id: isAuth
            }
            ApiCall("patient_cardlist", user).then((data) => {
                if (data.message == "Card listed successfully.") {
                    setCardList(data.data)
                }
            })
        }

    }, [callApi, LoginData])


    const handleRecord = (value) => {

        Store.dispatch({ type: "updateMedicalRecord", payload: value });

    }

    // const userdetail = useSelector((state) => state.bookingData.userData);
    const [cashType, setCashType] = useState(null);

    const handlePayment = (type) => {
        if (type == userData.payment_type) {
            return;
        }
        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            const userDat = {
                payment_type: type,
                payment_status: "failed",
                payment_id: null,
                client_secret: null,
                payment_method: null,
            }
            Store.dispatch({ type: "updateUserData", payload: userDat });
            setCashType(type);

            if (type == "card" && cardActive == "") {
                let card_default = cardList.find((e) =>
                    e.card_default == 1)
                if (card_default != undefined) {
                    const userData = {
                        card_id: card_default.id,
                        payment_type: "card"
                    }
                    Store.dispatch({ type: "updateUserData", payload: userData });
                    setCardActive(card_default.id);
                }
            }
        }
        else {
            toast.error("Login to choose payment method", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleCard = (data) => {
        setCardActive(data)
        const userData = {
            card_id: data,
            payment_type: "card"
        }
        Store.dispatch({ type: "updateUserData", payload: userData });
    }

    const handleApi = () => {
        setCallApi(!callApi);
    }
    const addBooking = (data) => {
        // console.log(data)
        props.handler(data);
    }


    return (
        <>
            <p className="pay-head">Select your Payment Method</p>
            <div className="mt-4">
                <ul className="cash-list">
                    {/* {
                        serviceData.service_id != 1 ?
                            <li><a className={cashType == "cash" ? "pay-active" : ""} onClick={() => handlePayment('cash')} role="button"><TbCashBanknote size={28} />&nbsp;<span className="cash-title">Cash</span></a></li>
                            : null
                    } */}
                    {/* <li><a className="" role="button"><BiLogoPaypal size={26} />&nbsp;<span>Paypal</span></a></li> */}
                    {/* {
                        userData.payment_type == "paypal" && userData.payment_status == "success" ?
                            <li><a className={cashType == "paypal" ? "pay-active" : ""} role="button" onClick={() => handlePayment('paypal')}><GiWallet size={24} />&nbsp;<span>Paypal</span></a></li> :
                            <> */}
                    <li><a className={cashType == "card" ? "pay-active" : ""} role="button" onClick={() => handlePayment('card')}><BsCreditCard2FrontFill size={24} />&nbsp;<span>Card</span></a></li>
                    <li><a className={cashType == "Wallet" ? "pay-active" : ""} role="button" onClick={() => handlePayment('Wallet')}><GiWallet size={24} />&nbsp;<span>Wallet</span></a></li>
                    <li><a className={cashType == "paypal" ? "pay-active" : ""} role="button" onClick={() => handlePayment('paypal')}><GiWallet size={24} />&nbsp;<span>Paypal</span></a></li>
                    <li><a className={cashType == "googlePay" ? "pay-active" : ""} role="button" onClick={() => handlePayment('googlePay')}><GiWallet size={24} />&nbsp;<span>Gpay / Apple Pay</span></a></li>

                    {/* </>

                    } */}

                </ul>
            </div>
            {cashType == "card" ?
                <div className="card-class row">
                    <div className="col-md-8">
                        <ul className="card-list">
                            {cardList.length > 0 ?
                                cardList.map((ele) => {

                                    return (
                                        <li key={ele.id} role='button' onClick={() => handleCard(ele.id)}><a className={cardActive == ele.id ? "card-active" : ""}><BsCreditCard2FrontFill size={28} />&nbsp;<span className="">****{ele.card_number}</span></a></li>
                                    )
                                })

                                // <li><a className=""><RiMastercardLine size={26} />&nbsp;<span className="">****1234</span></a></li> 
                                : <span className='no-data'> Add Card to continue </span>}
                        </ul>
                    </div>
                    <div className='col-md-4'>
                        <div className="new-card">
                            {/* <select className="">
                                <option>New Card&nbsp;&nbsp;</option>
                            </select> */}
                            <button className='add_card' data-bs-toggle="modal"
                                data-bs-target="#card-save">Add New card</button>
                        </div>
                    </div>
                    <div className="modal fade" id="card-save" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header logout-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Save Card</h1>
                                    <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="card-close"></button>
                                </div>
                                <div className="modal-body">
                                    <Elements stripe={stripePromise}>

                                        <CardModel handler={handleApi} />

                                    </Elements>
                                </div>
                                {/* <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={handleLogout}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {cashType == "Wallet" ?
                <div className="card-class row">
                    <div className="col-md-8">
                        <p className="total-amount mb-0">${LoginData.wallet}</p>
                        <p className="right-head m-1">Your Wallet Balance</p>
                    </div>
                    <div className='col-md-4'>
                        <div className="new-card">
                            {/* <select className="">
                                <option>New Card&nbsp;&nbsp;</option>
                            </select> */}
                            {/* <button className='add_card'>Add Money</button> */}
                        </div>
                    </div>
                </div>
                : null}
            {cashType == "paypal" && userData.payment_status == "failed" ?
                <div className="card-class row">
                    <div className="col-md-6">
                        <Paypal handler={addBooking} />
                    </div>

                </div>
                : cashType == "paypal" && userData.payment_status == "success" ?
                    <>
                        <p className='pay-con'>Amount Will be deducted after confirmed.</p>
                    </>
                    : null}
            {cashType == "googlePay" && userData.payment_status == "failed" ?
                <div className="card-class row">
                    <div className="col-md-12 mb-4">
                        <Elements stripe={stripePromise}>
                            <Gpay handler={addBooking}/>
                        </Elements>
                    </div>
                </div>
                : cashType == "googlePay" && userData.payment_status == "success" ?
                    <>
                        <p className='pay-con'>Amount Will be deducted after confirmed.</p>
                    </>
                    : null}


            {
                user_id ?
                    <div className="row mt-4">
                        <div div className="form-group mb-3 special-select">
                            <label className="mb-2 d-block">select medical records if any</label>
                            <select className="form-select" onChange={(e) => handleRecord(e.target.value)}>
                                <option value="">Select Medical Records</option>
                                {
                                    recordList.map((list) => {
                                        return (
                                            <option value={list.id}>{list.doucment_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div> : null
            }



        </>
    )
}
