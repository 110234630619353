import React, { useState } from 'react'
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { ApiCall } from '../../common/Const';
import { ToastContainer, toast } from 'react-toastify';



function CardModel(props) {
    

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "black",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontSmoothing: "antialiased",
            },
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#e5424d"
                }
            }
        },
        hidePostalCode: true
    };
    const stripe = useStripe();
    const elements = useElements();
    const [btnLoad, setBtnLoad] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const saveCard = async (e) => {
        e.preventDefault();

        setBtnLoad(true);
        if (elements == null) {
            setBtnLoad(false);
            return;
        }

        const element = elements.getElement(CardElement)
        let isAuth = localStorage.getItem("user");
        const { error, token } = await stripe.createToken(element);
        if (token != undefined) {
            ApiCall("patient_savecard", { patient_id: isAuth, strip_token: token.id })
                .then((data) => {
                    setErrorMessage('')
                    if (data.message == "Card details saved in stripe successfully.") {
                        toast.success("Card saved successfully", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {
                            var card_close = document.getElementById('card-close');
                            card_close.click();
                            setBtnLoad(false);
                            elements.getElement(CardElement).clear();
                            if (props && props.handler()) {
                                props.handler();
                            }
                        }, 3000);
                    } else {
                        toast.error(data.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setBtnLoad(false);
                    }
                })
        }
        else if (error != undefined) {
            setErrorMessage(error.message)
            setBtnLoad(false);
        }
    }

    return (
        <div> <form>
            {/* <PaymentElement /> */}
            <CardElement options={CARD_ELEMENT_OPTIONS} />

            <span className='card-err'>
                {errorMessage}
            </span>
            <button type="button" className={btnLoad ? "book-spinner dot-spinner spinner-border spinner-border-sm mt-3" : "card-btn"}  onClick={(e) => saveCard(e)} disabled={!stripe || !elements || btnLoad} >{btnLoad ? "" : "Save"}</button>

            {/* Show error message to your customers */}
        </form></div>
    )
}
export default React.memo(CardModel);
