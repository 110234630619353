export const GOOGLE_API_KEY = "AIzaSyCGLzwB6NGZYfn2wfdzx59Rq9ilE85mrCI";
export const STRIPE_API_KEY = "pk_test_WDP0fwVbtyJzXdGucvd901EJ";
export const FACEBOOK_APP_ID = "1037176944103373";
export const GOOGLE_CLIENT_ID = "673594778191-kkl5pb5cvu24eh17gd1f7ij4kfoofem6.apps.googleusercontent.com";
export const APPLE_CLIENT_ID = "com.doctorallways.web.client";
export const PAYPAL_CLIENT_ID = "ASq7ICSdWNkj-SFR3Q-Bmp6dzaQCZGXdUdP2SjZdxzRoG0M7XBSOG5rlGVu1zZweiyEMSWCRkEpbLsCc";
export const STRIPE_SECRET_KEY = "sk_test_RXLun9UnNrgStAK3sCHzcijK"; 




export const Web_url = {
    Base_url: 'https://doctorallways.uplogictech.in/api/',
    Domain_url: 'https://doctorallwaysweb.uplogictech.in/',
    App_Name: 'Doctor Allways',
}

export const ApiCall = async (url = '', data = {}, method = 'Post') => {
    const response = fetch(Web_url.Base_url + url, {
        method: method,
        headers: {
            'Authorization': '',
            'Content-Type': 'application/json'
        },

        body: JSON.stringify(data)
    })
    return (await response).json()
}

export const FileApiCall = async (url = '', formData, method = 'Post') => {
    const response = fetch(Web_url.Base_url + url, {
        method: method,
        body: formData
    })
    return (await response).json()
}
