import React, { useState } from "react";
import { images } from "../../common/images";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../common/Const";
import { ToastContainer, toast } from 'react-toastify';

function Footer(props) {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState('')


    const { first } = props;

    const handleHelp = () => {
        navigate("/help")
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    const subEmail = () => {
        if (validateEmail()) {
            let msgData = {
                subscribe_email: email,
            }
            ApiCall("subscribed_mail", msgData).then((data) => {
                if (data.message == "Success.") {
                    toast.success("Subscribed successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setEmail('');
                    document.getElementById('news-mail').reset();
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })

        }

    }
    const validateEmail = () => {

        let isvalid = true;
        if (email == "") {
            setEmailErr("Enter email")
            isvalid = false;
        }
        else {
            setEmailErr("")
        }

        if (email != "") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isvalid = false;
                setEmailErr("Enter valid Email");
            }
        }
        return isvalid;

    }

    return (
        <div className="footer">
            {
                first ?
                    <>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-4 col-md-12 col-9 mt-md-4">
                                    <p className="sub-head">Subscribe to our newsletter</p>
                                    <form id="news-mail">
                                        <div className="sub-email">
                                            <input type="text" name="user_mail" placeholder="Enter your e-mail" id="user_email" onChange={(e) => setEmail(e.target.value)} style={{ color: '#fff' }} />
                                            <button type="button" onClick={subEmail}>
                                                <img
                                                    src={images.right2}
                                                    alt="footer-arrow"
                                                    className="footer-arrow"
                                                />
                                            </button>
                                        </div>
                                        <span
                                            className="err-txt"
                                        >
                                            {emailErr ? emailErr : ""}
                                        </span>
                                    </form>
                                </div>
                                <div className="col-xl-8 mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer-list">
                                                <li className="footer-list-head"><a href="/aboutus">About</a></li>
                                                <li className="footer-list-con"><a href="/chooseus">Our Providers</a></li>
                                                {/* <li><a href="/">Blog</a></li> */}
                                                <li><a href="/help">Help</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer-list">
                                                <li className="footer-list-head"><a href="/services">Services</a></li>
                                                <li className="footer-list-con"><a href="/services">Telehealth</a></li>
                                                <li><a href="/services">Housecall Visits</a></li>
                                                <li>
                                                    <a href="/services">Urgent Care / <br /> Walk in Appointment
                                                    </a></li>
                                                <li>
                                                    <a href="/services">Specialist Appointments
                                                    </a></li> <li>
                                                    <a href="/services">Pharmacy
                                                    </a></li> <li>
                                                    <a href="/services">Lab
                                                    </a></li>
                                                    <li>
                                                    <a href="/services">Imaging
                                                    </a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer-list">
                                                <li className="footer-list-head"><a href="/legals">Legal</a></li>
                                                <li className="footer-list-con"><a href="/legals">Privacy</a></li>
                                                <li><a href="/legals">Term & Conditions</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer-list">
                                                <li className="footer-list-head">Contact Us</li>
                                                <li className="footer-list-con">Address : 20, New York 10100</li>
                                                <li>Phone : +1(347)6822223</li>
                                                <li>Email : info@doctorallways.com</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-4 col-8 mt-3 p-0">
                                    <img
                                        src={images.footer_logo}
                                        alt="footer-logo"
                                        className="footer-logo"
                                    />
                                </div>

                                <div className="col-md-8 col-8 d-flex align-items-md-end justify-content-md-end p-0 mt-3">
                                    <div className="footer1-img-list">
                                        <a href="#" target="blank"><img
                                            src={images.instagram}
                                            alt="instagram-img"
                                            className="instagram-img"
                                        /></a>&nbsp;&nbsp;&nbsp;
                                        <a href="#" target="blank"><img
                                            src={images.facebook}
                                            alt="facebook-img"
                                            className="instagram-img"
                                        /></a>&nbsp;&nbsp;&nbsp;
                                        <a href="#" target="blank"><img
                                            src={images.pinterest}
                                            alt="pinterest-img"
                                            className="instagram-img"
                                        /></a>
                                    </div>

                                </div>
                            </div>
                        </div></> : <>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 col-9 mt-md-4 p-0">
                                    <img
                                        src={images.footer_logo}
                                        alt="footer-logo"
                                        className="footer-logo"
                                    />
                                    <button className="footer-book-btn d-block" onClick={() => navigate("/bookus")}>BOOK NOW</button>
                                </div>
                                <div className="col-lg-8 mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer1-list">
                                                <li className=""><a href="/">About</a></li>
                                                <li className=""><a href="/">Services</a></li>
                                                <li><a href="/chooseus">Our Doctors</a></li>
                                                <li><a href="/help">Help</a></li>
                                                {/* <li><a href="/">Profile</a></li> */}
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-9 p-0">
                                            <ul className="footer1-list">
                                                {/* <li className=""><a href="/">Blog</a></li> */}
                                                <li className=""><a href="/legals">Legals</a></li>
                                                <li><a href="/bookus">Book Now</a></li>
                                                {/* <li>
                                                    <a href="/">American Med</a>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-9 d-md-flex flex-md-column align-items-md-end p-0">
                                            <button className="footer-help-btn" onClick={() => handleHelp()}><img src={images.person} alt="question"
                                                className="question" />&nbsp;&nbsp;&nbsp;NEED HELP?</button>
                                            <div className="footer-img-list">
                                                <a href="#" target="blank"><img src={images.be} alt="question"
                                                    className="" /></a>
                                                <a href="#" target="blank"><img src={images.google} alt="question"
                                                    className="" /></a>
                                                <a href="#" target="blank"><img src={images.twitter} alt="question"
                                                    className="" /></a>
                                                <a href="#" target="blank"><img src={images.fb} alt="question"
                                                    className="" /></a>
                                            </div>
                                            <p className="footer-sub">Subscribe to our newsletter</p>
                                            <form id="news-mail">
                                                <div className="footer-sub-email">
                                                    <input type="text" placeholder="Email" id="sub_email" onChange={(e) => setEmail(e.target.value)} style={{ color: '#fff' }}></input>&nbsp;&nbsp;

                                                    <button className="footer-send-btn" type="button" onClick={subEmail}>SEND</button>
                                                </div>
                                                <span
                                                    className="err-txt"
                                                >
                                                    {emailErr ? emailErr : ""}
                                                </span>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

            <ToastContainer />

        </div>
    );

}
export default Footer;


