import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";
import LatestNews from "./LatestNews";



function Newsroom() {

    return (
        <>
            <Header news={true} />
            <div className="help-con-bg py-5">
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <img src={images.news_img1} alt="news-img" className="news-left-img" />
                                <p className="news-title">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.</p>
                                <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a
                                    type specimen book.</p>
                                <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src={images.comment} alt="news-img" className="comment-img" />
                                &nbsp;&nbsp;
                                <span className="like-count">5</span>
                            </div>
                            <div className="news-right-con mt-4">
                                <div>
                                    <img src={images.news_img4} alt="news-img" className="news-right-img" />
                                    <p className="news-title">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.</p>
                                    <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                    <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                    <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={images.comment} alt="news-img" className="comment-img" />
                                    &nbsp;&nbsp;
                                    <span className="like-count">5</span>
                                </div>
                                <div>
                                    <img src={images.news_img5} alt="news-img" className="news-right-img" />
                                    <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                    <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                    <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={images.comment} alt="news-img" className="comment-img" />
                                    &nbsp;&nbsp;
                                    <span className="like-count">5</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-right-con mt-4">
                                <div>
                                    <img src={images.news_img2} alt="news-img" className="news-right-img" />
                                    <p className="news-title">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.</p>
                                    <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                    <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                    <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={images.comment} alt="news-img" className="comment-img" />
                                    &nbsp;&nbsp;
                                    <span className="like-count">5</span>
                                </div>
                                <div>
                                    <img src={images.news_img3} alt="news-img" className="news-right-img" />
                                    <p className="news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                    <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                    <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={images.comment} alt="news-img" className="comment-img" />
                                    &nbsp;&nbsp;
                                    <span className="like-count">5</span>
                                </div>
                            </div>
                            <div className="mt-4">
                                <img src={images.news_img6} alt="news-img" className="news-left-img" />
                                <p className="news-title">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.</p>
                                <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a
                                    type specimen book.</p>
                                <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;
                                <span className="like-count">8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src={images.comment} alt="news-img" className="comment-img" />
                                &nbsp;&nbsp;
                                <span className="like-count">5</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="news-subscribe">
                    <div className="container">
                        <div className="row justify-content-center mt-4">
                            <div className="col-md-4">
                                <p className="news-sub">Subscribe to our <br /><span> Newsletter</span></p>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="news-sub-email">
                                            <input type="text" placeholder="Email" className="form-control"></input>&nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button className="news-subs-btn">SUBSCRIBE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LatestNews />

            </div>
            <div className="help-last-bg">
                <div className="container pt-4">
                    <div className="row justify-content-center mt-md-5 pb-2">
                        <div className="col-md-10 d-flex flex-column align-items-center">
                            <p className="help-head text-center">Time to meet your doctor</p>
                            <p className="help-desc text-center">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a
                                type specimen book.</p>
                            <button className="book-doc-btn mt-3">BOOK NOW</button>
                        </div>
                    </div>
                </div>

            </div>
            <Footer first={false} />

        </>

    );

}

export default Newsroom;