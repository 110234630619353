import React, { useState } from "react";
import BookLogin from "../booking/BookLogin";
import { ToastContainer, toast } from 'react-toastify';
import { APPLE_CLIENT_ID, ApiCall, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, Web_url } from "../../common/Const";
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialApple
} from 'reactjs-social-login';
import Store from "../../Redux/Store";
import { jwtDecode } from "jwt-decode";
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import OtpInput from 'react18-input-otp';
import $ from "jquery";





function BookSignup() {
    const [signup, setSignup] = useState(1);

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');

    const [nameErr, setNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [phoneNumErr, setphoneNumErr] = useState("");

    const [btnLoad, setBtnLoad] = useState(false);
    const [googlebtn, setGooglebtn] = useState(false);

    const [verifyLoad, setVerifyLoad] = useState(false);
    const [phoneverifyLoad, setPhoneVerifyLoad] = useState(false);
    const [emailVerify, setEmailVerify] = useState(false);
    const [phoneVerify, setPhoneVerify] = useState(false);
    const [valOtp, setvalOtp] = useState("");
    const [verifyOtp, setVerifyOtp] = useState();


    const LoginWithGoogle = async (data) => {
        setGooglebtn(true);
        let userData = {
            access_token: data.access_token ? data.access_token : data.accessToken ? data.accessToken : "",
            email: data.email ? data.email : "",
            name: data.name ? data.name : "",
        }
        await ApiCall("auth/sociallogin", userData).then((res) => {
            if (res.message == "Patient login successfull." || res.message == "Register Successfully.") {
                let loginData = {
                    id: res.result.id,
                    name: res.result.name,
                    email: res.result.email,
                    phone_number: res.result.phone_number,
                    address: res.result.address,
                    city: res.result.city,
                    state: res.result.state,
                    avatar: res.result.profile_picture,
                    dob: res.result.dob,
                    gender: res.result.gender,
                    wallet: res.result.wallet_balance,
                    lat: res.result.customer_lat,
                    lng: res.result.customer_lng,
                    createdAt: res.result.created_at.split(' ')[0]
                };
                Store.dispatch({ type: "addData", payload: loginData });
                localStorage.setItem("user", res.result.id);
                toast.success("Login successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // setGooglebtn(false)
            }
            else {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => setGooglebtn(false), 2000);
            }
        })
    }

    const registerFun = (e) => {

        e.preventDefault();
        setBtnLoad(true);

        if (registerValidation()) {
            const signUp = {
                name: name,
                email: email,
                password: password,
                phone_number: phoneNumber,
            };
            ApiCall("auth/patient_create", signUp).then((data) => {
                let loginData = {
                    id: data.result.id,
                    name: data.result.name,
                    email: data.result.email,
                    phone_number: data.result.phone_number,
                    address: data.result.address,
                    city: data.result.city,
                    state: data.result.state,
                    avatar: data.result.profile_picture,
                    dob: data.result.dob,
                    gender: data.result.gender,
                    wallet: data.result.wallet_balance,
                    lat: data.result.customer_lat,
                    lng: data.result.customer_lng,
                    createdAt: data.result.created_at.split(' ')[0]
                };
                Store.dispatch({ type: "addData", payload: loginData });
                localStorage.setItem("user", data.result.id);
                if (data.message == "Registered Successfully.") {
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setName('');
                        setEmail('');
                        setPassword('');
                        setphoneNumber('');
                        setBtnLoad(false);
                        setSignup(2);
                    }, 3000)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => setBtnLoad(false), 2000);

                }
            })
        } else {
            setBtnLoad(false);
        }
    }

    const registerValidation = () => {

        let isValid = true;

        if (name == "") {
            setNameErr("Enter your name");
            isValid = false;
        }
        else {
            setNameErr("");
        }

        if (email == "") {
            setEmailErr("Enter your email");
            isValid = false;
        } else {
            setEmailErr("");
        }
        if (email != "") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isValid = false;
                setEmailErr("Enter valid Email");
            }
        }
        if (name != '' && email != '' && phoneNumber != "" && password != '' && emailVerify === false) {

            toast.error("Please Verify Your Email", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            isValid = false;
        }
        if (name != '' && email != '' && phoneNumber != "" && password != '' && phoneVerify === false) {

            toast.error("Please Verify Your Phone", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            isValid = false;

        }
        if (password == "") {
            setPassErr("Enter password");
            isValid = false;
        }
        else {
            setPassErr("");
        }

        if (phoneNumber == "") {
            setphoneNumErr("Enter Phone number");
            isValid = false;
        } else if (phoneNumber.length < 10) {
            setphoneNumErr("Enter valid number");
            isValid = false;
        }
        else {
            setphoneNumErr("");
        }
        return isValid;
    }
    const checkPhoneNumber = (e) => {
        // let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        let pattern = new RegExp(/^\+\d{0,15}$/);
        if (!pattern.test(e.target.value)) {
            setphoneNumErr("Enter digits only");
            e.target.value = "";
        }
        else {
            setphoneNumErr("");
            setphoneNumber(e.target.value);
        }
    }

    const LoginWithApple = async (data) => {
        setGooglebtn(true);
        if (data.authorization) {
            // console.log(data, 'data')
            const decoded = jwtDecode(data.authorization.id_token);
            let userData = {
                email: decoded.email ? decoded.email : "",
            }
            // console.log(decoded.email);
            await ApiCall("apple_login", userData).then((res) => {
                if (res.message == "Patient login successfull." || res.message == "Register Successfully.") {
                    let loginData = {
                        id: res.result.id,
                        name: res.result.name,
                        email: res.result.email,
                        phone_number: res.result.phone_number,
                        address: res.result.address,
                        city: res.result.city,
                        state: res.result.state,
                        avatar: res.result.profile_picture,
                        dob: res.result.dob,
                        gender: res.result.gender,
                        wallet: res.result.wallet_balance,
                        lat: res.result.customer_lat,
                        lng: res.result.customer_lng,
                        createdAt: res.result.created_at.split(' ')[0],
                        billing_status: res.result.billing_status,
                        billing_address: res.result.billing_address,
                        billing_lat: res.result.billing_lat,
                        billing_lng: res.result.billing_lng
                    };
                    const bookingDetails = {
                        name: res.result.name,
                        address: res.result.address,
                        dob: res.result.dob != "" && res.result.dob != null ? moment(res.result.dob).format("YYYY-MM-DD") : "",
                        gender: res.result.gender,
                        age: "",
                        lat: res.result.customer_lat,
                        lng: res.result.customer_lng,
                        userType: 0,
                    }
                    Store.dispatch({ type: "addUserdata", payload: bookingDetails });
                    Store.dispatch({ type: "addData", payload: loginData });

                    localStorage.setItem("user", res.result.id);
                    toast.success("Login successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                }
                else {
                    toast.error(res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => setGooglebtn(false), 2000);
                }
            })

        }
        else {
            setGooglebtn(false);
            toast.error("Login Failed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const getVerification = (isResend) => {

        if (email == '') {
            toast.error('Enter Valid Email', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {

            setVerifyLoad(true);

            let datas = { 'email': email }

            ApiCall("verify_mail", datas).then((data) => {

                if (data.message == 'OTP Sent') {

                    // console.log(data.result.otp);
                    setVerifyOtp(data.result.otp);
                    setVerifyLoad(false);

                    if (isResend == 'normal') {

                        $('#mai').click();
                    }
                }
                else {
                    setVerifyLoad(false);

                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
        }
    }

    const getMobVerification = (isResend) => {
        if (phoneNumber == '') {
            toast.error('Enter Phone Number', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {

            setPhoneVerifyLoad(true);
            let datas = { 'phone_number': phoneNumber }

            ApiCall("verify_phone", datas).then((data) => {

                if (data.message == 'OTP Sent') {

                    // console.log(data.result.otp);
                    setVerifyOtp(data.result.otp);
                    setPhoneVerifyLoad(false);
                    if (isResend == 'normal') {

                        $('#pho').click();
                    }
                }
                else {
                    setPhoneVerifyLoad(false);
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
        }
    }
    const submitMobVerification = (e) => {
        e.preventDefault();

        if (valOtp == verifyOtp) {
            toast.success('Mobile Verified', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setPhoneVerify(true);
            setvalOtp('');
        }
        else {
            toast.error('Otp Mismatch', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setvalOtp('');
        }

    }

    const handleOtpChange = (enteredOtp) => {
     
        setvalOtp(enteredOtp);
    }

    const submitVerification = (e) => {
        e.preventDefault();

        if (valOtp == verifyOtp) {
            toast.success('Email Verified', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setEmailVerify(true);
            setvalOtp('');
        }
        else {
            toast.error('Otp Mismatch', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setvalOtp('');
        }

    }

    return (
        <>
            {
                signup == 1 ?
                    <div className="mt-4 row">
                        <div className="col-md-10">
                            <p className="book-log-head">Sign Up</p>
                            <div className="row mt-4">
                                <div className="col-4">
                                    <LoginSocialFacebook
                                        appId={FACEBOOK_APP_ID}
                                        fieldsProfile={
                                            'id,first_name,last_name,email,name'
                                        }
                                        onResolve={({ provider, data }) => {
                                            LoginWithGoogle(data)
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Facebook</button>
                                    </LoginSocialFacebook>
                                </div>
                                <div className="col-4">
                                    <LoginSocialGoogle
                                        client_id={GOOGLE_CLIENT_ID}
                                        // onLoginStart={onLoginStart}
                                        // redirect_uri="http://localhost:3000"
                                        //scope="openid profile email "
                                        scope="email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar  https://www.googleapis.com/auth/calendar.events"
                                        discoveryDocs="claims_supported"
                                        access_type="offline"
                                        onResolve={({ data }) => {
                                            LoginWithGoogle(data)
                                            // setProvider(provider);
                                            // setProfile(data);
                                        }
                                        }
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Google</button>
                                    </LoginSocialGoogle>
                                </div>
                                <div className="col-4">
                                    <LoginSocialApple
                                        client_id={APPLE_CLIENT_ID}
                                        scope='name email'
                                        redirect_uri={Web_url.Domain_url}
                                        // onLoginStart={onLoginStart}
                                        onResolve={({ provider, data }) => {
                                            LoginWithApple(data);
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <button className="book-fb-btn" disabled={googlebtn}>Apple</button>
                                    </LoginSocialApple>
                                </div>
                            </div>
                            <div className="row  mt-4 book-line-class">
                                <div className="col-6">
                                    <hr className="left-hr"></hr>
                                </div>
                                <span>OR</span>
                                <div className="col-6">
                                    <hr className="right-hr"></hr>
                                </div>
                            </div>
                            <form>
                                <div className="book-email-input mb-3 mt-2">
                                    <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)}></input>
                                    <span
                                        className="err-txt"
                                    >
                                        {nameErr ? nameErr : ""}
                                    </span>
                                </div>
                                <div className="book-email-input mb-3 mt-2">
                                    <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} disabled={emailVerify}></input>
                                    <div className="verify-class">

                                        <span
                                            className="err-txt"
                                        >
                                            {emailErr ? emailErr : ""}
                                        </span>
                                        {emailVerify === false ? <span className={verifyLoad ? "log-spinner spinner-border spinner-border-sm" : "text-end"} style={{ color: "#1585FF", cursor: 'pointer', paddingTop: "2px" }} onClick={() => getVerification('normal')}>{verifyLoad ? "" : "Click to verify email"}</span> : <span className="text-end" style={{ color: "#1585FF" }}><FaCheckCircle size={20} style={{ color: "green" }} /> verified</span>}
                                    </div>
                                </div>
                                <div className="book-pass-input mb-3">
                                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} autoComplete="off"></input>
                                    <span
                                        className="err-txt"
                                    >
                                        {passErr ? passErr : ""}
                                    </span>
                                </div>
                                <div className="book-pass-input mb-3">
                                    <input type="text" placeholder="Phone Number" min="1" onChange={(e) => checkPhoneNumber(e)} disabled={phoneVerify}></input>
                                    <span style={{ color: '#1585FF' }}><FaInfoCircle /> should be added with country code and + symbol eg. (+9197******66)</span>
                                    <div className="verify-class">

                                        <span
                                            className="err-txt"
                                        >
                                            {phoneNumErr ? phoneNumErr : ""}
                                        </span>
                                        {phoneVerify === false ? <span className={phoneverifyLoad ? "log-spinner spinner-border spinner-border-sm" : "text-end"} style={{ color: "#1585FF", cursor: 'pointer', paddingTop: "2px" }} onClick={() => getMobVerification('normal')}>{phoneverifyLoad ? "" : "Click to verify phone"}</span> : <span className="text-end" style={{ color: "#1585FF" }}><FaCheckCircle size={20} style={{ color: "green" }} /> verified</span>}
                                    </div>

                                </div>
                                <div className="book-sign-last mt-3">
                                    <div>
                                        <span>Already have an Account?</span>&nbsp;&nbsp;&nbsp;
                                        <a className="" onClick={() => setSignup(0)} role="button">LOGIN</a>
                                    </div>
                                    <button type="button" className={btnLoad ? "book-spinner spinner-border spinner-border-sm" : "book-log-btn"} onClick={registerFun} disabled={btnLoad} >{btnLoad ? "" : "SIGNUP"}</button>
                                </div>
                            </form>
                        </div>
                        <div className="d-none">
                            <input type="hidden" data-bs-toggle="modal" id='mai' data-bs-target="#email_verify_model" />
                        </div>
                        <div className="d-none">
                            <input type="hidden" data-bs-toggle="modal" id='pho' data-bs-target="#phone_verify_model" />
                        </div>

                        <div className="modal fade" id="email_verify_model" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header logout-header">
                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Enter Verification Code</h1>
                                        <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal"></button>
                                    </div>
                                    <div className="modal-body d-flex justify-content-center">
                                        <OtpInput value={valOtp} onChange={handleOtpChange} numInputs={4} separator={<span className="m-1"></span>} isInputNum={true} inputStyle={{
                                            border: `1px solid #1585ff`,
                                            borderRadius: "4px",
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "14px",
                                            color: '#1585ff',
                                            fontWeight: "400",
                                            // caretColor: "blue"
                                        }}
                                            focusStyle={{
                                                border: `1px solid #1585ff`,
                                                outline: 'none'
                                            }} />
                                    </div>
                                    <p className='text-center' style={{ color: "#1585ff", cursor: 'pointer', fontSize: '13px' }} onClick={() => getVerification('resend')}>Resend OTP</p>
                                    <div className="modal-footer d-flex justify-content-center gap-3">
                                        <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={submitVerification}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="phone_verify_model" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header logout-header">
                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Enter Verification Code</h1>
                                        <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal"></button>
                                    </div>
                                    <div className="modal-body d-flex justify-content-center">
                                        <OtpInput value={valOtp} onChange={handleOtpChange} numInputs={4} separator={<span className="m-1"></span>} isInputNum={true} inputStyle={{
                                            border: `1px solid #1585ff`,
                                            borderRadius: "4px",
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "14px",
                                            color: '#1585ff',
                                            fontWeight: "400",
                                            // caretColor: "blue"
                                        }}
                                            focusStyle={{
                                                border: `1px solid #1585ff`,
                                                outline: 'none'
                                            }} />
                                    </div>
                                    <p className='text-center' style={{ color: "#1585ff", cursor: 'pointer', fontSize: '13px' }} onClick={() => getMobVerification('resend')}>Resend OTP</p>
                                    <div className="modal-footer d-flex justify-content-center gap-3">
                                        <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={submitMobVerification}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <BookLogin />
            }


        </>
    );

}

export default BookSignup;
