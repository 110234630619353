import React from 'react'
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Pagination } from "@mui/material";

 function Medicaments() {

      const dummy = [
        {
          id: 1,
          date: "May 2023",
          service: "Houscall",
          location: "57,2 Lorem Ipsum",
          status: 2,
        },
        {
          id: 2,
          date: "Apr 2023",
          service: "Online Visit",
          location: "57,2 Lorem Ipsum",
          status: 3,
        },
        {
          id: 3,
          date: "March 2023",
          service: "video call",
          location: "57,2 Lorem Ipsum",
          status: 4,
        },
      ];
      
  return (
    <>
      <table className="table table-striped table-borderless tableclass">
        <thead className="tableheader">
          <tr>
            {/* <th scope="col">No</th> */}
            <th scope="col">Date</th>
            <th scope="col">Services</th>
            <th scope="col">Store</th>
            <th scope="col">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {dummy.map((data,index) => {
            return (
              <tr className="fortablelists" key={index}>
                {/* <th scope="row">1</th> */}
                <td>{data.date}</td>
                <td>{data.service}</td>
                <td>{data.location}</td>
                <td>
                  <RemoveRedEyeRoundedIcon style={{ cursor: "pointer" }} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <Pagination count={5} variant="outlined" shape="rounded" />
      </div>
    </>
  );
}

export default Medicaments;
