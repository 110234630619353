import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home"
import Login from "./components/pages/Login";
import SignUp from "./components/pages/SignUp";
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Choose from "./components/pages/Choose";
import Article from "./components/pages/Article";
import Blog from './components/pages/Blog';
import './App.css';
import Viewblog from './components/pages/Viewblog';
import Help from './components/pages/Help';
import Legal from './components/pages/Legal';
import Newsroom from './components/pages/Newsroom';
import ProfileServices from './components/pages/ProfileServices';
import Viewnews from './components/pages/Viewnews';
import Book from './components/booking/Book';
import BookHomePage from './components/booking/BookHomePage';
import BookYourDoc from './components/booking/BookYourDoc';
import ThankyouPage from './components/booking/ThankyouPage';
import PasswordReset from './components/pages/PasswordReset';
import ConfirmPassword from './components/pages/ConfirmPassword';
import Meet from './components/Jitsi/Meet';
import ThankYouPage from './components/Jitsi/ThankYouPage';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="aboutus" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="chooseus" element={<Choose />} />
        <Route path="articles" element={<Article />} />
        <Route path="blogs" element={<Blog />} />
        <Route path="viewBlog" element={<Viewblog />} />
        <Route path="help" element={<Help />} />
        <Route path="legals" element={<Legal />} />
        <Route path="newsroom" element={<Newsroom />} />
        <Route path="profile" element={<ProfileServices />} />
        <Route path="viewnews" element={<Viewnews />} />
        <Route path="bookus" element={<Book />} />
        <Route path="bookhome" element={<BookHomePage />} />
        <Route path="bookyoudoc" element={<BookYourDoc />} />
        <Route path="password_reset" element={<PasswordReset />} />
        <Route path="passwordUpdate" element={<ConfirmPassword />} />
        <Route path="meet/:id" element={<Meet />} />
        <Route path="thankYou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
