import React, { useEffect, useState } from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import Step1 from "../booking/Step1";

import "../../css/home.css";
import BookYourDoc from "./BookYourDoc";

function Book() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <>
            <Header display={true} />
            <BookYourDoc />
            <Footer first={false} />

        </>

    );

}

export default Book;