import React, { useState } from "react";
import Header from "./Header";
import "../../css/password.css";
import { images } from "../../common/images";
import { ToastContainer, toast } from 'react-toastify';
import { ApiCall } from "../../common/Const";

function PasswordReset() {

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const sendMail = (e) => {

    e.preventDefault();
    setBtnLoad(true);

    if (mailValidation()) {
      const mailData = {
        email: email,
      };
      ApiCall("auth/patient_forgotpassword", mailData).then((data) => {
        if (data.message == "Mail sent successfully.") {
          setEmail('');
          toast.success("Reset Link sent to your Email Id.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => setBtnLoad(false), 2000)
        }
        else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => setBtnLoad(false), 2000);
        }
      })
    }
    else {
      setBtnLoad(false);
    }
  }

  const mailValidation = () => {

    let isValid = true;

    if (email == "") {
      setEmailErr("Enter your email");
      isValid = false;
    } else {
      setEmailErr("");
    }
    if (email != "") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
          lastDotPos > 2
        )
      ) {
        isValid = false;
        setEmailErr("Enter valid Email");
      }
    }
    return isValid;
  }

  return (
    <>
      <Header display={true} />
      <div className="password_reset_bg">
        <div className="container py-5 ">
          <div className="row forbackborder g-0">
            <div className="col-md-5  password_left">
              <img src={images.password_reset} alt="login-bg" width="100%" height="100%" />
            </div>
            <div className="col-md-7 password-right">
              <div className="row rowpaddings justify-content-center">
                <div className="col-lg-11 col-md-11 col-11">
                  <div className="inner_content_right">
                    <h3 className="text_pswd_reset">Password Reset</h3>
                    <p className="passworddescrib">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standardLorem Ipsum has been the industry's standard
                    </p>
                    <div className="form_of_forgotpassword">
                      <form>
                        <div className="email-input mb-3">
                          <input value={email} type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)}></input>
                          <span
                            className="err-txt"
                          >
                            {emailErr ? emailErr : ""}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="dothaveaccount">Don't hava an Account? <a href="/signup" style={{ color: "white" }}>Sign Up</a></p>
                          <div>
                            <button className={btnLoad ? "send-spinner spinner-border spinner-border-sm" : "btn btn-sm forgotsendbtn"} onClick={sendMail} disabled={btnLoad} >{btnLoad ? "" : "SEND"}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </>
  );
}

export default PasswordReset;
