import React, { useState } from "react";
import "../../css/home.css";

function LegalContent() {

    const [term, setTerm] = useState(true);
    return (
        <>

            <div className="container mt-5 d-flex justify-content-center">
                <button type="button" className={term ? "term-btn term-btn-active" : "term-btn"} onClick={() => setTerm(!term)}>Terms & Conditions</button>
                <button type="button" className={term ? "privacy-btn" : "privacy-btn term-btn-active"} onClick={() => setTerm(!term)}>Privacy</button>
            </div>
            {
                term ?
                    <div className="container mt-4">
                        <p className="help-sec-head">Terms & Conditions</p>

                        <p className="terms-content">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.</p>
                        <p className="terms-content">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.</p>
                    </div>
                    :
                    <div className="container mt-4">
                        <p className="help-sec-head">Privacy</p>
                        <p className="terms-content">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.</p>
                        <p className="terms-content">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.</p>

                    </div>

            }



        </>

    );

}

export default LegalContent;