import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/profile.css";
import { images } from "../../common/images";
import ServicesProfile from "./ServicesProfile";
import PersonalInfo from "./PersonalInfo";
import MedicalRecords from "./MedicalRecords";
import Medicaments from "./Medicaments";
import Payment from "./Payment";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import store from "../../Redux/Store";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiCall, Web_url } from "../../common/Const";
import UserCard from "../card/UserCard";
import Wallet from "./Wallet";
import moment from "moment";
import UserProfle from "./UserProfle";



function ProfileServices() {

  const navigate = useNavigate();
  const [user, setUser] = useState(null);



  useEffect(() => {
    let isAuth = localStorage.getItem("user");
    if (isAuth == null) {
      navigate("/login");
    }
    store.dispatch({ type: "clearBookingData", payload: {} });
    setUser(isAuth);
  }, [])



  const [service, setServices] = useState("personal_info");

  const LoginData = useSelector((state) => state.login);
  const handleLogout = () => {
    store.dispatch({ type: "clearBookingData", payload: {} });
    store.dispatch({ type: "removeData" });
    localStorage.clear();
    toast.success("Logged out Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate('/');
      window.scrollTo({ top: 0, behavior: "smooth" })
    }, 3000)
  }

  const checkServices = ($val) => {

    setServices($val);

  }

  const avatorUpload = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = async function (e) {
        var splitted = e.target.result.split(",");
        var splitd = splitted[1];
        ApiCall("avatar_upload", { id: user, image: splitd })
          .then((data) => {
            if (data.message == "Success") {
              let loginData = {
                avatar: data.result
              };
              store.dispatch({ type: "updateData", payload: loginData });
              toast.success("Image uploaded Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => console.log(err));
      };
      reader.readAsDataURL(input.files[0]);
    }
  };


  return (
    <>
      <Header profile={true} />
      <div className="profile_2bg">
        <div className="container second_part">
          <div className="row align-items-center">
            <div className="col-8 col-md-6 col-lg-6">
              <div className="profile-first">
                <div className="d-flex justify-content-center align-items-center">
                  <Avatar
                    alt={user && LoginData.name ? LoginData.name : ""}
                    src={
                      LoginData && LoginData.avatar && LoginData.avatar != ""
                        ? Web_url.Domain_url + LoginData.avatar
                        : images.user
                    }
                    sx={{ width: 110, height: 110 }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={avatorUpload}
                    />
                    <PhotoCamera />
                  </IconButton>
                </div>
                <div className="">
                  <h4 className="hello">
                    Hello!, <span className="andre_name">{LoginData.name}</span>
                  </h4>
                  <h4 className="hello">Member since  {moment(LoginData.createdAt?LoginData.createdAt:new Date(), 'DD-MM-YYYY').format('MMMM Do YYYY')}</h4>
                </div>
              </div>
            </div>
            {/* 06-07-2023 09:45:07 */}
            {/* 26-07-2023 13:09:00 */}
            <div className="col-4 col-md-6 col-lg-6 text-end sss">
              <div className="btns">
                <button type="button" className="btn btn-outline-primary px-4" data-bs-toggle="modal"
                  data-bs-target="#logout">
                  LOGOUT
                </button>
              </div>
            </div>
          </div>

          <hr className="horizontal_line"></hr>

          <div className="table-sections">
            <div className="row align-items-center">
              <div className="col-4 col-md-6 col-lg-6">
                <h3 className="other_details">Other Details</h3>
              </div>
            </div>

            <div className="row tablesection3">
              <div className="col-lg-2 col-md-4 col-12 forverticalline ">
                <ul className="lists">
                  {/* <li
                    className={
                      service == "cards" ? "ul-lists-active" : "ul-lists"
                    }
                    id="cards"
                    onClick={() => checkServices("cards")}
                  >
                    cards
                  </li> */}

                  <li
                    className={
                      service == "personal_info"
                        ? "ul-lists-active"
                        : "ul-lists"
                    }
                    id="personal_info"
                    onClick={() => checkServices("personal_info")}
                  >
                    Personal Info
                  </li>
                  <li
                    className={
                      service == "services" ? "ul-lists-active" : "ul-lists"
                    }
                    id="services"
                    onClick={() => checkServices("services")}
                  >
                    Appoinments
                  </li>
                  <li
                    className={
                      service == "medical_records"
                        ? "ul-lists-active"
                        : "ul-lists"
                    }
                    id="medical_records"
                    onClick={() => checkServices("medical_records")}
                  >
                    Medical Records
                  </li>
                  {/* <li
                    className={
                      service == "medicaments" ? "ul-lists-active" : "ul-lists"
                    }
                    id="medicaments"
                    onClick={() => checkServices("medicaments")}
                  >
                    Medicaments
                  </li> */}
                  <li
                    className={
                      service == "cards" ? "ul-lists-active" : "ul-lists"
                    }
                    id="payments"
                    onClick={() => checkServices("cards")}
                  >
                    Card Details
                  </li>
                  <li
                    className={
                      service == "wallet" ? "ul-lists-active" : "ul-lists"
                    }
                    id="payments"
                    onClick={() => checkServices("wallet")}
                  >
                    Wallet
                  </li>
                  <li
                    className={
                      service == "user_profile" ? "ul-lists-active" : "ul-lists"
                    }
                    id="payments"
                    onClick={() => checkServices("user_profile")}
                  >
                    Add User
                  </li>
                </ul>
              </div>

              <div className="col-lg-10 col-md-8 col-12 tablesection2">
                {service == "cards" && <UserCard />}
                {service == "services" && <ServicesProfile />}
                {service == "personal_info" && <PersonalInfo LoginData={LoginData} user={user} />}
                {service == "medical_records" && <MedicalRecords />}
                {service == "medicaments" && <Medicaments />}
                {service == "payments" && <Payment />}
                {service == "wallet" && <Wallet />}
                {service == "user_profile" && <UserProfle LoginData={LoginData} user={user}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="logout" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header logout-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Logout</h1>
              <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <p className="text-center logout-desc">Are you sure want to logout?</p>
            </div>
            <div className="modal-footer d-flex justify-content-center gap-3">
              <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={handleLogout}>Yes</button>
              <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Footer />
    </>
  );
}

export default ProfileServices;
