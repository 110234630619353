import React, { useEffect, useState } from "react";
import Step2 from "./Step2";
import "../../css/home.css";
import Step3 from "./Step3";
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaRegSadCry } from 'react-icons/fa';
import { ApiCall } from "../../common/Const";
import { useSelector } from "react-redux";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import BookYourDoc from "./BookYourDoc";
import Store from "../../Redux/Store";
import { ToastContainer, toast } from 'react-toastify';



function Step1() {

    const [severeDataList, setsevereDataList] = useState([]);
    const [bookHome, setBookHome] = useState(0);
    const [severeDataFilterList, setsevereDataFilterList] = useState([]);
    const [symptomList, setSymptomList] = useState([]);

    const LoginData = useSelector((state) => state.login);
    const bookingData = useSelector((state) => state.bookingData);
    const userData = bookingData.userData;
    const cartData = bookingData.bookingData;
    const common_symptoms = bookingData.commonSymptoms;



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        getSymptoms();
    }, [])

    const getSymptoms = () => {
        ApiCall("common_symptoms").then((data) => {
            if (data.message == "symptoms listed successfull.") {
                let listData = data.result.non_common.length > 0 ? data.result.non_common : [];
                listData = listData.map(element => {
                    const exist = cartData.find(e => e.symptom_id == element.id);
                    if (exist) {

                        return {
                            ...element, selected_id: exist.severe_id, popup: false
                        }
                    }
                    else {
                        return {
                            ...element, selected_id: 0, popup: false
                        }
                    }
                });
                let listData2 = data.result.common.length > 0 ? data.result.common : [];
                listData2 = listData2.map(element => {
                    const checkValue = common_symptoms.find(e => e.symptom_id == element.id);
                    if (checkValue) {
                        return {
                            ...element, selected_id: checkValue.severe_id, popup: false, check: true
                        }
                    }
                    else {
                        return {
                            ...element, selected_id: 0, popup: false, check: false
                        }
                    }

                });
                setsevereDataList(listData);
                setsevereDataFilterList(listData);
                setSymptomList(listData2);
            }
        })
    }
    const severeData = [
        {
            id: 1,
            name: "No pain"
        },
        {
            id: 2,
            name: "Mild"
        },
        {
            id: 3,
            name: "Moderate"
        },
        {
            id: 4,
            name: "Severe"
        },
        {
            id: 5,
            name: "Very Severe"
        },
        {
            id: 6,
            name: "Worst Pain"
        },
    ];
    const [severeValue, setSevereValue] = useState(1);


    const SymptomCheck = () => {

        if (cartData.length == 0 && common_symptoms.length == 0) {
            toast.error("Add symptoms to continue", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            setStep(2);
            window.scrollTo({ top: 0, behavior: "smooth" })
        }

    }
    const HandleSevereList = (item, id) => {
        // console.log(id)

        let filterdata = severeDataList.map((element) => {
            if (element.id == item.id) {
                return { ...element, popup: !element.popup, selected_id: id }
            }
            else {
                return { ...element, popup: false }
            }
        });
        let filterdata1 = severeDataFilterList.map((element) => {
            if (element.id == item.id) {
                return { ...element, selected_id: id }
            }
            else {
                return { ...element, popup: false }
            }
        });

        if (cartData.length > 0) {

            let cartDat = cartData.find((e) => e.symptom_id == item.id);

            let cartData1 = cartData.find((e) => e.symptom_id == item.id && e.severe_id == id);

            if (cartDat == undefined || cartDat == '') {
                const symptomData = {
                    symptom_id: item.id,
                    symptoms_name: item.symptoms_name,
                    severe_id: id
                }
                Store.dispatch({ type: "updateSymptoms", payload: symptomData });
            } else if (cartData1 == undefined || cartData1 == '') {
                const symptomData = {
                    symptom_id: item.id,
                    symptoms_name: item.symptoms_name,
                    severe_id: id
                }
                Store.dispatch({ type: "ModifyBookingData", payload: symptomData });
            } else {

                toast.error("Symptoms already added!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        else {
            const symptomData = {
                symptom_id: item.id,
                symptoms_name: item.symptoms_name,
                severe_id: id
            }
            Store.dispatch({ type: "updateSymptoms", payload: symptomData });
        }
        setsevereDataList([...filterdata])
        setsevereDataFilterList([...filterdata1])
    }

    const handleSearch = (e) => {
        const filterValue = severeDataFilterList.filter(item => item.symptoms_name.toLowerCase().includes(e.target.value.toLowerCase()));
        setsevereDataList(filterValue);
    }

    function openList() {
        browsers.style.display = 'block';
        search.style.borderRadius = "5px 5px 0 0"
        for (let option of browsers.options) {
            option.onclick = function () {
                search.value = option.value;
                browsers.style.display = 'none';
                search.style.borderRadius = "5px";
            }
        };
    }

    const handleSevereModel = (key) => {
        let filterdata = severeDataList.map((element, i) => {
            if (i == key) {
                return { ...element, popup: !element.popup }
            }
            else {
                return { ...element, popup: false }
            }
        });
        setsevereDataList([...filterdata]);
    }

    const addSymptoms = (item) => {

        if (cartData.length > 0) {

            let cartDat = cartData.find((e) => e.symptom_id == item.id);

            let cartData1 = cartData.find((e) => e.symptom_id == item.id && e.severe_id == item.selected_id);

            if (cartDat == undefined || cartDat == '') {
                const symptomData = {
                    symptom_id: item.id,
                    symptoms_name: item.symptoms_name,
                    severe_id: item.selected_id
                }
                Store.dispatch({ type: "updateSymptoms", payload: symptomData });
            } else if (cartData1 == undefined || cartData1 == '') {
                const symptomData = {
                    symptom_id: item.id,
                    symptoms_name: item.symptoms_name,
                    severe_id: item.selected_id
                }
                Store.dispatch({ type: "ModifyBookingData", payload: symptomData });
            } else {
                toast.error("Symptoms already added!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        else {
            const symptomData = {
                symptom_id: item.id,
                symptoms_name: item.symptoms_name,
                severe_id: item.selected_id
            }
            Store.dispatch({ type: "updateSymptoms", payload: symptomData });
        }
    }

    const hidePopup = () => {
        let filterdata = symptomList.map((element) => {
            return { ...element, popup: false }
        });
        setSymptomList([...filterdata]);
    }

    const removeSymptom = (item) => {
        let filterList = severeDataList.map((e) => {
            return e.id == item.symptom_id ?
                { ...e, popup: false, selected_id: 0 }
                : e
        })
        setsevereDataList([...filterList]);
        setsevereDataFilterList([...filterList]);

        const symptomData = {
            symptom_id: item.symptom_id,
        }
        Store.dispatch({ type: "removeBookingData", payload: symptomData });
    }

    const checkService = () => {

        if (cartData.length == 0) {
            toast.error("Add symptoms to continue", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            toast.error("complete the step 2 to continue", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleCommon = (e, item, key) => {
        symptomList[key].check = e.target.checked;
        let data = symptomList;
        setSymptomList([...data]);
        if (common_symptoms.length > 0 && e.target.checked) {

            const symptomData = {
                symptom_id: item.id,
                symptoms_name: item.symptoms_name,
                severe_id: item.selected_id == 0 ? 1 : item.selected_id
            }
            let filterList = symptomList.map((e) => {
                return e.id == item.id && item.selected_id == 0 ?
                    { ...e, selected_id: 1 }
                    : e
            })
            setSymptomList([...filterList]);

            Store.dispatch({ type: "addCommonSymptoms", payload: symptomData });
        }
        else if (e.target.checked) {
            const symptomData = {
                symptom_id: item.id,
                symptoms_name: item.symptoms_name,
                severe_id: item.selected_id == 0 ? 1 : item.selected_id
            }
            let filterList = symptomList.map((e) => {
                return e.id == item.id && item.selected_id == 0 ?
                    { ...e, selected_id: 1 }
                    : e
            })
            setSymptomList([...filterList]);
            Store.dispatch({ type: "addCommonSymptoms", payload: symptomData });
        }
        else {
            let filterList = symptomList.map((e) => {
                return e.id == item.id ?
                    { ...e, selected_id: 0, check: false }
                    : e
            })
            setSymptomList([...filterList]);

            Store.dispatch({ type: "removeCommonSymptoms", payload: item.id });
        }
    }

    const removeCommonSymptom = (item) => {
        let filterList = symptomList.map((e) => {
            return e.id == item.symptom_id ?
                { ...e, check: false, selected_id: 0 }
                : e
        })
        setSymptomList([...filterList]);
        Store.dispatch({ type: "removeCommonSymptoms", payload: item.symptom_id });
    }

    const [step, setStep] = useState(1);

    const showList = (key) => {
        let filterdata = symptomList.map((element, i) => {
            if (i == key)
                return { ...element, popup: true }
            else
                return { ...element, popup: false }
        });
        setSymptomList([...filterdata]);
    }
    const hideList = (key) => {
        symptomList[key].popup = false;
        let data = symptomList;
        setSymptomList([...data]);
    }

    const handleCommonList = (key, id) => {

        let cartDat = common_symptoms.find((e) => e.symptom_id == symptomList[key].id);

        if (cartDat == undefined || cartDat == '') {

            symptomList[key].selected_id = id;
            symptomList[key].check = true;
            const symptomData = {
                symptom_id: symptomList[key].id,
                symptoms_name: symptomList[key].symptoms_name,
                severe_id: id
            }
            Store.dispatch({ type: "addCommonSymptoms", payload: symptomData });

        } else {
            symptomList[key].selected_id = id;
            const symptomData = {
                symptom_id: symptomList[key].id,
                symptoms_name: symptomList[key].symptoms_name,
                severe_id: id
            }
            Store.dispatch({ type: "ModifyCommonSymptom", payload: symptomData });
        }
        let data = symptomList;
        setSymptomList([...data]);
    }

    const hideModel = () => {
        let filterdata = severeDataList.map((element) => {
            return { ...element, popup: false }
        });
        setsevereDataList([...filterdata]);
    }
    return (

        <>
            {
                bookHome == 0 ?
                    <>

                        {
                            step == 1 ?
                                <div className="common-bg py-5">
                                    <div className="container book-content">
                                        <div className="row px-lg-5 px-2">
                                            <div className="col-md-8">
                                                <p className="step-head">Book your Service</p>
                                                <p className="step-desc">Get back to Better Health Your way. Whether at Home, at Work or Traveling, Doctor Allways has the right service for you.</p>
                                            </div>
                                            <div className="col-md-4 d-flex flex-column justify-content-end align-items-center">
                                                <div className="step-list">
                                                    <a onClick={() => (setStep(1))} className={step == 1 ? "step-left1 step-active" : "step-left1"} role="button">1</a>
                                                    <a onClick={() => SymptomCheck()} className={step == 2 ? "step-left step-active" : "step-left"} role="button">2</a>
                                                    <a onClick={() => checkService()} className={step == 3 ? "step-active" : ""} role="button">3</a>
                                                </div>
                                                <p className="right-head">{step == 1 ? "Symptoms" : step == 2 ? "Service" : "Checkout"}</p>
                                            </div>
                                        </div>
                                        <div className="row px-4">
                                            <div className="col-md-12">
                                                <hr className="help-line mb-0 mt-4"></hr>
                                            </div>
                                        </div>
                                        <div className="row px-lg-5 px-2">
                                            <div className="col-md-7 left-content-steps">
                                                <p className="start-head">
                                                    Hi! Please tell us the symptom and Severity you have in order to offer you the best service available.
                                                </p>
                                                <div className="row" onMouseLeave={hideModel}>
                                                    <div className="search-box col-12">
                                                        <input autoComplete="off" role="combobox" list="" id="search" name="browsers" className="search" placeholder="Search your symptoms" onFocus={openList} onChange={(e) => { handleSearch(e); }} />
                                                        <datalist id="browsers" role="listbox">
                                                            <>
                                                                {severeDataList.length > 0 ? severeDataList.map((item, key) =>
                                                                    <div className="severe-class" key={key}>
                                                                        <div className="option-value">
                                                                            {item.symptoms_name}
                                                                        </div>
                                                                        <div className="severe-first">
                                                                            {item.selected_id != 0 ? <span>Severity : </span> : null}
                                                                            <a>&nbsp;{severeData.map((emoji) => {
                                                                                if ((emoji.id == severeValue && item.selected_id ==
                                                                                    severeValue) || (emoji.id == item.selected_id))
                                                                                    return emoji.name;
                                                                            })} </a>&nbsp;&nbsp;
                                                                            <button onClick={() => handleSevereModel(key)} >Add</button>
                                                                            <div className="severe-list" style={{ visibility: `${item.popup ? "Visible" : "hidden"}` }}>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 1)}>
                                                                                    <span className="d-block">No Pain</span>
                                                                                    <InsertEmoticonIcon />
                                                                                </div>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 2)}>
                                                                                    <span className="d-block">Mild</span>
                                                                                    <SentimentSatisfiedAltIcon />
                                                                                </div>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 3)}>
                                                                                    <span className="d-block">Moderate</span>
                                                                                    <SentimentNeutralIcon />
                                                                                </div>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 4)}>
                                                                                    <span className="d-block">Severe</span>
                                                                                    <SentimentVeryDissatisfiedIcon />
                                                                                </div>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 5)}>
                                                                                    <span className="d-block">Very Severe</span>
                                                                                    <SickOutlinedIcon />
                                                                                </div>
                                                                                <div role="button" onClick={() => HandleSevereList(item, 6)}>
                                                                                    <span className="d-block">Worst Pain</span>
                                                                                    <FaRegSadCry size={20} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                    <option value="No symptoms found" className="not-found" disabled>No symptoms found
                                                                    </option>
                                                                }
                                                            </>

                                                        </datalist>
                                                    </div>
                                                </div>


                                                <p className="sym-head">Common Symptoms</p>
                                                <div className="sym-list" onMouseLeave={() => hidePopup()}>
                                                    {symptomList.length > 0 ? symptomList.map((item, key) =>
                                                        <div className="common-list" ><div className="mt-2 sym-list-item" key={key} onMouseEnter={() => showList(key)}>
                                                            <label>{item.symptoms_name}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                                            <input type="checkbox" id="" name="" onChange={(e) => handleCommon(e, item, key)} checked={item.check} />
                                                        </div>{
                                                                item.popup ?
                                                                    <div className="non-severe-list" onMouseLeave={() => hideList(key)}>
                                                                        <div role="button" className={item.selected_id == 1 ? "common-active" : ""} onClick={() => handleCommonList(key, 1)}>
                                                                            <span className="d-block">No Pain</span>
                                                                            <InsertEmoticonIcon />
                                                                        </div>
                                                                        <div className={item.selected_id == 2 ? "common-active" : ""} role="button" onClick={() => handleCommonList(key, 2)}>
                                                                            <span className="d-block">Mild</span>
                                                                            <SentimentSatisfiedAltIcon />
                                                                        </div>
                                                                        <div role="button" className={item.selected_id == 3 ? "common-active" : ""} onClick={() => handleCommonList(key, 3)}>
                                                                            <span className="d-block">Moderate</span>
                                                                            <SentimentNeutralIcon />
                                                                        </div>
                                                                        <div role="button" className={item.selected_id == 4 ? "common-active" : ""} onClick={() => handleCommonList(key, 4)}>
                                                                            <span className="d-block">Severe</span>
                                                                            <SentimentVeryDissatisfiedIcon />
                                                                        </div>
                                                                        <div role="button" className={item.selected_id == 5 ? "common-active" : ""} onClick={() => handleCommonList(key, 5)}>
                                                                            <span className="d-block">Very Severe</span>
                                                                            <SickOutlinedIcon />
                                                                        </div>
                                                                        <div role="button" className={item.selected_id == 6 ? "common-active" : ""} onClick={() => handleCommonList(key, 6)}>
                                                                            <span className="d-block">Worst Pain</span>
                                                                            <FaRegSadCry size={20} />
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }

                                                        </div>
                                                    ) :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-5 mt-md-2 mt-4 px-lg-5 px-4 d-flex flex-column justify-content-between">
                                                <div>
                                                    <div className="right-name-sec">
                                                        <p className="name-con">{userData.name} / {userData.age}</p>
                                                        <a className="name-edit" role="button" onClick={() => setBookHome(1)}>Edit</a>
                                                    </div>
                                                    {
                                                        cartData.length > 0 || common_symptoms.length > 0
                                                            ?
                                                            <div className="mt-4">
                                                                <div className="right-sym-sec">
                                                                    <p className="sym-head mb-1">Your Symptoms</p>
                                                                    {/* <a className="name-edit mt-3" role="button">Edit</a> */}
                                                                </div>
                                                                {cartData.map((symptom, k) => {

                                                                    return (


                                                                        <div className="right-name-sec mt-3" key={k}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <span className="close-btn" role="button" onClick={() => removeSymptom(symptom)}>&times;</span>
                                                                                <p className="name-con">{symptom.symptoms_name}</p>
                                                                            </div>
                                                                            <div className="sym-status">

                                                                                {severeData.map((ele) => {

                                                                                    if (ele.id == symptom.severe_id)
                                                                                        return ele.name
                                                                                })}
                                                                                {/* <select className="">
                                                                                <option>Normal&nbsp;&nbsp;</option>
                                                                            </select> */}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                })}
                                                                {common_symptoms.map((symptom, k) => {

                                                                    return (


                                                                        <div className="right-name-sec mt-3" key={k}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <span className="close-btn" role="button" onClick={() => removeCommonSymptom(symptom)}>&times;</span>
                                                                                <p className="name-con">{symptom.symptoms_name}</p>
                                                                            </div>
                                                                            <div className="sym-status">

                                                                                {severeData.map((ele) => {

                                                                                    if (ele.id == symptom.severe_id)
                                                                                        return ele.name
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                })}
                                                            </div> : ""
                                                    }

                                                </div>
                                                <div>
                                                    <button className="step-continue" onClick={() => SymptomCheck()}>CONTINUE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                </div>
                                : ""
                        }
                        {
                            step == 2 ?
                                <Step2 userData={userData} cartData={cartData} /> : ""
                        }
                        {
                            step == 3 ?
                                <Step3 LoginData={LoginData} /> : ""
                        }


                    </>

                    : <BookYourDoc />
            }
        </>


    );

}

export default Step1;