import React, { useEffect, useState } from 'react'
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardModel from '../card/CardModel';
import { ApiCall } from '../../common/Const';
import { ToastContainer, toast } from 'react-toastify';
import { images } from '../../common/images';
import { useSelector } from 'react-redux';
import moment from "moment";
import store from "../../Redux/Store";
import { Pagination } from '@mui/material';
import { STRIPE_API_KEY } from '../../common/Const';


export default function Wallet() {
    const [cardList, setCardList] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [cardActive, setCardActive] = useState('');
    const [callApi, setCallApi] = useState(false);
    const [transactionApi, setTransactionApi] = useState(false);
    const [rechargeAmount, setRechargeAmount] = useState('');
    const [stripePromise, setStripePromise] = useState(() => loadStripe(STRIPE_API_KEY))
    const [phoneNumErr, setphoneNumErr] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);
    const LoginData = useSelector((state) => state.login);
    const [totalCount, setTotalCount] = useState(0);
    const [userId, setUserId] = useState('');
    const [currentpage, setCurrentpage] = useState(1);
    const [windowSize, setWindowSize] = useState(0)


    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        setWindowSize(window.innerWidth);

        return () => window.removeEventListener("resize", handleResize);
    }, []);



    useEffect(() => {
        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            setUserId(isAuth);
            const user = {
                patient_id: isAuth
            }
            ApiCall("profile_view", user).then((data) => {
                if (data.message == "Success.") {
                    let wallet = {
                        wallet: data.result.wallet_balance
                    }
                    store.dispatch({ type: "updateWalletData", payload: wallet });
                }
            })
        }
    }, [transactionApi])

    useEffect(() => {

        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            const user = {
                patient_id: isAuth
            }
            getTransaction(user);
            setCurrentpage(1);
        }
    }, [transactionApi])

    const getTransaction = (user, page = 0) => {
        user.page = page;
        ApiCall("transaction_history", user).then((data) => {
            if (data.message == "Success") {
                setTotalCount(data.total_count)
                setTransactionList(data.result);
            }
        })
    }



    useEffect(() => {
        let isAuth = localStorage.getItem("user");
        if (isAuth && isAuth != '') {
            const user = {
                patient_id: isAuth
            }
            ApiCall("patient_cardlist", user).then((data) => {
                if (data.message == "Card listed successfully.") {
                    let card_default = data.data.find((e) =>
                        e.card_default == 1)
                    if (card_default != undefined) {
                        setCardActive(card_default.id);
                    }
                    setCardList(data.data)
                }
            })
        }

    }, [callApi])

    const handleChangePage = (page) => {
        setCurrentpage(page)
        const user = {
            patient_id: userId
        }
        getTransaction(user, (page - 1) * 6)
    }

    const handleApi = () => {
        setCallApi(!callApi);
    }

    const checkPhoneNumber = (e) => {
        let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(e.target.value)) {
            setphoneNumErr("Enter digits only");
            setRechargeAmount("");
        }
        else {
            setphoneNumErr("");
            setRechargeAmount(e.target.value);
        }
    }

    const addWallet = () => {
        setBtnLoad(true);

        if (walletValidation()) {
            if (cardActive == "") {
                toast.error("Choose card to add money", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setBtnLoad(false);
            }
            else {
                let isAuth = localStorage.getItem("user");
                const user = {
                    patient_id: isAuth,
                    amount: rechargeAmount,
                    card_id: cardActive
                }
                ApiCall("wallet_recharge", user).then((data) => {
                    if (data.message == "Success") {
                        toast.success("Amount added Successfully", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        let wallet = {
                            wallet: data.result.wallet_balance
                        }
                        store.dispatch({ type: "updateWalletData", payload: wallet });
                        setTimeout(() => {
                            setBtnLoad(false);
                            setRechargeAmount('');
                            setCardActive('')
                            setTransactionApi(!transactionApi);
                            window.scrollTo({ top: 0, behavior: "smooth" })
                        }, 3000)
                    }
                    else {
                        toast.error(data.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setBtnLoad(false);
                    }
                })
            }
        }
        else {
            setBtnLoad(false);
        }
    }

    const walletValidation = () => {
        let isValid = true;

        if (rechargeAmount == "") {
            setphoneNumErr("Enter amount to recharge");
            isValid = false;
        }
        else {
            setphoneNumErr("");
        }
        return isValid;
    }

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <p className="step-head">Add Money</p>
                    <p className="step-desc">By proceeding with this transaction, you can confirm that you accept Doctor Allways terms & condition / services</p>
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-end align-items-center">
                    <p className="total-amount mb-1">${LoginData.wallet}</p>
                    <p className="right-head">Your Wallet Balance</p>
                </div>
            </div>
            <hr className='Wallet-line'></hr>
            <div className='row justify-content-center mt-4'>
                <div className="col-md-9">
                    <div className="form-group mb-2 time-select">
                        <label className="mb-2">Recharge Amount</label>
                        <input type='text' name="amount" id="wallet" className='form-control' value={rechargeAmount} onChange={(e) => checkPhoneNumber(e)}></input>
                        <span
                            className="err-txt"
                        >
                            {phoneNumErr ? phoneNumErr : ""}
                        </span>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-4'>
                <div className="col-md-9">
                    <div className='row'>
                        <div className='col-4'>
                            <button className={rechargeAmount == 100 ? "amt-active amount-btn" : 'amount-btn'} onClick={() => setRechargeAmount(100)}>100</button>
                        </div>
                        <div className='col-4'>
                            <button className={rechargeAmount == 200 ? "amt-active amount-btn" : 'amount-btn'} onClick={() => setRechargeAmount(200)}>200</button>
                        </div>
                        <div className='col-4'>
                            <button className={rechargeAmount == 300 ? "amt-active amount-btn" : 'amount-btn'} onClick={() => setRechargeAmount(300)}>300</button>
                        </div>
                    </div>


                </div>
            </div>
            <div className='row justify-content-center mt-5'>
                <div className='col-md-9'>
                    <div className='row'>
                        <div className="col-md-8">
                            <p className={cardList.length > 0 ? 'no-data' : 'd-none'}> Choose Card to Wallet Recharge </p>
                            <ul className="card-list">
                                {cardList.length > 0 ?
                                    cardList.map((ele) => {

                                        return (
                                            <li key={ele.id} role='button' onClick={() => setCardActive(ele.id)}><a className={cardActive == ele.id ? "card-active" : ""}><BsCreditCard2FrontFill size={28} />&nbsp;<span className="">****{ele.card_number}</span></a></li>
                                        )
                                    })

                                    // <li><a className=""><RiMastercardLine size={26} />&nbsp;<span className="">****1234</span></a></li> 
                                    : <span className='no-data'> No card added! </span>}
                            </ul>
                        </div>
                        <div className='col-md-4'>
                            <div className="new-card">
                                {/* <select className="">
                                <option>New Card&nbsp;&nbsp;</option>
                            </select> */}
                                <button className='add_card' data-bs-toggle="modal"
                                    data-bs-target="#card-save">Add New Card</button>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button type="button" className={btnLoad ? "right-spinner-wallet spinner-border spinner-border-sm" : "add-money-btn"} onClick={() => addWallet()} disabled={btnLoad} >{btnLoad ? "" : "Add to Wallet"}</button>

                        {/* <button >ADD NOW</button> */}
                    </div>
                </div>

            </div>
            <div className='mt-5'>
                <div className='row'>

                    <p className="step-head">All Transaction Details</p>

                </div>
                <div className='table-responsive'>

                    <table className="table table-striped table-borderless tableclass mt-3">
                        <thead className="tableheader">
                            <tr>
                                {/* <th scope="col">No</th> */}
                                <th scope="col">Transaction Type</th>
                                <th scope="col">Transaction Mode</th>
                                <th scope="col">Transaction Notes</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        {
                            transactionList.length > 0 ?
                                <tbody>
                                    {
                                        transactionList.map((data, index) => {
                                            return (
                                                <tr className="fortablelists" key={index}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>
                                                        {data.transaction_type}
                                                    </td>
                                                    <td>{data.transaction_mode}</td>
                                                    <td>{data.transaction_notes}</td>
                                                    <td>${data.transaction_amount}</td>
                                                    <td>
                                                        {moment(data.created_at).format('MMMM Do YYYY')}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }

                                </tbody>
                                : ""
                        }
                    </table>
                </div>

                {transactionList.length == 0 ?
                    <div className='emp-serv'>
                        <p>No transaction found!</p>

                    </div> :
                    <div className='mt-4 d-flex justify-content-center'>
                        <Pagination count={(totalCount / 6).toString().split(".")[1] == undefined ? parseInt((totalCount / 6).toString().split(".")[0]) : parseInt((totalCount / 6).toString().split(".")[0]) + 1} color="primary" onChange={(e, page) => { handleChangePage(page) }} page={currentpage} siblingCount={0} boundaryCount={2} defaultPage={1} size={windowSize < 768 ? "small" : "large"} />
                    </div>
                }

            </div >
            <div className="modal fade" id="card-save" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header logout-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Save Card</h1>
                            <button type="button" className="btn-close logout-close-btn" data-bs-dismiss="modal" id="card-close"></button>
                        </div>
                        <div className="modal-body">
                            <Elements stripe={stripePromise}>

                                <CardModel handler={handleApi} />

                            </Elements>
                        </div>
                        {/* <div className="modal-footer d-flex justify-content-center gap-3">
                            <button type="button" className="logout-yes" data-bs-dismiss="modal" onClick={handleLogout}>Yes</button>
                            <button type="button" className="logout-no" data-bs-dismiss="modal">No</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )
}
