import React, { useEffect, useState } from 'react'
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Pagination } from '@mui/material';
import { ApiCall, FileApiCall, Web_url } from '../../common/Const';
import { ToastContainer, toast } from 'react-toastify';


function MedicalRecords() {

  const [callApi, setCallApi] = useState(false)

  useEffect(() => {
    let isAuth = localStorage.getItem("user");
    setUser_id(isAuth);
    ApiCall("medical_records_list", { patient_id: isAuth })
      .then((data) => {
        if (data.message == "Success.") {
          setDocumentList(data.result);
        }
      })
      .catch((err) => console.log(err));
  }, [callApi])
  const [btnLoad, setBtnLoad] = useState(false);
  const [user_id, setUser_id] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [documentNameErr, setDocumentNameErr] = useState('');
  const [file, setFile] = useState('');
  const [fileErr, setFileErr] = useState('');

  const [documentList, setDocumentList] = useState([])

  const handleFile = (e) => {
    if (e.target.files[0].type == "") {
      document.getElementById('formFile').value = "";
      setFile('');
      toast.error("File type must be pdf or doc", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else if (e.target.files[0].size > 2000000) {
      document.getElementById('formFile').value = "";
      setFile('');
      toast.error("File size must be less than 2MB", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else {
      setFile(e.target.files[0])
    }
  }


  const submitDocument = () => {
    setBtnLoad(true);
    if (validateDocument()) {
      const formData = new FormData();
      formData.append('patient_id', user_id);
      formData.append('document_name', documentName);
      formData.append('document_pdf', file);
      FileApiCall("medical_records", formData)
        .then((data) => {
          if (data.message == "Success.") {

            toast.success("Record uploaded Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setBtnLoad(false);
            setFile('');
            setDocumentName('');
            document.getElementById('upload_data').reset();
            setCallApi(!callApi)
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setBtnLoad(false);

        })
        .catch((err) => console.log(err));
    }
    else {
      setBtnLoad(false);
    }
  }

  const validateDocument = () => {
    let isvalid = true;
    if (documentName == "") {
      setDocumentNameErr("Enter Document Name")
      isvalid = false;
    }
    else {
      setDocumentNameErr("")
    }
    if (file == "") {
      setFileErr("Choose medical document")
      isvalid = false;
    }
    else {
      setFileErr("")
    }

    return isvalid;
  }


  return (
    <>
      <form id="upload_data">
        <div className='row'>
          <div className='col-6'>
            <div className="form-group mb-4 profile-input">
              <label style={{ paddingBottom: "15px" }}>
                Document Name
              </label>
              <input
                type="text"
                className="form-control forinputborder"
                id="state"
                placeholder="Enter your document name"
                onChange={(e) => setDocumentName(e.target.value)}
                autoComplete='off'
              />
              <span
                className="err-txt"
              >
                {documentNameErr ? documentNameErr : ""}
              </span>
            </div>
          </div>
          <div className='col-6'>
            <div className="form-group mb-4 profile-input">
              <label style={{ paddingBottom: "15px" }}>
                Upload Document
              </label>
              {/* <input className="form-control" type="file" id="formFile" accept=".pdf,.doc" onChange={uploadFile} /> */}

              <input className="form-control" type="file" id="formFile" accept=".pdf, .doc, .docx" onChange={(e) => handleFile(e)} />

              <span
                className="err-txt"
              >
                {fileErr ? fileErr : ""}
              </span>
            </div>
          </div>
        </div>
        <div>
          <button type="button" className={btnLoad ? "right-spinner spinner-border spinner-border-sm" : "upload-btn"} onClick={submitDocument} disabled={btnLoad} >{btnLoad ? "" : "Submit"}</button>
        </div>
      </form>
      <table className="table table-striped table-borderless tableclass mt-5">
        <thead className="tableheader">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Document Name</th>
            <th scope="col">View Document</th>
          </tr>
        </thead>
        <tbody>
          {
            documentList.length > 0 ?
              documentList.map((data, index) => {
                return (
                  <tr className="fortablelists" key={index}>
                    <td>{index + 1}</td>
                    <td>{data.doucment_name}</td>
                    <td>
                      <a href={Web_url.Domain_url + data.document_image} target='_blank'>
                        <RemoveRedEyeRoundedIcon style={{ cursor: "pointer" }} />
                      </a>
                    </td>
                  </tr>
                );
              }) : null
          }
        </tbody>
      </table>
      {documentList.length == 0 ?
        <>
          <div className='emp-serv'>
            <p>No records found!</p>
          </div>
        </>
        : ""}
      {/* <div className="d-flex justify-content-end">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
        />
      </div> */}
      <ToastContainer />

    </>
  );
}


export default MedicalRecords;
