import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../css/home.css";
import { images } from "../../common/images";
import Faq from "./Faq";



function Viewnews() {

    return (
        <>
            <Header news={true} />
            <div className="help-con-bg py-5">

                <div className="container pb-4">
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-9">
                            <p className="arti-text">Articles</p>
                            <p className="view-news-title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>

                    </div>
                    <div className="row justify-content-end">
                        <div className="col-md-4 d-flex justify-content-end">
                            <div className="news-detail">
                                <span >01-07-2023</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src={images.like} alt="news-img" className="like-img" />&nbsp;&nbsp;

                                <span>8</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src={images.comment} alt="news-img" className="comment-img" />
                                &nbsp;&nbsp;
                                <span>5</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mx-3 my-2 help-line"></hr>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="news-left-content">
                                <p className="news-left-title">Book Your Service</p>
                                <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <button className="footer-book-btn">BOOK NOW</button>
                            </div>
                        </div>
                        <div className="col-md-8  mt-3">
                            <img src={images.news_img1} alt="news-img" className="news-left-img" />
                            <p className="news-title">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a
                                type specimen book.</p>

                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a
                                type specimen book.</p>
                            <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a
                                type specimen book.</p>
                            <hr className="news-line"></hr>

                            <p className="comments-count mt-5">2 Comments</p>
                            <div>
                                <div className="row mt-4">
                                    <div className="col-8">
                                        <div className="comments-detail">
                                            <img src={images.user} alt="news-img" className="com-user-img" />
                                            <p className="news-title mt-2 mb-0">Lorem Ipsum is simply dummy text </p>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <p className="news-date">01-07-2023</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a
                                    type specimen book.</p>
                            </div>
                            <div>
                                <div className="row mt-4">
                                    <div className="col-8">
                                        <div className="comments-detail">
                                            <img src={images.user} alt="news-img" className="com-user-img" />
                                            <p className="news-title mt-2 mb-0">Lorem Ipsum is simply dummy text </p>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <p className="news-date">01-07-2023</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <p className="news-desc">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a
                                    type specimen book.</p>
                            </div>
                            <hr className="mx-0 mt-5 mb-1 help-line"></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="news-name">
                                        <label className="mb-2">Name</label>
                                        <input type="text" className="form-control" id="" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="news-name">
                                        <label className="mb-2">Email</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email" />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mb-3 news-message">
                                        <label className="mb-2">Message</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="comment-send-btn">SEND COMMENT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer first={false} />

        </>

    );

}

export default Viewnews;